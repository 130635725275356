import React from "react";
import { Loader } from "components/lib";

const FullViewLoader = () => {
  return (
    <div className="position-absolute top-50 start-50">
      <Loader width="2rem" height="2rem" />
    </div>
  );
};

export default FullViewLoader;
