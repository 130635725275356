/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import CompanyGraphContainer from "./CompanyGraphContainer";
import { CompanyContext } from "pages/Companies";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const CompaniesTable = props => {
  const { companiesApiResponse, setIsShowSearch, setSearchTerm, setIsShowCompanyTable, setSelectedCategoryName } =
    useContext(CompanyContext);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isShowCompanyGraph, setIsShowCompanyGraph] = useState(false);

  const companiesColumns = [
    {
      dataField: "_source.name",
      text: "Company Name",
      events: {
        onClick: () => {
          setIsShowCompanyGraph(true);
          setIsShowSearch(false);
        },
      },
    },

    {
      dataField: "_source.category",
      text: "Category Name",
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setSelectedCategoryName(row?._source?.category);
          setSearchTerm("category");
          setIsShowCompanyTable(true);
        },
      },
    },
  ];

  const { SearchBar } = Search;

  return !isShowCompanyGraph ? (
    <div>
      <ToolkitProvider keyField="_id" data={companiesApiResponse} columns={companiesColumns} search>
        {props => (
          <div>
            <SearchBar
              placeholder="Search"
              {...props.searchProps}
              className="input-form form-control mb-2 ms-auto"
              style={{ background: "#ae587a", height: "2.9rem", fontSize: "1rem" }}
              srText=""
            />
            <BootstrapTable
              {...props.baseProps}
              pagination={paginationFactory({ sizePerPage: 10 })}
              striped
              hover
              condensed
              selectRow={{
                mode: "checkbox",
                clickToSelect: true,
                bgColor: "#ae587a",
                selected: selectedIndex,
                onSelect: (row, isSelect) => {
                  if (!isSelect) {
                    setSelectedIndex([]);
                    setSelectedRow(null);
                    return;
                  }
                  setSelectedIndex([row.id]);
                  setSelectedRow(row);
                },
              }}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  ) : (
    <CompanyGraphContainer graphDataObj={selectedRow} setIsShowCompanyGraph={setIsShowCompanyGraph} />
  );
};

CompaniesTable.propTypes = {};

export default CompaniesTable;
