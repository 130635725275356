import React, { useEffect, useState } from "react";
import { GraphContentContainer, VisNetwork } from "components/graphUI";
import { useIsShowFeaturedAppsBar } from "context/IsShowFeaturedAppsBarContext";
import { jsonData } from "components/graphUI/GraphJsonData";

const Graph = () => {
  const [isShowGraph, setIsShowGraph] = useState(false);
  const [graphData, setGraphData] = useState(null);

  const { dispatch: dispatchIsShowFeaturedAppsBar, Action } = useIsShowFeaturedAppsBar();

  useEffect(() => {
    dispatchIsShowFeaturedAppsBar({ type: Action.HideAppsBar });

    return () => {
      dispatchIsShowFeaturedAppsBar({ type: Action.ShowAppsBar });
    };
  }, []);

  return isShowGraph ? (
    <VisNetwork jsonData={graphData} setIsShowGraph={setIsShowGraph} />
  ) : (
    <GraphContentContainer setIsShowGraph={setIsShowGraph} setGraphData={setGraphData} defaultValue={jsonData} />
  );
};

export default Graph;
