/* eslint-disable react/prop-types */
import React from "react";

class WebView extends React.Component {
  static views = {};

  componentDidMount() {
    if (!WebView.views[this.props.url]) {
      WebView.views[this.props.url] = this.createWebView(this.props.url);
    }

    WebView.views[this.props.url].style.display = this.props.isShow ? "block" : "none";
    WebView.views[this.props.url].style.transform = "translate(-50%,-50%)";
    WebView.views[this.props.url].style.width = "95%";
    WebView.views[this.props.url].style.height = "88%";
    WebView.views[this.props.url].style.pointerEvents = "none";

    WebView.views[this.props.url].classList.add("rounded", "position-absolute", "top-50", "start-50", "bg-white");
  }

  createWebView(url) {
    let view = document.createElement("iframe");
    view.src = this.props.url;
    document.body.appendChild(view);
    return view;
  }

  componentWillUnmount() {
    WebView.views[this.props.url].style.display = "none";
  }

  render() {
    return null;
  }
}

export default WebView;
