import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { PropTypes } from "prop-types";
import { useDebouncedCallback } from "use-debounce";
import { client } from "utils/apiClient";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    top: -8,
  }),
};

const generateLabel = (data, category) => {
  return data?.hits?.hits?.map((item, i) => {
    return { value: i, label: item?._source?.[category] };
  });
};

const SearchDropdown = ({
  setInputValue,
  setGraphData,
  setErrorMessage,
  category,
  resetData,
  searchOptions,
  setSearchOptions,
  selectedValue,
  setSelectedValue,
}) => {
  const [apiResponseData, setApiResponseData] = useState(null);
  const [_inputValue, _setInputValue] = useState("");

  const getFullJSONDataUsingLabel = (label, category) => {
    return apiResponseData?.hits?.hits?.filter(option => {
      return option?._source?.[category] === label;
    });
  };

  const handleInputChange = _selectedOption => {
    if (!_selectedOption) {
      return resetData();
    }

    setSelectedValue(_selectedOption);
    setInputValue(_selectedOption.label);
    setGraphData(getFullJSONDataUsingLabel(_selectedOption.label, category));
  };

  const fetchData = (inputValue, category, callback) => {
    return new Promise(resolve => {
      resolve(fetchQuoteDataUsingQuery(inputValue, category));
    }).then(data => {
      setApiResponseData(data);
      const labelData = generateLabel(data, category);
      setSearchOptions(labelData);
      callback(labelData);
    });
  };

  const debouncedLoadOptions = useDebouncedCallback(fetchData, 500);

  const getData = (value, callback) => {
    resetData(true);
    if (!category) {
      setErrorMessage("Category field is required");
      callback([]);
    } else {
      if (_inputValue.length >= 2) {
        debouncedLoadOptions(value, category, callback);
      } else {
        callback([]);
      }
    }
  };

  const fetchQuoteDataUsingQuery = async (query, category) => {
    const apiResponse = await client(process.env.REACT_APP_API_URL, {
      token: process.env.REACT_APP_API_KEY,
      isBasicAuth: "true",
      data: {
        query: {
          match: {
            [category]: {
              query,
            },
          },
        },
      },
    });

    return apiResponse;
  };

  return (
    <div>
      <AsyncSelect
        styles={customStyles}
        menuPosition="fixed"
        defaultOptions={searchOptions}
        loadOptions={getData}
        value={selectedValue}
        onChange={e => {
          handleInputChange(e);
        }}
        onInputChange={value => {
          _setInputValue(value);
        }}
        placeholder="Search"
        backspaceRemovesValue
        isClearable
      />
    </div>
  );
};

SearchDropdown.propTypes = {
  setInputValue: PropTypes.func.isRequired,
  setGraphData: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  resetData: PropTypes.func.isRequired,
  setSearchOptions: PropTypes.func.isRequired,
  searchOptions: PropTypes.object,
  selectedValue: PropTypes.object,
  setSelectedValue: PropTypes.func.isRequired,
};

SearchDropdown.defaultProps = {
  searchOptions: null,
  selectedValue: null,
};

export default SearchDropdown;
