export const Action = {
  HideAppsBar: "HIDE_FEATURED_APPS_BAR",
  ShowAppsBar: "SHOW_FEATURED_APPS_BAR",
};

// initial state
export const initialFeaturedAppsBarState = { isShowAppsBar: true };

export const featuredAppsBarReducer = (state, action) => {
  switch (action.type) {
    case Action.HideAppsBar:
      return { isShowAppsBar: false };
    case Action.ShowAppsBar:
      return initialFeaturedAppsBarState;
    default:
      throw new Error();
  }
};
