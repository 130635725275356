/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import ExternalLink from "components/common/ExternalLink";
import "./Card.style.scss";
import dayjs from "dayjs";
import Modal from "./Modal";
import { helpText } from "./helpText";

const TickMark = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 28 24"
        xmlSpace="preserve"
        className="features_icon"
      >
        <g transform="translate(0 32)">
          <g transform="translate(729 -294.025)">
            <path
              fill="#010101"
              d="M-706.27,264.65l-12.23,12.46l-5.41-5.51l-3.18,3.24l5.41,5.51l3.18,3.24l3.18-3.24    l12.23-12.46L-706.27,264.65z"
            />
          </g>
        </g>
      </svg>
    </>
  );
};
const CrossIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 28 26.7"
        xmlSpace="preserve"
        className="features_icon"
      >
        <g transform="translate(-1180.436 -401.752)">
          <g transform="translate(1202.41 401.967)">
            <g id="cross">
              <rect
                x="-23.3"
                y="10.2"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -11.5657 -2.4669)"
                fill="#1f1f1f"
                width="29"
                height="5"
              />

              <rect
                x="-11.3"
                y="-1.8"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -11.565 -2.4668)"
                fill="#1f1f1f"
                width="5"
                height="29"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

const FeatureItem = ({ text, data }) => {
  const isOptional = data.inclusion === "Optional";
  const isIncluded = data.inclusion === "Included";

  return (
    <Col xs={12} sm={6} className="my-2">
      {isIncluded ? <TickMark /> : <CrossIcon />} {text}{" "}
      {isOptional ? `- £${data?.cost?.annual?.amount?.value} extra` : null}
    </Col>
  );
};

const formatText = text => {
  const words = text.split(/(?=[A-Z])/);

  const formattedWords = [words[0][0].toUpperCase() + words[0].slice(1)].concat(
    words.slice(1).map(word => word.toLowerCase()),
  );

  return formattedWords.join(" ");
};

function isObject(val) {
  return typeof val === "object" && val !== null;
}

const QuoteCard = ({ data }) => {
  const [isShowMoreInfo, setIsShowMoreInfo] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [includedItems, setIncludedItems] = useState([]);
  const [isIncludedItems, setIsIncludedItems] = useState([]);
  const [isNotIncludedItems, setIsNotIncludedItems] = useState([]);
  const [optionalItems, setOptionalItems] = useState([]);
  const [notAvailableItems, setNotAvailableItems] = useState([]);
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const { data: quoteData } = data;
  const {
    cost,
    productDetail: {
      legalCover,
      courtesyCar,
      breakdownCover,
      windscreenCover,
      personalAccidentCover,
      defaqto,
      cover,
      excess,
    },
    partnerRef,
    brandInfo,
    companyInfo,
    purchaseChannels,
  } = quoteData;

  const date = dayjs(quoteData.expiresAt).diff(new Date(), "day");

  useEffect(() => {
    const includedItems = Object.keys(quoteData.productDetail)
      .filter(item => item !== "cover" || item !== "isPayByMile" || item !== "isTelematicsBrand" || item !== "excess")
      .filter(item => {
        const itemDetails = quoteData.productDetail[item];
        if (itemDetails.inclusion === "Included") return item;
      });

    setIncludedItems(includedItems);

    const isIncludedItems = Object.keys(quoteData.productDetail)
      .filter(item => item !== "cover" || item !== "isPayByMile" || item !== "isTelematicsBrand" || item !== "excess")
      .filter(item => {
        const itemDetails = quoteData.productDetail[item];
        if (itemDetails.isIncluded || itemDetails?.cover?.isIncluded) return item;
      });

    setIsIncludedItems(isIncludedItems);

    const isNotIncludedItems = Object.keys(quoteData.productDetail)
      .filter(item => item !== "cover" || item !== "isPayByMile" || item !== "isTelematicsBrand" || item !== "excess")
      .filter(item => {
        const itemDetails = quoteData.productDetail[item];

        if (itemDetails?.isIncluded === false || itemDetails?.cover?.isIncluded === false) return item;
      });

    setIsNotIncludedItems(isNotIncludedItems);

    const optionalItems = Object.keys(quoteData.productDetail)
      .filter(item => item !== "cover" || item !== "isPayByMile" || item !== "isTelematicsBrand" || item !== "excess")
      .filter(item => {
        const itemDetails = quoteData.productDetail[item];
        if (itemDetails.inclusion === "Optional") return item;
      });

    setOptionalItems(optionalItems);

    const notAvailableItems = Object.keys(quoteData.productDetail)
      .filter(item => item !== "cover" || item !== "isPayByMile" || item !== "isTelematicsBrand" || item !== "excess")
      .filter(item => {
        const itemDetails = quoteData.productDetail[item];
        if (itemDetails.inclusion === "NotAvailable") return item;
      });

    setNotAvailableItems(notAvailableItems);
  }, [quoteData]);

  return (
    <div>
      <Card body className="mx-auto w-100 top-bar my-2">
        <Row>
          <Col xs={5} className="py-5 brand-logo">
            <img src={brandInfo.logo} alt="brand logo" />
            {defaqto.hasRating ? (
              <div className="rating text-center">
                <img src={`https://motor.confused.com/images/defaqto/${defaqto.value}.png`} alt="rating" />
              </div>
            ) : null}
          </Col>
          <Col xs={7} className="brand-price-info p-0">
            <div className="price-details">
              <h4>Annual price</h4>
              <b className="price--large">
                <span className="price__pounds">£{cost.annual.amount.wholePart}</span>
                <span className="price__pence">.{cost.annual.amount.fractionalPart}</span>
              </b>
            </div>
            <div className="p-3">
              <p className="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <circle cx="8" cy="8" r="8" fill="#1f1f1f" />
                  <path
                    d="M3140.674-5453v5.182h4.04"
                    transform="translate(-3133.154 5457.746)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                </svg>
                <span className="fw-bold ms-1">Expires</span>{" "}
                {date ? ` in ${date} day` : <span className="fw-bold ms-1">today</span>}
              </p>
              {excess?.description === "Information not available" ? null : (
                <div className="excess_item">
                  <Accordion allowZeroExpanded>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span className="excess_info">Total excess: £{excess.total}</span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="p-1">
                        <p className="m-0 mb-1">Voluntary: £{excess.voluntary}</p>
                        <p className="m-0 mb-1">Compulsory: £{excess.compulsory}</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              )}
            </div>
          </Col>
          <Col xs={12}>
            <div className="mt-2">
              <h4>Features</h4>
              <Row>
                <FeatureItem text="Legal Cover" data={legalCover} />
                <FeatureItem text="Courtesy car" data={courtesyCar} />
                <FeatureItem text="Breakdown cover" data={breakdownCover} />
                <FeatureItem text="Windscreen cover" data={windscreenCover} />
                <FeatureItem text="Personal accident cover" data={personalAccidentCover} />
              </Row>
            </div>
          </Col>
          <Col xs={6}>
            <Button variant="dark" className="mt-3 rounded-0 py-2 px-4" onClick={() => setIsShowModal(true)}>
              More info
            </Button>
          </Col>
          <Col xs={6} className="align-self-end">
            {data?._provider ? (
              <div className="text-end">
                <ExternalLink href={data?._provider_url} label={data?._provider} />
              </div>
            ) : null}
          </Col>
        </Row>
      </Card>
      <Modal
        title="All quotes"
        showModal={isShowModal}
        handleModalClose={() => {
          setIsShowModal(false);
        }}
      >
        <Row>
          <Col xs={12} sm={4} className="text-center">
            <img src={brandInfo.logo} alt="brand logo" />
          </Col>
          <Col xs={12} sm={8} className="brand-price-info border-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="price-details m-1">
                <h4>Annual price</h4>
                <b className="price--large">
                  <span className="price__pounds">£{cost.annual.amount.wholePart}</span>
                  <span className="price__pence">.{cost.annual.amount.fractionalPart}</span>
                </b>
              </div>

              <div className="price-details m-1">
                <h4>Annual price</h4>
                <b className="price--large">
                  <span className="price__pounds">£{cost.annual.amount.wholePart}</span>
                  <span className="price__pence">.{cost.annual.amount.fractionalPart}</span>
                </b>
              </div>
            </div>
            <p className="d-flex align-items-center justify-content-center mt-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8" fill="#1f1f1f" />
                <path
                  d="M3140.674-5453v5.182h4.04"
                  transform="translate(-3133.154 5457.746)"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="2"
                />
              </svg>
              <span className="fw-bold ms-1">Expires</span>{" "}
              {date ? ` in ${date} day` : <span className="fw-bold ms-1">today</span>}
            </p>
          </Col>
          <Col xs={12}>
            <a
              className="btn btn-dark rounded-0 px-4 py-2 mb-3"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://motor.confused.com/DeepLinkThrough?brandId=${brandInfo.id}&activityGuid=${data.id.value}&addOnsSelected=false&showReselectAddonMessage=false`}
            >
              Go to {brandInfo.name}
            </a>
          </Col>
          {purchaseChannels.toString().includes("Phone") ? (
            <Col xs={12}>
              <Button
                className="rounded-0 px-4 py-2 mb-3"
                variant="outline-dark"
                onClick={() => setOpenPhoneModal(true)}
              >
                Phone {brandInfo.name}
              </Button>
            </Col>
          ) : null}
          <Col xs={12}>
            <hr />
            <h2 className="title_xl">Policy overview</h2>
            <section>
              <p className="detail">
                <span className="detail__heading">Cover type</span>
                <b className="detail__value" data-id="">
                  {cover}
                </b>
              </p>
              <p className="detail">
                <span className="detail__heading">Quote reference</span>
                <b className="detail__value" data-id="">
                  {partnerRef.value}
                </b>
              </p>
            </section>
          </Col>
          <Col xs={12}>
            <h4 className="title_sm">Excess:</h4>
            {excess?.description ? (
              <p className="fs-6 mt-0 mb-2">{excess?.description}</p>
            ) : (
              <section>
                <p className="detail">
                  <span className="detail__heading">Voluntary</span>
                  <b className="detail__value" data-id="">
                    £{excess.voluntary}
                  </b>
                </p>
                <p className="detail">
                  <span className="detail__heading">Compulsory</span>
                  <b className="detail__value" data-id="">
                    £{excess.compulsory}
                  </b>
                </p>
                <p className="detail">
                  <span className="detail__heading">Total excess</span>
                  <b className="detail__value" data-id="">
                    £{excess.total}
                  </b>
                </p>
              </section>
            )}
          </Col>
          <Col xs={12}>
            <a
              href={`https://motor.confused.com/QuoteSummary?activityIdentifier=${data.id.value}`}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-link ps-0"
            >
              View quote summary
            </a>
          </Col>
          {includedItems.length || isIncludedItems.length || quoteData.productDetail?.guaranteeOfRepairs ? (
            <Col xs={12}>
              <h2 className="title_xl mb-1 mt-2">Policy features included</h2>
              <p className="fs-6 mt-2">Features included as standard or added by you.</p>
              <section>
                <Accordion allowZeroExpanded>
                  {includedItems.map((item, index) => {
                    const itemDetails = quoteData.productDetail[item];
                    const formattedText = formatText(item);
                    return (
                      <AccordionItem key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>{formattedText}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>{helpText[item]}</p>
                          <div className="detail-container">
                            <ul>
                              {itemDetails?.attributes &&
                                Object.keys(itemDetails.attributes).map((label, index) => {
                                  const data = itemDetails.attributes[label];
                                  return (
                                    <li className="detail" key={index}>
                                      <span className="detail__heading">{formatText(label)}</span>
                                      <b className="detail__value">
                                        {data?.amount && !isObject(data?.amount) ? `£${data?.amount}` : null}

                                        {data?.amount && isObject(data?.amount) ? `£${data?.amount.value}` : null}

                                        {data?.description ? data?.description : null}
                                      </b>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                  {isIncludedItems.map((item, index) => {
                    const itemDetails = quoteData.productDetail[item];
                    delete itemDetails?.isIncluded && delete itemDetails?.isIncluded;
                    itemDetails?.cover?.isIncluded && delete itemDetails?.cover?.isIncluded;
                    const formattedText = formatText(item);
                    return (
                      <AccordionItem key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>{formattedText}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>{helpText[item]}</p>
                          <div className="detail-container">
                            <ul>
                              {itemDetails?.attributes &&
                                Object.keys(itemDetails.attributes).map((label, index) => {
                                  const data = itemDetails.attributes[label];
                                  return (
                                    <li className="detail" key={index}>
                                      <span className="detail__heading">{formatText(label)}</span>
                                      <b className="detail__value">
                                        {data?.amount && !isObject(data?.amount) ? `£${data?.amount}` : null}

                                        {data?.cover?.amount && !isObject(data?.amount)
                                          ? `£${data?.cover?.amount}`
                                          : null}

                                        {data?.cover?.amount && isObject(data?.amount)
                                          ? `£${data?.cover?.amount.value}`
                                          : null}

                                        {data?.amount && isObject(data?.amount) ? `£${data?.amount.value}` : null}

                                        {data?.description ? data?.description : null}
                                      </b>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                  {quoteData.productDetail?.guaranteeOfRepairs && (
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>{formatText("guaranteeOfRepairs")}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>{helpText.guaranteeOfRepairs}</p>
                        <div className="detail-container">
                          <ul>
                            {Object.keys(quoteData.productDetail?.guaranteeOfRepairs).map((label, index) => {
                              const data = quoteData.productDetail?.guaranteeOfRepairs[label];
                              return (
                                <li className="detail" key={index}>
                                  <span className="detail__heading">{formatText(label)}</span>
                                  <b className="detail__value">
                                    {data?.amount && !isObject(data?.amount) ? `£${data?.amount}` : null}

                                    {data?.amount && isObject(data?.amount) ? `£${data?.amount.value}` : null}

                                    {data?.description ? data?.description : null}
                                  </b>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  )}
                </Accordion>
              </section>
            </Col>
          ) : null}

          {optionalItems.length ? (
            <Col xs={12}>
              <h2 className="title_xl mb-1 mt-2">Additional policy features available</h2>
              <p className="fs-6 mt-2">Features available to add for an extra cost.</p>

              <section>
                <Accordion allowZeroExpanded>
                  {optionalItems.map((item, index) => {
                    const itemDetails = quoteData.productDetail[item];
                    const formattedText = formatText(item);
                    return (
                      <AccordionItem key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>{formattedText}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="detail-container">
                            <ul>
                              <li className="detail">
                                <span className="detail__heading">Annual price</span>
                                <b className="detail__value">£{itemDetails.cost.annual.amount.value}</b>
                              </li>
                              <li className="detail">
                                <span className="detail__heading">Monthly price</span>
                                <b className="detail__value">
                                  £{itemDetails.cost.monthly.amount.value} (total) split over installments
                                </b>
                              </li>
                            </ul>
                          </div>
                          <p>{helpText[item]}</p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </section>
            </Col>
          ) : null}

          {notAvailableItems.length || isNotIncludedItems.length ? (
            <Col xs={12}>
              <h2 className="title_xl mb-1 mt-2">Not available</h2>
              <p className="fs-6 mt-2">Features not available to add or not requested by you.</p>
              <section>
                <Accordion allowZeroExpanded>
                  {notAvailableItems.map((item, index) => {
                    const itemDetails = quoteData.productDetail[item];
                    const formattedText = formatText(item);
                    return (
                      <AccordionItem key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>{formattedText}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>{helpText[item]}</p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                  {isNotIncludedItems.map((item, index) => {
                    const itemDetails = quoteData.productDetail[item];
                    delete itemDetails.isIncluded;
                    const formattedText = formatText(item);
                    return (
                      <AccordionItem key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>{formattedText}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>{helpText[item]}</p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </section>
            </Col>
          ) : null}

          <Col xs={12}>
            <h2 className="title_xl mb-1 mt-2">About Privilege</h2>
            <p className="fs-6 mt-2" dangerouslySetInnerHTML={{ __html: brandInfo.description }}></p>
            <p className="fs-6 mt-2" dangerouslySetInnerHTML={{ __html: brandInfo.fcaDescription }}></p>
          </Col>
          {defaqto.hasRating ? (
            <Col xs={12}>
              <h4 className="title_sm mb-1 mt-2">Policy rating</h4>

              <div className="expert_rating mb-1">
                <img
                  src={`https://motor.confused.com/images/defaqto-expert-rated/${defaqto.value}.jpg`}
                  alt="rating"
                  className="mt-0"
                />
              </div>

              <div className="excess_item">
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <span className="excess_info fw-normal">What's Defaqto?</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="p-1">
                      <p>
                        Defaqto is an independent financial research company. It independently assesses all products in
                        the market, and gives each a rating from 1 to 5 stars based on the quality and comprehensiveness
                        of the features and benefits it offers. This helps you see, at a glance, where a product sits in
                        the market. A 1 star-rated product is a basic product, with a low level of features and
                        benefits. Whereas 5 star-rated products provide a comprehensive range of features and benefits.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </Col>
          ) : null}
        </Row>
      </Modal>
      <Modal
        title="Close"
        variant="close"
        showModal={openPhoneModal}
        handleModalClose={() => {
          setOpenPhoneModal(false);
        }}
      >
        {companyInfo ? (
          <Row>
            <Col xs={12} sm={4} className="text-center">
              <img src={companyInfo.brand.logo} alt="brand logo" />
            </Col>
            <Col xs={12} sm={8} className="brand-price-info border-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className="price-details m-1">
                  <h4>Annual price</h4>
                  <b className="price--large">
                    <span className="price__pounds">£{cost.annual.amount.wholePart}</span>
                    <span className="price__pence">.{cost.annual.amount.fractionalPart}</span>
                  </b>
                </div>

                <div className="price-details m-1">
                  <h4>Annual price</h4>
                  <b className="price--large">
                    <span className="price__pounds">£{cost.annual.amount.wholePart}</span>
                    <span className="price__pence">.{cost.annual.amount.fractionalPart}</span>
                  </b>
                </div>
              </div>
              <p className="d-flex align-items-center justify-content-center mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <circle cx="8" cy="8" r="8" fill="#1f1f1f" />
                  <path
                    d="M3140.674-5453v5.182h4.04"
                    transform="translate(-3133.154 5457.746)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                </svg>
                <span className="fw-bold ms-1">Expires</span>{" "}
                {date ? ` in ${date} day` : <span className="fw-bold ms-1">today</span>}
              </p>
            </Col>
            <Col xs={12}>
              <h3 className="phone">
                Call:{" "}
                <a
                  href={`tel://${companyInfo.phone.number}`}
                  target="_blank"
                  className="telephone-number-link"
                  rel="noreferrer"
                >
                  {companyInfo.phone.number}
                </a>
              </h3>
              <p className="fs-6 mt-0">
                You could save time if you{" "}
                <a
                  href={`https://motor.confused.com/Prices?activityIdentifier=${companyInfo.quoteSetId}#`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dark-link"
                >
                  buy your policy online
                </a>
              </p>
              <div className="detail mt-3 mb-4">
                <span className="detail__heading">Quote reference</span>
                <b className="detail__value">{companyInfo.phone.quoteReference}</b>
              </div>
            </Col>
            <Col xs={12}>
              <h4 className="title_sm mb-1">Opening times:</h4>
              <ul className="list-unstyled">
                {companyInfo.phone.openingTimes.map((item, index) => (
                  <li key={index} className="fw-medium fs-6 my-1">
                    {item}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        ) : null}
      </Modal>
    </div>
  );
};

export default QuoteCard;
