import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { MEDIA_QUERY } from "constants/general";

const SelectFrom = ({ value, handleChange, isRequired, title, data }) => {
  const isSmallScreen = useMediaQuery({
    query: MEDIA_QUERY.SMALL_MOBILE,
  });
  return (
    <div className={classNames("form-group", "select-box", { ["mt-3"]: !isSmallScreen }, { ["mt-2"]: isSmallScreen })}>
      <select
        size="sm"
        value={value}
        className="input-form-style hide-arrow form-control"
        onChange={handleChange}
        required={isRequired}
      >
        <option className="input-form-style form-control" value={""}>
          {title}
        </option>
        {data?.map((item, index) => (
          <option value={item.toUpperCase()} key={index}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectFrom.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

SelectFrom.defaultProps = {
  type: "text",
  autoFocus: false,
  isRequired: false,
};

export default SelectFrom;
