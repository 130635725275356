import styled from "@emotion/styled";
import ArcText from "./ArcText";

export const GraphWrapper = styled.div`
  height: calc(100vh - 41.17px) !important;
  @media (min-width: 993px) {
    height: calc(100vh - 174.17px) !important;
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const CircleContainer = styled.div`
  max-width: 740px;
  width: 100%;
  text-align: center;
  @media (max-height: 980px) {
    max-width: 600px;
  }
  @media (max-height: 890px) {
    max-width: 660px;
  }
  @media (max-height: 840px) {
    max-width: 640px;
  }
  @media (max-height: 800px) {
    max-width: 590px;
  }
  @media (max-height: 777px) {
    max-width: 520px;
  }
  @media (max-height: 740px) {
    max-width: 480px;
  }
  @media (max-height: 680px) {
    max-width: 420px;
  }
  @media (max-height: 600px) {
    max-width: 380px;
  }
  @media (max-height: 560px) {
    max-width: 340px;
  }
  @media (max-height: 530px) {
    max-width: 300px;
  }
  @media (max-height: 480px) {
    max-width: 260px;
  }
  @media (max-height: 450px) {
    max-width: 220px;
  }
  @media (max-height: 420px) {
    max-width: 240px;
  }
  @media (max-height: 320px) {
    max-width: 140px;
  }
`;

export const Circle = styled.div`
  background: transparent;
  position: relative;
  display: block;
  text-align: center;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    display: block;
    padding-bottom: 100%;
    width: 100%;
    height: 0;
    border-radius: 50%;
    background: transparent;
    background: rgba(233, 213, 218, 1);
    background-image: linear-gradient(310deg, rgba(233, 213, 218, 1) 50%, transparent 50%),
      linear-gradient(296.5deg, rgba(233, 213, 218, 1) 50%, transparent 50%),
      linear-gradient(296.5deg, rgba(255, 255, 255, 1) 50.3%, transparent 50.3%),
      linear-gradient(243.5deg, rgba(192, 208, 226, 1) 50%, transparent 50%),
      linear-gradient(243.5deg, rgba(255, 255, 255, 1) 50.3%, transparent 50.3%);
    content: "";
  }

  .circle__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .circle__wrapper {
    display: table;
    width: 100%;
    height: 100%;
  }

  .circle__content {
    display: table-cell;
    vertical-align: middle;
  }
`;

export const ArcCircle = styled(ArcText)`
  background-color: transparent;
  border-width: 50%;
  // Applied to each characters
  & span.character {
    font-size: 16px;
    text-transform: uppercase;
    @media (max-width: 480px) {
      font-size: 10px;
    }
    font-family: monospace, sans-serif;
  }
`;
