import React from "react";
import { Container, FolderIconContainer, SmallIcon } from "./GroupIcon.styles";
import PropTypes from "prop-types";

const GroupIcon = ({ groupIcon }) => {
  return (
    <Container>
      <FolderIconContainer className="image-wrapper">
        <div>
          <FolderIconContainer className="image-wrapper">
            {groupIcon.slice(-9).map(_data => {
              return <SmallIcon className="small-icons-img" src={_data.icon} alt="address" key={_data.key} />;
            })}
          </FolderIconContainer>
        </div>
      </FolderIconContainer>
    </Container>
  );
};

GroupIcon.propTypes = {
  groupIcon: PropTypes.array.isRequired,
};

export default GroupIcon;
