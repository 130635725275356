import {
  businessDetailsBoxIconsData,
  graphBoxIconsData,
  testHarnessBoxIconsData,
  boxRobotIconsData,
  boxRobotTwoIconsData,
  businessRobotsIconsData,
  comparisonRobotsIconsData,
} from "data/iconsData";
import SliderBox from "components/quote/SliderBox";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERY } from "constants/general";

const pageTitle = "Home";
const boxTitle = "Test Harness";

const Home = () => {
  const isBigScreen = useMediaQuery({
    query: MEDIA_QUERY.BIG_SCREEN,
  });

  return (
    <div className={`w-100 h-100 ${isBigScreen ? "" : "min-vh-100"} d-flex justify-content-center align-items-center`}>
      <div className="mx-auto panel-wrapper">
        <div className={`d-flex ${isBigScreen ? "flex-row" : "flex-column"} flex-wrap justify-content-center`}>
          <SliderBox
            pageTitle={pageTitle}
            icons={testHarnessBoxIconsData}
            boxTitle={boxTitle}
            classes="panel"
            showNavigateIcon={false}
          />
          <SliderBox
            pageTitle={pageTitle}
            icons={graphBoxIconsData}
            boxTitle={"Graphs"}
            classes="panel"
            showNavigateIcon={false}
          />
          <SliderBox
            pageTitle={pageTitle}
            icons={businessDetailsBoxIconsData}
            boxTitle={"Business Data"}
            classes="panel"
            showNavigateIcon={false}
          />
          <SliderBox
            pageTitle={pageTitle}
            icons={businessRobotsIconsData}
            boxTitle={"Business Robots"}
            classes="panel"
            showNavigateIcon={false}
          />
          <SliderBox
            pageTitle={pageTitle}
            icons={comparisonRobotsIconsData}
            boxTitle={"Comparison Robots"}
            classes="panel"
            showNavigateIcon={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
