export const getShouldCombine = (id, items, newIndex, collisions, active) => {
  if (!items) {
    return false;
  }
  const newId = items[newIndex];

  if (!collisions || collisions.length < 2) {
    return false;
  }

  const { id: secondCollidingId, data: collisionData } = collisions[1];

  if (!collisionData) {
    return false;
  }
  const collisionRatio = collisionData.value;

  // NOTE: This can be improved with a custom dnd-kit collision detection
  return (
    secondCollidingId === newId &&
    collisionRatio != null &&
    id !== active?.id &&
    collisionRatio > 0.1 &&
    collisionRatio < 0.4
  );
};
