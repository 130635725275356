/* eslint-disable react/prop-types */
import React from "react";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FeaturesAccordionItem = ({ item }) => {
  if (item.included) {
    return (
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <div className="d-flex justify-content-between align-items-center w-100">
              <p className="m-0 fw-bold">{item.featureTitle}</p>
              <div className="badge_feature_sm rounded-circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div className="mb-3">
            <p className="text-green fw-bold m-0">Policy includes</p>
            {item.details.map((data, index) => (
              <div key={index} className="d-flex align-items-center">
                <div className="badge_feature_sm rounded-circle me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </div>
                <p className="fw-bold p-0 m-0 my-1">{data}</p>
              </div>
            ))}
            <div className="my-3">
              {item.detailsUpgradable.length ? <p className="text-green fw-bold m-0">Available to add</p> : null}
              {item.detailsUpgradable.map((data, index) => (
                <div key={index} className="d-flex align-items-center">
                  <div className="badge_excluded_sm text-center rounded-circle me-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7px" height="7px" viewBox="0 0 7 7" version="1.1">
                      <g id="Mobile-results-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g
                          id="GoCo-results-small-mobile-320px---Monthly-V2"
                          transform="translate(-32.000000, -606.000000)"
                          fill="#3FA534"
                          fillRule="nonzero"
                        >
                          <g id="Group-3" transform="translate(16.000000, 453.000000)">
                            <path
                              d="M22.3,155.8 L20.2,155.8 L20.2,153.7 C20.2,153.313401 19.8865993,153 19.5,153 C19.1134007,153 18.8,153.313401 18.8,153.7 L18.8,155.8 L16.7,155.8 C16.3134007,155.8 16,156.113401 16,156.5 C16,156.886599 16.3134007,157.2 16.7,157.2 L18.8,157.2 L18.8,159.3 C18.8,159.686599 19.1134007,160 19.5,160 C19.8865993,160 20.2,159.686599 20.2,159.3 L20.2,157.2 L22.3,157.2 C22.6865993,157.2 23,156.886599 23,156.5 C23,156.113401 22.6865993,155.8 22.3,155.8 Z"
                              id="Shape"
                              transform="translate(19.500000, 156.500000) rotate(-270.000000) translate(-19.500000, -156.500000) "
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <p className="p-0 m-0 my-1">{data}</p>
                </div>
              ))}
              {item.detailsUpgradable.length ? (
                <p className="alert sm_text text-start mt-2">
                  You can easily upgrade this cover on the insurer’s website or over the phone.
                </p>
              ) : null}
            </div>
          </div>
          <p className="sm_text">{item.helpText}</p>
          <p className="fw-bold sm_text">
            More cover options such as optional extras or upgrades may be available when you continue to the insurer’s
            website
          </p>
        </AccordionItemPanel>
      </AccordionItem>
    );
  }

  if (item.hasDefaqtoCertifiedUpgrade) {
    return (
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <div className="d-flex justify-content-between align-items-center w-100">
              <p className="m-0 fw-bold">{item.featureTitle}</p>
              <div className="badge_excluded_sm text-center rounded-circle">
                <svg xmlns="http://www.w3.org/2000/svg" width="7px" height="7px" viewBox="0 0 7 7" version="1.1">
                  <g id="Mobile-results-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g
                      id="GoCo-results-small-mobile-320px---Monthly-V2"
                      transform="translate(-32.000000, -606.000000)"
                      fill="#3FA534"
                      fillRule="nonzero"
                    >
                      <g id="Group-3" transform="translate(16.000000, 453.000000)">
                        <path
                          d="M22.3,155.8 L20.2,155.8 L20.2,153.7 C20.2,153.313401 19.8865993,153 19.5,153 C19.1134007,153 18.8,153.313401 18.8,153.7 L18.8,155.8 L16.7,155.8 C16.3134007,155.8 16,156.113401 16,156.5 C16,156.886599 16.3134007,157.2 16.7,157.2 L18.8,157.2 L18.8,159.3 C18.8,159.686599 19.1134007,160 19.5,160 C19.8865993,160 20.2,159.686599 20.2,159.3 L20.2,157.2 L22.3,157.2 C22.6865993,157.2 23,156.886599 23,156.5 C23,156.113401 22.6865993,155.8 22.3,155.8 Z"
                          id="Shape"
                          transform="translate(19.500000, 156.500000) rotate(-270.000000) translate(-19.500000, -156.500000) "
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div className="mb-3">
            {item.detailsUpgradable.length ? <p className="text-green fw-bold m-0">Available to add</p> : null}
            {item.detailsUpgradable.map((data, index) => (
              <div key={index} className="d-flex align-items-center">
                <div className="badge_excluded_sm text-center rounded-circle me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="7px" height="7px" viewBox="0 0 7 7" version="1.1">
                    <g id="Mobile-results-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g
                        id="GoCo-results-small-mobile-320px---Monthly-V2"
                        transform="translate(-32.000000, -606.000000)"
                        fill="#3FA534"
                        fillRule="nonzero"
                      >
                        <g id="Group-3" transform="translate(16.000000, 453.000000)">
                          <path
                            d="M22.3,155.8 L20.2,155.8 L20.2,153.7 C20.2,153.313401 19.8865993,153 19.5,153 C19.1134007,153 18.8,153.313401 18.8,153.7 L18.8,155.8 L16.7,155.8 C16.3134007,155.8 16,156.113401 16,156.5 C16,156.886599 16.3134007,157.2 16.7,157.2 L18.8,157.2 L18.8,159.3 C18.8,159.686599 19.1134007,160 19.5,160 C19.8865993,160 20.2,159.686599 20.2,159.3 L20.2,157.2 L22.3,157.2 C22.6865993,157.2 23,156.886599 23,156.5 C23,156.113401 22.6865993,155.8 22.3,155.8 Z"
                            id="Shape"
                            transform="translate(19.500000, 156.500000) rotate(-270.000000) translate(-19.500000, -156.500000) "
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <p className="fw-bold p-0 m-0 my-1">{data}</p>
              </div>
            ))}
          </div>
          <p className="sm_text">{item.helpText}</p>
          <p className="fw-bold sm_text">
            More cover options such as optional extras or upgrades may be available when you continue to the insurer’s
            website
          </p>
        </AccordionItemPanel>
      </AccordionItem>
    );
  }

  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          <div className="d-flex justify-content-between align-items-center w-100">
            <p className="m-0 text-muted">{item.featureTitle}</p>
            <div className="badge_light_sm rounded-circle">
              <svg
                data-v-69d7a821=""
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line> <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="px-0 pt-3">
        <p className="alert sm_text">No option to upgrade on this policy</p>
        <p className="fw-bold sm_text">
          More cover options such as optional extras or upgrades may be available when you continue to the insurer’s
          website
        </p>
      </AccordionItemPanel>
    </AccordionItem>
  );
};

export default FeaturesAccordionItem;
