import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { GraphContentContainer } from "components/graphUI";
import EmailGraph from "./EmailGraph.js";

const ValidateEmailGraph = ({ apiResponse, email }) => {
  const [isShowGraph, setIsShowGraph] = useState(false);
  const [graphData, setGraphData] = useState(null);
  return isShowGraph ? (
    <EmailGraph graphData={graphData} setIsShowGraph={setIsShowGraph} email={email} />
  ) : (
    <GraphContentContainer
      setIsShowGraph={setIsShowGraph}
      setGraphData={setGraphData}
      defaultValue={apiResponse}
      isCheckChild={false}
    />
  );
};

ValidateEmailGraph.propTypes = {
  apiResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  email: PropTypes.string.isRequired,
};

export default ValidateEmailGraph;
