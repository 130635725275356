/** @jsxImportSource @emotion/react */
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Pages
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Check from "pages/Check";
import GetCarQuotesPage from "pages/carQuotes";

import Detail from "pages/DetailPage";
import ValidateDlN from "pages/ValildateDLN";

import "styles/AuthenticatedApp.css";
import ValidateAddress from "pages/address/ValidateAddress";
import ValidateName from "pages/ValidateName";
import MultipleContainersCombinePage from "pages/MultipleContainersCombine";
import Graph from "pages/Graph";
import QuotePage from "pages/Quote";
// import QuoteTimelinePage from "pages/QuoteTimelinePage";
import TestHarness from "pages/TestHarness";
import Graphs from "pages/Graphs";
import Robots from "pages/Robots";
import Search from "pages/Search";
import ConeGraphPage from "pages/ConeGraphPage";
import ValidateTelephone from "pages/ValidateTelephone";
import ValidateEmail from "pages/ValidateEmail";
import GetVanQuotesPage from "../pages/vanQuotes";
import GetHouseQuotesPage from "../pages/houseQuotes";
import GetBikeQuotesPage from "../pages/bikeQuotes";
import GetTravelQuotesPage from "../pages/travelQuotes";
import GetPetQuotesPage from "../pages/petQuotes";
import GetQuotesFromQuoteZonePage from "../pages/QuotezoneServices";
import GetQuotesFromDirectLinePage from "../pages/DirectLineServices";
import GetQuotesFromMoneySuperMarketPage from "../pages/MoneySuperMarketServices";
import GetQuotesFromMoneyConfusedPage from "../pages/ConfusedServices";
import ValidateCompanyByYell from "../pages/YellServices";
import MatchName from "pages/MatchName";
import ScrapeBusinessDetailsThomson from "pages/ScrapeBusinessDetailsThomson";
import Companies from "pages/Companies";
import GetQuotesFromGoComparePage from "../pages/GoCompareServices";
import GetQuotesFromCompareTheMarketPage from "../pages/CompareTheMartketSevices";
import { Percayso } from "pages/percayso";
import { Kibana } from "pages/kibana";
import KibanaRowCountTable from "components/kibanaChart/KibanaRowCountTable";
import KibanaSummaryReport from "components/kibanaChart/KibanaSummaryReport";
import PieChart from "components/kibanaChart/PieChart";
import { CarInsurance, GoCompareServices } from "pages/goCompare";
import { ConfusedCarInsurance } from "pages/confused";
import { SearchBrands } from "pages/searchBrands";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/test-harness" element={<TestHarness />} />
      <Route path="/graphs" element={<Graphs />} />
      <Route path="/robots" element={<Robots />} />
      <Route path="/multi-box" element={<MultipleContainersCombinePage />} />
      <Route path="/graph" element={<Graph />} />
      <Route path="/quote" element={<QuotePage />} />
      <Route path="/search" element={<Search />} />
      <Route path="/cone" element={<ConeGraphPage />} />
      {/* <Route path="/quote-timeline" element={<QuoteTimelinePage />} /> */}
      <Route path="/validate-company" element={<Check />} />
      <Route path="/validate-company-yell" element={<ValidateCompanyByYell />} />
      <Route path="/get-car-quotes" element={<GetCarQuotesPage />} />
      <Route path="/get-van-quotes" element={<GetVanQuotesPage />} />
      <Route path="/get-house-quotes" element={<GetHouseQuotesPage />} />
      <Route path="/get-bike-quotes" element={<GetBikeQuotesPage />} />
      <Route path="/get-travel-quotes" element={<GetTravelQuotesPage />} />
      <Route path="/get-pet-quotes" element={<GetPetQuotesPage />} />
      <Route path="/get-quotes-from-quote-zone" element={<GetQuotesFromQuoteZonePage />} />
      <Route path="/get-quotes-from-direct-line" element={<GetQuotesFromDirectLinePage />} />
      <Route path="/get-quotes-from-money-supermarket" element={<GetQuotesFromMoneySuperMarketPage />} />
      <Route path="/get-quotes-from-confused" element={<GetQuotesFromMoneyConfusedPage />} />
      <Route path="/get-quotes-from-go-compare" element={<GetQuotesFromGoComparePage />} />
      <Route path="/get-quotes-from-compare-the-market" element={<GetQuotesFromCompareTheMarketPage />} />
      <Route path="/check/detail/:id" element={<Detail />} />
      <Route path="/validate-address" element={<ValidateAddress />} />
      <Route path="/validate-dln" element={<ValidateDlN />} />
      <Route path="/validate-tel" element={<ValidateTelephone />} />
      <Route path="/validate-email" element={<ValidateEmail />} />
      <Route path="/validate-name" element={<ValidateName />} />
      <Route path="/match-name" element={<MatchName />} />
      <Route path="/scrape-business-details" element={<ScrapeBusinessDetailsThomson />} />
      <Route path="/search-companies" element={<Companies />} />
      <Route path="/percayso" element={<Percayso />} />
      <Route path="/kibana" element={<Kibana />} />
      <Route path="/chart-1" element={<KibanaRowCountTable />} />
      <Route path="/chart-2" element={<KibanaRowCountTable />} />
      <Route path="/summary" element={<KibanaSummaryReport />} />
      <Route path="/pie-chart" element={<PieChart />} />
      <Route path="/go-compare-services" element={<GoCompareServices />} />
      <Route path="/car-insurance" element={<CarInsurance />} />
      <Route path="/confused-car-insurance" element={<ConfusedCarInsurance />} />
      <Route path="/search-brands" element={<SearchBrands />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default AppRoutes;
