import React from "react";

// eslint-disable-next-line
const MessageParser = ({ children, actions }) => {
  const parse = message => {
    if (message.includes("hello")) {
      // eslint-disable-next-line
      actions.handleHello();
    }
    if (message.includes("widget")) {
      // eslint-disable-next-line
      actions.handleWidget();
    }
  };

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
