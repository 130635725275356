/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import "./KibanaRowCountTable.style.scss";
import { useIsShowNavBar } from "hooks/useIsShowNavBar";
import BootstrapTable from "react-bootstrap-table-next";
import { client } from "utils/apiClient";
import { config } from "./../../config/index";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import TableCell from "./TableCell";
import { Loader } from "components/lib";
import useSWR from "swr";

const columns = [
  {
    dataField: "category",
    text: "HubSpot Data Summary by Object",
  },
  {
    dataField: "count",
    text: "Count",
  },
];

const companySubTableColumns = [
  {
    dataField: "_source.properties.name",
    text: "Company Name",
  },
  {
    dataField: "_source.properties.phone",
    text: "Phone",
  },
  {
    dataField: "_source.properties.original_address",
    text: "Address",
  },
  {
    dataField: "_source.properties.country",
    text: "Country",
  },
];

const SubCompanyTable = ({ subCompanyTableData }) => {
  return (
    <div className="sub-company-table">
      <BootstrapTable keyField="_id" data={subCompanyTableData} columns={companySubTableColumns} bordered={false} />
    </div>
  );
};

const KibanaSummaryReport = () => {
  const [isShowCompaniesSubTable, setIsShowCompaniesSubTable] = useState(false);
  const [isSubTableLoading, setIsSubTableLoading] = useState(false);
  const [subCompanyTableData, setCompanySubTableData] = useState([]);
  const { setIsShowNavBar } = useIsShowNavBar();

  const {
    percayso: { apiUrl: API_URL },
  } = config;

  async function fetcher(url) {
    const data = await client(url);
    return data;
  }

  const { data, error } = useSWR(`${API_URL}/pipelines-data-count`, fetcher);

  const formatCategoryName = categoryName => {
    // if (categoryName === "Percayso" || categoryName === "Parent Company") {
    //   return "Percayso;Parent Company";
    // }
    return categoryName;
  };

  const handleSubTable = async companyType => {
    if (companyType === "Sub Total") return;
    setIsSubTableLoading(true);
    try {
      const data = await client(`${API_URL}/get-companies?companyType=${companyType}`);
      setCompanySubTableData(data.data);
      setIsShowCompaniesSubTable(true);
    } finally {
      setIsSubTableLoading(false);
    }
  };

  const handleSubTableClose = () => {
    setCompanySubTableData([]);
    setIsShowCompaniesSubTable(false);
  };

  useEffect(() => {
    setIsShowNavBar(false);
    return () => {
      setIsShowNavBar(true);
    };
  }, []);

  if (!data) {
    return (
      <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
        <div className="iframe-box bg-white rounded ">
          <Link to="/percayso" className="close-btn">
            <FontAwesomeIcon icon={faXmark} />
          </Link>
          <div className="iFrame-style overflow-auto">
            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
              <Loader />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const expandRow = {
    renderer: row => {
      switch (row.category) {
        case "Companies":
          return (
            <div className="container-fluid">
              {data.length
                ? data
                    .filter(item => item.category === "Companies")[0]
                    .data.map((item, i) => {
                      return (
                        <div key={i} className="d-flex justify-content-between align-item-center px-5">
                          <p
                            className="m-0 mb-2 cursor_pointer"
                            onClick={() => handleSubTable(formatCategoryName(item.category))}
                          >
                            {item.category}
                          </p>
                          <p className="m-0 mb-2">{item.count}</p>
                        </div>
                      );
                    })
                : 0}
            </div>
          );
        case "Contacts":
          return null;
        case "Pipeline Deals":
          return (
            <div className="container-fluid">
              {data.length
                ? data
                    .filter(item => item.category === "Pipeline Deals")[0]
                    .data.map(item => <TableCell key={item.id} name={item.category} count={item.count} />)
                : 0}
            </div>
          );
        case "Products":
          return null;

        default:
          return null;
      }
    },
    expanded: data.map((_, i) => i + 1),
    expandByColumnOnly: true,
  };

  return (
    <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
      <div className="iframe-box bg-white rounded ">
        {isShowCompaniesSubTable ? (
          <div className="close-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="black"
              style={{ width: "24px", height: "24px" }}
              className="cursor_pointer"
              onClick={handleSubTableClose}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
            </svg>
          </div>
        ) : (
          <Link to="/percayso" className="close-btn">
            <FontAwesomeIcon icon={faXmark} />
          </Link>
        )}
        <div className="iFrame-style overflow-auto">
          {isShowCompaniesSubTable ? (
            isSubTableLoading ? (
              <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            ) : (
              <SubCompanyTable subCompanyTableData={subCompanyTableData} />
            )
          ) : (
            <div className="summary-container">
              <BootstrapTable keyField="id" data={data} columns={columns} bordered={false} expandRow={expandRow} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KibanaSummaryReport;
