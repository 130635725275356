import React, { useState } from "react";
import { Meta } from "components/layout";
import { InputForm } from "components/forms";
import { FieldsWrapper, Loader, LoadingButton } from "components/lib";
import "../styles/validateAddress.css";
import { config } from "../config";
import { client } from "utils/apiClient";
import ValidateTelephoneGraph from "components/telephoneGraph";

const ValidateTelephone = () => {
  const [apiResponse, setApiResponse] = useState("");
  const [nextPage, setNextPage] = useState(0);
  const [telephone, setTelephone] = useState("");
  const [loading, setLoading] = useState(false);
  const [telValidationMessage, setTelValidationMessage] = useState("");
  const pageTitle = "Validate Telephone";
  const handleSubmit = async e => {
    e.preventDefault();
    setTelValidationMessage("");

    if (!telephone) {
      setTelValidationMessage("Telephone field required!");
      return;
    }

    setLoading(true);

    try {
      const {
        validateTel: { apiUrl: API_URL },
      } = config;

      const apiResponse = await client(API_URL, {
        data: {
          tel: telephone,
        },
      });

      if (apiResponse.status === "VALID") {
        setApiResponse(apiResponse);
        setNextPage(1);
      } else setTelValidationMessage(`Telephone verification failed. ${apiResponse?.message}`);
    } catch (error) {
      setTelValidationMessage(error?.tel[0] || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };
  const handleAnother = () => {
    setTelephone("");
    setNextPage(0);
  };
  return (
    <div className="address-form-wrapper">
      <Meta title={pageTitle} />
      {nextPage === 0 ? (
        <div>
          <form onSubmit={handleSubmit} className="mt-3">
            <FieldsWrapper>
              <InputForm
                type={"text"}
                value={telephone}
                handleChange={event => setTelephone(event.target.value.replace(/[^0-9]+/g, ""))}
                placeholder={"Telephone number"}
                autoFocus
              />
            </FieldsWrapper>
            <div className="btn-container">
              <LoadingButton type="submit" className="btn-validate mt-1">
                {loading ? <Loader /> : "Submit"}
              </LoadingButton>
            </div>
          </form>
          <div className="name-validate-error">{telValidationMessage}</div>
        </div>
      ) : (
        <div>
          <ValidateTelephoneGraph apiResponse={apiResponse} />
          {/* <div className="btn-container">
            <LoadingButton type="submit" onClick={handleAnother} className="btn-validate mt-4">
              {loading ? <Loader /> : "Another"}
            </LoadingButton>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ValidateTelephone;
