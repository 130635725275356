/* eslint-disable react/prop-types */
import React from "react";

const CloseBtn = ({ onClose, classes, variant = "back" }) => (
  <button className={`slideout__close ${classes}`} onClick={onClose}>
    {variant === "back" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.171"
        height="34.308"
        viewBox="0 0 34.171 34.308"
        style={{ transform: "rotate(180deg)", width: 13, height: 13 }}
      >
        <path
          d="M79.79,11.9l-4.242,4.189,9.545,9.328H63.671v5.873H85.093l-9.545,9.328,4.242,4.189L96.626,28.353Z"
          transform="translate(-63.171 -11.199)"
          fill="#000"
          stroke="#000"
          strokeWidth="1"
        />
      </svg>
    ) : (
      <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Close Icon</title>
        <desc>Close Icon</desc>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-811.000000, -2028.000000)" fill="#2E1B23" fillRule="nonzero">
            <g transform="translate(152.000000, 1852.000000)">
              <g id="Close-icon" transform="translate(0.000000, 144.000000)">
                <path
                  d="M667.657429,38.462564 L671.597417,34.5225767 C672.148132,33.9315591 672.131883,33.0105621 671.56066,32.4393398 C670.989438,31.8681174 670.068441,31.8518675 669.477423,32.4025835 L665.537436,36.3425708 L661.597449,32.4025835 C661.006431,31.8518675 660.085434,31.8681174 659.514212,32.4393398 C658.942989,33.0105621 658.926739,33.9315591 659.477455,34.5225767 L663.417443,38.462564 L659.477455,42.4025514 C659.07615,42.7764914 658.910959,43.3396595 659.046686,43.8711245 C659.182413,44.4025896 659.59741,44.8175875 660.128875,44.9533143 C660.66034,45.0890412 661.223509,44.9238497 661.597449,44.5225446 L665.537436,40.5825573 L669.477423,44.5225446 C670.068441,45.0732606 670.989438,45.0570107 671.56066,44.4857883 C672.131883,43.914566 672.148132,42.993569 671.597417,42.4025514 L667.657429,38.462564 Z"
                  id="Close"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )}
  </button>
);

export default CloseBtn;
