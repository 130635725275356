import React from "react";
import PropTypes from "prop-types";

function Overview(props) {
  const { company } = props;

  return (
    <div className="overview">
      <div className="info-wrap">
        <p className="detail-title">Registered Company Address</p>
        <p className="detail">{company.address.toLowerCase()}</p>
      </div>
      <div className="info-wrap">
        <p className="detail-title">Company Status</p>
        <p className="detail">{company.status.toLowerCase()}</p>
      </div>
      <div className="type-inc">
        <div>
          <p className="detail-title">Company Type</p>
          <p className="detail">{company.category.toLowerCase()}</p>
        </div>
        <div>
          <p className="detail-title">Incorporated on</p>
          <p className="detail">{new Date(company.incorporated.toLowerCase()).toDateString()}</p>
        </div>
      </div>
      <div className="info-wrap">
        <p className="detail-title">Nature of Business (SIC)</p>
        <p className="detail">{company.sic}</p>
      </div>
    </div>
  );
}

Overview.propTypes = {
  company: PropTypes.shape({
    address: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    incorporated: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    sic: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default Overview;
