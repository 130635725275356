import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const Meta = ({ title }) => {
  return (
    <Helmet>
      <title>Percayso Test Harness</title>
      <link rel="shortcut icon" href="/favicon.ico" />
    </Helmet>
  );
};

Meta.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Meta;
