import percaysoRed from "assest/graph/percayso-red.png";
import percaysoInform from "assest/graph/percayso-inform.png";
import { GraphColor, GraphGroup } from "constants/general";

export const jsonData = {
  titleTop: `Franchises`,
  titleBottom: `Percayso Limited`,
  id: "main",
  group: "main",
  label: "main",
  image: percaysoRed,
  level: 0,
  child: [
    {
      id: "upMain",
      group: GraphGroup.BLUE_GROUP,
      label: "upMain",
      image: percaysoInform,
      level: 1,
      child: [
        {
          id: "upper-child-center",
          group: GraphGroup.BLUE_GROUP,
          label: "Portal",
          color: GraphColor.madison,
          level: 2,
        },
        {
          id: "upper-child-left",
          group: GraphGroup.BLUE_GROUP,
          label: "Website",
          color: GraphColor.madison,
          level: 2,
        },
        {
          id: "upper-child-right",
          group: GraphGroup.BLUE_GROUP,
          label: "Propositions",
          color: GraphColor.madison,
          level: 2,
        },
      ],
    },
    {
      id: "right",
      group: GraphGroup.PINK_GROUP,
      label: "Propositions",
      color: GraphColor.cosmicDeep,
      level: 1,
      child: [
        {
          id: "right-child-1",
          group: GraphGroup.PINK_GROUP,
          label: "Deploy",
          color: GraphColor.frenchRose,
          level: 2,
          child: [
            {
              id: "right-child-child-1",
              group: GraphGroup.PINK_GROUP,
              label: "Platform",
              color: GraphColor.froly,
              level: 3,
              child: [
                {
                  id: "right-child-child-child-1",
                  group: GraphGroup.PINK_GROUP,
                  label: "Services",
                  color: GraphColor.saffronMango,
                  textColor: GraphColor.black,
                  level: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "downRight",
      group: GraphGroup.PINK_GROUP,
      label: "Territories",
      color: GraphColor.cosmicDeep,
      level: 1,
      child: [
        {
          id: "downRight-child-1",
          group: GraphGroup.PINK_GROUP,
          label: "Countries",
          color: GraphColor.frenchRose,
          level: 2,
          child: [
            {
              id: "downRight-child-child-1",
              group: GraphGroup.PINK_GROUP,
              label: "Regions",
              color: GraphColor.froly,
              level: 3,
              child: [
                {
                  id: "downRight-child-child-child-1",
                  group: GraphGroup.PINK_GROUP,
                  label: "Sectors",
                  color: GraphColor.saffronMango,
                  textColor: GraphColor.black,
                  level: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "downLeft",
      group: GraphGroup.PINK_GROUP,
      label: "Access",
      color: GraphColor.cosmicDeep,
      level: 1,
      child: [
        {
          id: "downLeft-child-1",
          group: GraphGroup.PINK_GROUP,
          label: "Roles",
          color: GraphColor.frenchRose,
          level: 2,
          child: [
            {
              id: "downLeft-child-child-1",
              group: GraphGroup.PINK_GROUP,
              label: "Policies",
              color: GraphColor.froly,
              level: 3,
              child: [
                {
                  id: "downLeft-child-child-child-1",
                  group: GraphGroup.PINK_GROUP,
                  label: "Permissions",
                  color: GraphColor.saffronMango,
                  textColor: GraphColor.black,
                  level: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "leftDown",
      group: GraphGroup.PINK_GROUP,
      label: "Portal",
      color: GraphColor.cosmicDeep,
      level: 1,
      child: [
        {
          id: "leftDown-child-center",
          group: GraphGroup.PINK_GROUP,
          label: "Investors",
          color: GraphColor.frenchRose,
          level: 2,
          child: [
            {
              id: "leftDown-child-child-center",
              group: GraphGroup.PINK_GROUP,
              label: "Let's talk",
              color: GraphColor.froly,
              level: 3,
              child: [
                {
                  id: "leftDown-child-child-child-center",
                  group: GraphGroup.PINK_GROUP,
                  label: "Onboarding",
                  color: GraphColor.saffronMango,
                  textColor: GraphColor.black,
                  level: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "leftUp",
      group: GraphGroup.PINK_GROUP,
      label: "Partners",
      color: GraphColor.cosmicDeep,
      level: 1,
      child: [
        {
          id: "leftUp-child-1",
          group: GraphGroup.PINK_GROUP,
          label: "Platform",
          color: GraphColor.frenchRose,
          level: 2,
          child: [
            {
              id: "leftUp-child-child-1",
              group: GraphGroup.PINK_GROUP,
              label: "Services",
              color: GraphColor.froly,
              level: 3,
              child: [
                {
                  id: "leftUp-child-child-child-1",
                  group: GraphGroup.PINK_GROUP,
                  label: "Data",
                  color: GraphColor.saffronMango,
                  textColor: GraphColor.black,
                  level: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "leftUp-2",
      group: GraphGroup.PINK_GROUP,
      label: "Partners",
      color: GraphColor.cosmicDeep,
      level: 1,
      child: [
        {
          id: "leftUp-2-child-1",
          group: GraphGroup.PINK_GROUP,
          label: "Platform",
          color: GraphColor.frenchRose,
          level: 2,
          child: [
            {
              id: "leftUp-2-child-child-1",
              group: GraphGroup.PINK_GROUP,
              label: "Services",
              color: GraphColor.froly,
              level: 3,
              child: [
                {
                  id: "leftUp-2-child-child-child-1",
                  group: GraphGroup.PINK_GROUP,
                  label: "Data",
                  color: GraphColor.saffronMango,
                  textColor: GraphColor.black,
                  level: 4,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "leftUp-3",
      group: GraphGroup.PINK_GROUP,
      label: "Partners",
      color: GraphColor.cosmicDeep,
      level: 1,
      child: [
        {
          id: "leftUp-3-child-1",
          group: GraphGroup.PINK_GROUP,
          label: "Platform",
          color: GraphColor.frenchRose,
          level: 2,
          child: [
            {
              id: "leftUp-3-child-child-1",
              group: GraphGroup.PINK_GROUP,
              label: "Services",
              color: GraphColor.froly,
              level: 3,
              child: [
                {
                  id: "leftUp-3-child-child-child-1",
                  group: GraphGroup.PINK_GROUP,
                  label: "Data",
                  color: GraphColor.saffronMango,
                  textColor: GraphColor.black,
                  level: 4,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
