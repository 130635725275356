/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react";
import { Dialog as ReachDialog } from "@reach/dialog";
import { FaSpinner } from "react-icons/fa";

import * as colors from "styles/colors";
import * as mq from "styles/media-queries";

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});
const FieldsWrapper = styled.div({
  marginBottom: "35px",
});

const LoadingButton = styled.button({
  minHeight: "38px",
  minWidth: "78px",
  padding: "0",
  textAlign: "center",
});

const Loader = styled.div(props => ({
  display: "inline-block",
  width: props.width ?? "1.5rem",
  height: props.height ?? "1.5rem",
  verticalAlign: "-0.4em",
  border: "0.2em solid currentColor",
  borderRightColor: "transparent",
  borderRadius: "50%",
  animation: "0.75s linear infinite spinner-border",
}));

const CircleButton = styled.button({
  borderRadius: "30px",
  padding: "0",
  width: "40px",
  height: "40px",
  lineHeight: "1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: colors.base,
  color: colors.text,
  border: `1px solid ${colors.gray10}`,
  cursor: "pointer",
});

const Spinner = styled(FaSpinner)({
  animation: `${spin} 1s linear infinite`,
});
Spinner.defaultProps = {
  "aria-label": "loading",
};

const buttonVariants = {
  primary: {
    background: colors.indigo,
    color: colors.base,
  },
  secondary: {
    background: colors.gray,
    color: colors.text,
  },
};
const Button = styled.button(
  {
    padding: "10px 15px",
    border: "0",
    lineHeight: "1",
    borderRadius: "3px",
  },
  ({ variant = "primary" }) => buttonVariants[variant],
);

const inputStyles = {
  border: "1px solid #f1f1f4",
  background: "#f1f2f7",
  padding: "8px 12px",
};

const Input = styled.input({ borderRadius: "3px" }, inputStyles);

const Dialog = styled(ReachDialog)({
  maxWidth: "450px",
  borderRadius: "3px",
  paddingBottom: "3.5em",
  boxShadow: "0 10px 30px -5px rgba(0, 0, 0, 0.2)",
  margin: "20vh auto",
  [mq.small]: {
    width: "100%",
    margin: "10vh auto",
  },
});

const FormGroup = styled.div({
  display: "flex",
  flexDirection: "column",
});

function FullPageSpinner() {
  return (
    <div
      css={{
        fontSize: "4em",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner />
    </div>
  );
}

export {
  CircleButton,
  Spinner,
  Button,
  Input,
  Dialog,
  FormGroup,
  FullPageSpinner,
  LoadingButton,
  Loader,
  FieldsWrapper,
};
