import React, { useEffect, useRef } from "react";
import { Network } from "vis-network/standalone/esm/vis-network";
import { options } from "./GraphOptions.js";
import { ArcCircle, Circle, CircleContainer, Container, GraphWrapper } from "./VisNetwork.styles.js";
import { getGraphData } from "./VisNetworkPositionGenerator.js";
import PropTypes from "prop-types";
import { useMeasure } from "react-use";
import { isFirefox } from "react-device-detect";

const VisNetwork = ({ jsonData, setIsShowGraph }) => {
  const domNode = useRef(null);
  const network = useRef(null);

  const [circleInnerRef, { width, height }] = useMeasure();

  const graphData = getGraphData(jsonData);
  const nodes = graphData.node;
  const edges = graphData.edge;
  const data = {
    nodes,
    edges,
  };

  useEffect(() => {
    network.current = new Network(domNode.current, data, options);
  }, [domNode, network, data, options]);

  useEffect(() => {
    if (!network.current) return;

    network.current.moveTo({
      position: { x: 0, y: 0 },
    });

    // disable select node
    network.current.on("selectNode", opts => {
      network.current.setSelection({
        nodes: "",
        edges: opts.edges,
      });
    });
  }, [network.current]);

  return (
    <>
      <GraphWrapper className="px-4 d-flex justify-content-center align-items-center position-relative">
        <div className="position-absolute" style={{ zIndex: 9999 }}>
          <ArcCircle text={jsonData?.titleTop ?? ""} width={width} characterWidth={1.6} textColor="#0c3069">
            <ArcCircle
              text={jsonData?.titleBottom ?? ""}
              width={width}
              characterWidth={1.6}
              upsideDown
              textColor="#f35877"
            />
          </ArcCircle>
        </div>

        <CircleContainer>
          <Circle>
            <div className="circle__inner" ref={circleInnerRef}>
              <div className="circle__wrapper">
                <div className="circle__content position-relative">
                  <Container
                    ref={domNode}
                    className="position-absolute"
                    style={{
                      width: isFirefox ? width : "100%",
                      height: isFirefox ? height : "100%",
                      top: 0,
                    }}
                  />
                </div>
              </div>
            </div>
          </Circle>
        </CircleContainer>
      </GraphWrapper>
      <div className="text-center pb-2">
        <button className="btn-validate" onClick={() => setIsShowGraph(false)}>
          Another
        </button>
      </div>
    </>
  );
};

VisNetwork.propTypes = {
  jsonData: PropTypes.object.isRequired,
  setIsShowGraph: PropTypes.func.isRequired,
};

export default VisNetwork;
