import { GraphColor } from "constants/general";

export const options = {
  autoResize: true,
  height: "100%",
  width: "100%",
  physics: false,
  nodes: {
    borderWidth: 0,
    borderWidthSelected: 0,
    brokenImage: undefined,
    chosen: true,
    color: {
      border: GraphColor.none,
      background: GraphColor.onahau,
      highlight: {
        border: GraphColor.royalBlue,
        background: GraphColor.onahau,
      },
      hover: {
        border: GraphColor.royalBlue,
        background: GraphColor.onahau,
      },
    },
    font: {
      color: GraphColor.white,
      size: 12, // px
    },
    opacity: 1,
    fixed: {
      x: true,
      y: true,
    },

    mass: 1,
    physics: true,
    scaling: {
      min: 55,
      max: 55,
      label: {
        enabled: false,
        min: 14,
        max: 100,
        maxVisible: 100,
        drawThreshold: 5,
      },
      customScalingFunction: function (min, max, total, value) {
        if (max === min) {
          return 0.5;
        } else {
          let scale = 1 / (max - min);
          return Math.max(0, (value - min) * scale);
        }
      },
    },
    shape: "circle",
    shapeProperties: {
      borderDashes: false,
      borderRadius: 1,
      interpolation: false,
      useImageSize: false,
      useBorderWithImage: false,
      coordinateOrigin: "center",
    },
    size: 40,
    title: undefined,
    value: undefined,
    widthConstraint: 64,
  },
  edges: {
    arrowStrikethrough: true,
    chosen: true,
    color: {
      color: GraphColor.gullGray,
      highlight: GraphColor.gullGray,
      hover: GraphColor.gullGray,
      inherit: "from",
      opacity: 1.0,
    },
    width: 5,
    selectionWidth: 1,
    hoverWidth: 1.5,
    labelHighlightBold: true,
    physics: true,
    scaling: {
      min: 1,
      max: 100,
      label: {
        enabled: false,
        min: 14,
        max: 100,
        maxVisible: 100,
        drawThreshold: 5,
      },
      customScalingFunction: function (min, max, total, value) {
        if (max === min) {
          return 0.5;
        } else {
          var scale = 1 / (max - min);
          return Math.max(0, (value - min) * scale);
        }
      },
    },
    smooth: {
      enabled: true,
      type: "continuous",
      roundness: 0.1,
    },
    title: undefined,
    value: undefined,
    widthConstraint: false,
  },
  interaction: {
    dragNodes: false,
    dragView: false,
    zoomView: false,
    navigationButtons: false,
  },
};
