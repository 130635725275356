import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const WrapperLI = styled.li`
  display: flex;
  box-sizing: border-box;
  transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1))
    scaleY(var(--scale-y, 1));
  transform-origin: 0 0;
  touch-action: manipulation;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${props =>
    props.fadeIn &&
    css`
      animation: fadeIn 500ms ease;
    `}

  ${props =>
    props.dragOverlay &&
    css`
      --scale: 1.01;
      z-index: 999;
    `}
`;

export const StyledItem = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: calc(4px / var(--scale-x, 1));
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;

  -webkit-tap-highlight-color: transparent;

  font-weight: 400;
  font-size: 1rem;
  white-space: nowrap;

  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  &:focus-visible {
    box-shadow: 0 0px 4px 1px #4c9ffe, 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
      0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
  }

  &:not(.withHandle) {
    touch-action: manipulation;
    cursor: grab;
  }

  &.dragging:not(.dragOverlay) {
    opacity: var(--dragging-opacity, 0.2);
    z-index: 0;

    &:focus {
      box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
        0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
    }
  }

  ${props =>
    props.disabled &&
    css`
      color: #999;
      background-color: #f1f1f1;
      &:focus {
        box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1), 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
          0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
      }
      cursor: not-allowed;
    `}

  ${props =>
    props.dragOverlay &&
    css`
      cursor: inherit;
      /* box-shadow: 0 0px 6px 2px $focused-outline-color; */
      animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
      transform: scale(var(--scale));
      box-shadow: var(--box-shadow-picked-up);
      opacity: 1;
    `}

  ${props =>
    props.color &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 100%;
        width: 3px;
        display: block;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background-color: var(--color);
      }
    `}
`;
