import React, { useEffect, useState } from "react";
import { useIsShowFeaturedAppsBar } from "context/IsShowFeaturedAppsBarContext";
import { ConeGraph, ConeGraphContentContainer } from "components/coneGraph";

const ConeGraphPage = () => {
  const [isShowGraph, setIsShowGraph] = useState(false);
  const [graphData, setGraphData] = useState(null);

  const { dispatch: dispatchIsShowFeaturedAppsBar, Action } = useIsShowFeaturedAppsBar();

  useEffect(() => {
    dispatchIsShowFeaturedAppsBar({ type: Action.HideAppsBar });

    return () => {
      dispatchIsShowFeaturedAppsBar({ type: Action.ShowAppsBar });
    };
  }, []);

  return isShowGraph ? (
    <>
      <ConeGraph jsonData={graphData} setIsShowGraph={setIsShowGraph} />
    </>
  ) : (
    <ConeGraphContentContainer setIsShowGraph={setIsShowGraph} setGraphData={setGraphData} />
  );
};

export default ConeGraphPage;
