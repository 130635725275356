export function separateArray(arr, size) {
  var newArr = [];
  for (var i = 0; i < arr.length; i += size) {
    var sliceIt = arr.slice(i, i + size);
    newArr.push(sliceIt);
  }
  return newArr;
}

/**
 * * move extra items in the next array
 */
const moveExtraItemsInTheNextArray = (item, index, newItemsArr, count) => {
  let arr = [];

  if (item.length > count) {
    arr = item.splice(count, item.length);
  }

  if (arr.length) {
    if (!newItemsArr[index + 1]) {
      newItemsArr.push(arr);
      return;
    }
    newItemsArr[index + 1] = [...arr, ...newItemsArr[index + 1]];
  }
};

/**
 * * slice items from previous array And fill current array
 */
const sliceItemsFromPreviousArrayAndFillCurrentArray = (item, index, newItemsArr, count) => {
  let arr = [];

  const totalArrayLength = newItemsArr.length;

  if (totalArrayLength === index + 1) return;

  if (item.length < count) {
    const newCount = count - item.length;
    arr = newItemsArr[index + 1]?.splice(0, newCount);
  }

  if (arr.length) {
    newItemsArr[index] = [...newItemsArr[index], ...arr];
  }
};

/**
 * * fill box empty space
 */

export const fillBoxEmptySpace = (newItemsArr, count) => {
  /**
   * * move extra items in the next array
   */
  newItemsArr.forEach((item, index) => {
    moveExtraItemsInTheNextArray(item, index, newItemsArr, count);
  });
  /**
   * * slice items from previous array And fill current array
   */
  newItemsArr.forEach((item, index) => {
    sliceItemsFromPreviousArrayAndFillCurrentArray(item, index, newItemsArr, count);
  });

  /**
   * * remove empty items array
   */
  newItemsArr.forEach((item, index) => {
    if (!item.length) {
      newItemsArr.splice(index, 1);
    }
  });
};
