import React, { useState } from "react";
import Select from "react-select";
import { PropTypes } from "prop-types";
import { AddressSearchDate } from "constants/general";

const customStyles = {
  menu: provided => ({
    ...provided,
    backgroundColor: "var(--tapestry)",
  }),
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      width: "100%",
      padding: "0.375rem 0 0.375rem 0.375rem",
      fontWeight: 400,
      lineHeight: 1.5,
      backgroundClip: "padding-box",
      appearance: "none",
      borderRadius: "0.25rem",
      transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
      backgroundColor: state.isDisabled ? "hsl(336, 35%, 45%)" : "var(--tapestry)",
      border: "none !important",
      outline: "none !important",
      color: "#fff !important",
      height: "2.9rem",
      fontSize: "1rem",
      fontFamily: "GreycliffCf-Medium",
      boxShadow: state.isFocused ? "0 0 0 0.25rem rgb(13 110 253 / 25%)" : "none",
      cursor: state.isDisabled && "not-allowed",

      "& svg": {
        transform: state.isFocused ? "rotate(180deg)" : "rotate(0deg)",
        fill: "#fff",
      },
    };
  },
  input: baseStyles => ({
    ...baseStyles,
    color: "#fff !important",
  }),
  indicatorsContainer: (baseStyles, state) => {
    return {
      ...baseStyles,
      color: "#fff !important",
      "& > span": { display: "none" },
    };
  },
  placeholder: baseStyles => ({
    ...baseStyles,
    color: "#fff !important",
  }),
  singleValue: baseStyles => ({
    ...baseStyles,
    color: "#fff !important",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#fff",
    backgroundColor: state.isSelected ? "#7c3a74" : "inherit",
    "&:hover": { backgroundColor: "rgba(124, 58, 116, 0.5)" },
  }),
};

const options = AddressSearchDate.map(item => ({ value: item, label: item }));

// eslint-disable-next-line react/prop-types
const SearchDate = ({ selectedValue, setSelectedValue, dateRef, ...rest }) => {
  const [apiResponseData, setApiResponseData] = useState(null);
  const [_inputValue, _setInputValue] = useState("");

  const handleInputChange = _selectedOption => {
    setSelectedValue(_selectedOption);
  };

  return (
    <div className="mt-3 ms-2" style={{ minWidth: "150px" }}>
      <Select
        ref={dateRef}
        styles={customStyles}
        components={{ ClearIndicator: () => null }}
        options={options}
        value={selectedValue}
        onChange={handleInputChange}
        placeholder="Date"
        {...rest}
      />
    </div>
  );
};

SearchDate.propTypes = {
  selectedValue: PropTypes.object,
  setSelectedValue: PropTypes.func.isRequired,
};

SearchDate.defaultProps = {
  selectedValue: null,
};

export default SearchDate;
