export const config = {
  analyseName: {
    apiUrl: "https://wi1fjgbq18.execute-api.eu-west-2.amazonaws.com/analyseName",
  },
  validateDln: {
    apiUrl: "https://sandbox.validation-services.percayso-inform.com/dln",
  },
  validateName: {
    apiUrl: "https://sandbox.validation-services.percayso-inform.com/name",
  },
  validateCompany: {
    apiUrl: `${process.env.REACT_APP_BOT_BASE_API_URL}/api/validate-company`,
  },
  validateAddress: {
    apiUrl: "https://sandbox.validation-services.percayso-inform.com/address",
  },
  validateTel: {
    apiUrl: "https://sandbox.validation-services.percayso-inform.com/phone",
  },
  validateEmail: {
    apiUrl: "https://sandbox.validation-services.percayso-inform.com/email",
  },
  matchName: {
    apiUrl: "https://sandbox.validation-services.percayso-inform.com/match-name",
  },
  businessDetailsScrap: {
    apiUrl: "http://localhost:4000/api/business-details/fetch",
  },
  getCarQuotes: {
    apiUrl: `${process.env.REACT_APP_BOT_BASE_API_URL}/api/get-car-quotes`,
  },
  getBikeQuotes: {
    apiUrl: `${process.env.REACT_APP_BOT_BASE_API_URL}/api/get-bike-quotes`,
  },
  getVanQuotes: {
    apiUrl: `${process.env.REACT_APP_BOT_BASE_API_URL}/api/get-van-quotes`,
  },
  getHouseQuotes: {
    apiUrl: `${process.env.REACT_APP_BOT_BASE_API_URL}/api/get-house-quotes`,
  },
  getTravelQuotes: {
    apiUrl: `${process.env.REACT_APP_BOT_BASE_API_URL}/api/get-travel-quotes`,
  },
  getPetQuotes: {
    apiUrl: `${process.env.REACT_APP_BOT_BASE_API_URL}/api/get-pet-quotes`,
  },
  leads: {
    apiUrl: "http://localhost:4000/api/deals",
  },
  companies: {
    apiUrl: "http://localhost:4000/api/companies",
  },
  contacts: {
    apiUrl: "http://localhost:4000/api/contacts",
  },
  pipeline: {
    apiUrl: "http://localhost:4000/api/pipeline",
  },
  products: {
    apiUrl: "http://localhost:4000/api/products",
  },
  percayso: {
    apiUrl: "http://localhost:4000/api/percayso",
  },
  goCompareCarInsuranceQuotes: {
    apiUrl: "http://localhost:4000/api/go-compare/car",
  },
  confusedCarInsuranceQuotes: {
    apiUrl: "http://localhost:4000/api/confused/car",
  },
  carInsurancesQuotes: {
    apiUrl: "http://localhost:4000/api/insurance/car",
  },
};

export const QUOTE_TYPES = {
  CAR_QUOTES: "carQuotes",
  VAN_QUOTES: "vanQuotes",
  BIKE_QUOTES: "bikeQuotes",
  HOUSE_QUOTES: "houseQuotes",
  PET_QUOTES: "petQuotes",
  TRAVEL_QUOTES: "travelQuotes",
};

export const API_URL_BY_QUOTE_TYPES = {
  CAR_QUOTES: config.getCarQuotes.apiUrl,
  VAN_QUOTES: config.getVanQuotes.apiUrl,
  BIKE_QUOTES: config.getBikeQuotes.apiUrl,
  HOUSE_QUOTES: config.getHouseQuotes.apiUrl,
  PET_QUOTES: config.getPetQuotes.apiUrl,
  TRAVEL_QUOTES: config.getTravelQuotes.apiUrl,
};
