import React, { createContext, useCallback, useEffect, useState } from "react";
import { PercaysoGraphContainer } from "components/percayso";
import { config } from "config";
import { client } from "utils/apiClient";
import "styles/companies.css";
import { useFirstRender } from "hooks/useFirstRender";

import { MEDIA_QUERY } from "constants/general";
import { useMediaQuery } from "react-responsive";
import { percaysoBoxOneIconsData } from "data/iconsData";
import SliderBox from "components/quote/SliderBox";
import { usePercaysoDataTable } from "hooks/usePercaysoDataTable";
import FullViewLoader from "components/FullViewLoader";
import { useFolderModal } from "context/FolderModalContext";
import FolderContent from "components/appIcon/FolderContent";
import WebView from "components/percayso/WebView";

export const PercaysoContext = createContext(null);

const Percayso = () => {
  const pageTitle = "Percayso";

  const isBigScreen = useMediaQuery({
    query: MEDIA_QUERY.BIG_SCREEN,
  });

  const {
    setIsShowSearch,
    selectedOption,
    setSelectedOption,
    searchInputValue,
    setDefaultOptions,
    companyGraphData,
    setCompanyGraphData,
    loading,
    setLoading,
    isShowCompanyGraph,
    setIsShowCompanyGraph,
    isShowGraph,
    setIsShowGraph,
    setIsShowSearchIcon,
    setApiResponse,
    setSearchInputValue,
  } = usePercaysoDataTable();

  const [graphLoading, setGraphLoading] = useState(false);

  const isFirstRender = useFirstRender();
  const { isFolderModalShow, setIsFolderModalShow, dispatchFolderContent } = useFolderModal();

  const fetchGraphData = useCallback(
    async (id, type) => {
      setGraphLoading(true);
      try {
        const {
          percayso: { apiUrl: API_URL },
        } = config;
        const data = await client(`${API_URL}/get-crm-data`, {
          data: {
            objectId: id,
            objectType: type,
          },
        });
        setCompanyGraphData(data);
        setGraphLoading(false);
      } catch (err) {
        setGraphLoading(false);
      }
    },
    [selectedOption],
  );

  const fillESdbIfEmpty = async () => {
    setLoading(true);
    try {
      const {
        percayso: { apiUrl: API_URL },
      } = config;
      await client(`${API_URL}/fill`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isFirstRender) fillESdbIfEmpty();

    return () => {
      setSelectedOption(null);
      setIsShowGraph(false);
      setApiResponse([]);
      setSearchInputValue("");
    };
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setIsShowSearch(false);
      setIsShowGraph(true);
    }

    if (!selectedOption) {
      setIsShowCompanyGraph(false);
    }

    // fetch graph data
    if (
      (selectedOption && selectedOption?.value.categoryName === "companies") ||
      selectedOption?.value.categoryName === "deals" ||
      selectedOption?.value.categoryName === "contacts"
    ) {
      fetchGraphData(selectedOption?.value.id, selectedOption?.value.categoryName);
      setIsShowCompanyGraph(true);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (!searchInputValue) {
      setApiResponse([]);
      setDefaultOptions([]);
    }
  }, [searchInputValue]);

  useEffect(() => {
    setIsShowSearchIcon(true);

    return () => {
      setIsShowSearchIcon(false);
    };
  }, []);

  useEffect(() => {
    return () => {
      setIsFolderModalShow(false);
      dispatchFolderContent({ type: "REMOVE_MODAL_ITEMS" });
    };
  }, []);

  if (loading) {
    return <FullViewLoader />;
  }

  return (
    <>
      {isFolderModalShow ? <WebView isShow={false} url={process.env.REACT_APP_KIBANA_DASHBOARD_URL} /> : null}
      {graphLoading ? (
        <>
          <FullViewLoader />
        </>
      ) : (
        <div>
          {isShowGraph ? (
            <PercaysoGraphContainer
              graphDataObj={isShowCompanyGraph ? companyGraphData : selectedOption}
              setIsShowGraph={setIsShowGraph}
              showCompanyGraph={isShowCompanyGraph}
            />
          ) : (
            <div
              className={`w-100 h-100 ${
                isBigScreen ? "" : "min-vh-100"
              } d-flex justify-content-center align-items-center`}
            >
              <div className="mx-auto w-100">
                <div className={`d-flex ${isBigScreen ? "flex-row" : "flex-column"} flex-wrap justify-content-center`}>
                  {isFolderModalShow ? (
                    <FolderContent />
                  ) : (
                    <SliderBox
                      pageTitle={pageTitle}
                      icons={percaysoBoxOneIconsData}
                      boxTitle="Reports"
                      classes="my-2"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Percayso;
