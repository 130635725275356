export const JSON_DATA = {
  client: "Somerset Bridge",
  GoSkippy: [{ openGl: "Open Gl", Compare_the_market: {} }],
  Risk: [
    {
      Vehicle: "12076001",
      body: "03",
      cc: 1560,
      doors: 5,
      estimatedValue: 2190,
      fuel: "001",
      gearbox: "002",
      imported: false,
      keeper: "1",
      make: "",
      model: "",
      objectId: "1",
      owner: "1",
      purchaseDate: "2014-07-01",
      qPlate: false,
      registeredDate: "2011-01-01",
      rightHandDrive: true,
      seats: 5,
      trackerType: "",
      vrn: "SP11UFE",
      vrnValid: "",
      year: 2011,
    },
    {
      Cover: "Cover",
      accessoriesValue: 0,
      annualMileage: 3000,
      businessMileage: 0,
      coverPeriod: 12,
      coverPeriodUnits: "2",
      coverStartDate: "2022-02-06",
      coverTypeId: "02",
      dayTimeParking: "",
      drivingRestrictionId: "1",
      hasWindscreenCover: false,
      overnightParkingId: "5",
      pleasureMileage: 3000,
      requiredDrivers: "1",
      voluntaryExcess: 0,
      voluntaryExcessAmount: 0,
      // vehicleUses: [
      //   {
      //     useCode: "04",
      //     drivers: ["1"],
      //   },
      // ],
      // previousInsurance: {
      //   previousExpiryDate: "2022-02-05",
      //   previousInsurerId: "999",
      //   previousPaymentRegularity: "01",
      // },
      // ncdDetails: {
      //   years: 20,
      //   entitlementReasonId: "11",
      //   countryEarnedId: "GB",
      //   protected: true,
      // },
      // otherNcdDetails: [],
      // garageAddress: {
      //   postcode: "TS7 8TA",
      // },
      // accessories: [],
      // modifications: [],
      // securityDevices: [
      //   {
      //     typeId: "93",

      //     fittedbyId: "4",
      //   },
      // ],
      // experienceDetails: [],
    },
  ],
  Subjects: [
    {
      ANNE_OTHER: "ANNE OTHER",
      "a.other.hotmail.com": {},
      "01642312845": {},
      OTHER01642312845: {},
    },
    {
      John_Smith: "John Smith",
      "31_Oakwood_Court_TS7_8TA": {},
      "j.smith@btinternet.com": {},
      "01642123456": {},
      SMITH01642123456: {},
    },
  ],
  Percayso_Inform: [{ deploy: "deploy" }, { inform: "inform" }, { enrich: "enrich" }],
};
