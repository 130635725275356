import React, { useEffect, useState } from "react";
import { Meta } from "components/layout";
import { InputForm } from "components/forms";
import { FieldsWrapper, Loader, LoadingButton } from "components/lib";
import "../styles/validateAddress.css";
import { socket } from "config/socket";

const ScrapeBusinessDetailsThomson = () => {
  const [message, setMessage] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [loading, setLoading] = useState(false);
  const [businessNameValidationMessage, setBusinessNameValidationMessage] = useState("");
  const pageTitle = "Scrape Business Details";
  const handleSubmit = async e => {
    e.preventDefault();
    setMessage("");
    setBusinessNameValidationMessage("");

    if (!businessName) {
      setBusinessNameValidationMessage("Business name field required!");
      return;
    }

    setLoading(true);

    try {
      socket.emit("scrape_request_submit", {
        businessName,
      });
    } catch (error) {
      setBusinessNameValidationMessage("Business details scrape request submit is failed.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!businessName) {
      socket.on("scrape_request_received", args => {
        setLoading(args.loading);
        setMessage(args.message);
      });

      socket.on("scraped_data_saving_in_db", args => {
        setMessage(args.message);
      });

      socket.on("scrape_complete", args => {
        setLoading(args.loading);
        setMessage(args.message);
      });

      socket.on("scrape_error", args => {
        setLoading(args.loading);
        setMessage(args.message);
      });

      return () => {
        socket.off("scrape_request_received");
        socket.off("scraped_data_saving_in_db");
        socket.off("scrape_complete");
        socket.off("scrape_error");
      };
    }
  }, []);

  return (
    <div className="address-form-wrapper">
      <Meta title={pageTitle} />
      <div>
        <form onSubmit={handleSubmit} className="mt-3">
          <FieldsWrapper>
            <InputForm
              type={"text"}
              value={businessName}
              handleChange={event => setBusinessName(event.target.value)}
              placeholder={"Business name"}
              autoFocus
            />
          </FieldsWrapper>
          <div className="btn-container">
            <LoadingButton type="submit" className="btn-validate mt-1" disabled={loading}>
              {loading ? <Loader /> : "Submit"}
            </LoadingButton>
          </div>
        </form>
        <div className="name-validate-error">{businessNameValidationMessage}</div>
        <div className="name-validate-error">{message}</div>
      </div>
    </div>
  );
};

export default ScrapeBusinessDetailsThomson;
