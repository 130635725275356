import React from "react";
import { usePercaysoDataTable } from "hooks/usePercaysoDataTable";
import PropTypes from "prop-types";
import "./SearchItem.style.scss";
import classNames from "classnames";

const SearchItem = ({ option, setIsPopoverOpen }) => {
  const { setSelectedOption, selectedOption } = usePercaysoDataTable();
  const isSelected = selectedOption && selectedOption.value?.id === option?.value.id;
  return (
    <p
      onClick={() => {
        setSelectedOption(option);
        setIsPopoverOpen(false);
      }}
      className={classNames("item", { selected: isSelected })}
    >
      {option.label}
    </p>
  );
};

SearchItem.propTypes = {
  option: PropTypes.object.isRequired,
  setIsPopoverOpen: PropTypes.func.isRequired,
};

export default SearchItem;
