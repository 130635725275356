import React, { useState } from "react";
import { Meta } from "components/layout";
import { InputForm } from "components/forms";
import { FieldsWrapper, Loader, LoadingButton } from "components/lib";
import "../../styles/validateAddress.css";
import { config } from "../../config";
import { client } from "utils/apiClient";
import { TextArea } from "../../components/graphUI/GraphContentContainer.styles";
import Select from "react-select";

const options = [
  {
    value: "direct_line",
    label: "Direct Line",
  },
  {
    value: "quote_zone",
    label: "Quote Zone",
  },
  {
    value: "money_supermarket",
    label: "Money SuperMarket",
  },
  {
    value: "confused",
    label: "Confused",
  },
];

const GetCarQuotesPage = () => {
  const [apiResponse, setApiResponse] = useState(null);
  const [source, setSource] = useState(options[0]);
  const [registrationNumber, setRegistrationNumber] = useState("FG62OOX");
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const pageTitle = "Get Car Quotes";

  const handleSubmit = async e => {
    e.preventDefault();
    setValidationMessage("");

    if (!registrationNumber) {
      setValidationMessage("Registration Number required!");
      return;
    }

    setLoading(true);

    const {
      getCarQuotes: { apiUrl: API_URL },
    } = config;

    try {
      const apiResponse = await client(API_URL, {
        query: {
          q: registrationNumber,
          source: source.value,
        },
      });

      setApiResponse(apiResponse);
    } catch (e) {
      setValidationMessage("Getting car quotes from registration number failed");
    }

    setLoading(false);
  };
  return (
    <div className="address-form-wrapper">
      <Meta title={pageTitle} />
      <div>
        <form onSubmit={handleSubmit} className="mt-3">
          <FieldsWrapper>
            <InputForm
              type={"text"}
              value={registrationNumber}
              handleChange={event => setRegistrationNumber(event.target.value)}
              placeholder={"Registration Number"}
              autoFocus
            />
            <div style={{ marginTop: "10px" }}>
              <Select
                options={options}
                placeholder="Select Category"
                onChange={e => {
                  setSource(e);
                }}
                value={source}
              />
            </div>
          </FieldsWrapper>

          <div className="btn-container">
            <LoadingButton type="submit" className="btn-validate mt-1">
              {loading ? <Loader /> : "Submit"}
            </LoadingButton>
          </div>
        </form>
        <div className="name-validate-error">{validationMessage}</div>
        {apiResponse && (
          <TextArea
            className="w-100 h-100 rounded-3 p-2 form-control"
            style={{ minHeight: "300px" }}
            placeholder="Data"
            value={JSON.stringify(apiResponse, undefined, 3)}
            // onChange={event => setTextAreaValue(event.target.value)}
          />
        )}
      </div>
    </div>
  );
};

export default GetCarQuotesPage;
