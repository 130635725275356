import React from "react";
import { Navigation, Pagination } from "swiper";
import { Swiper } from "swiper/react";
import PropTypes from "prop-types";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERY } from "constants/general";

const SwiperWrapper = ({ children, isSwiperDraggableDisabled }) => {
  const isBigScreen = useMediaQuery({
    query: MEDIA_QUERY.BIG_SCREEN,
  });
  return (
    <div className="w-100" style={{ position: isBigScreen ? "relative" : "initial" }}>
      <Swiper
        modules={[Pagination, Navigation]}
        navigation={{
          nextEl: ".button-next",
          prevEl: ".button-prev",
        }}
        pagination={{
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        }}
        spaceBetween={50}
        slidesPerView={1}
        threshold={20}
        touchStartPreventDefault={false}
        allowSlidePrev={!isSwiperDraggableDisabled}
        allowSlideNext={!isSwiperDraggableDisabled}
      >
        {children}
      </Swiper>
      <div className="swiper-pagination"></div>
    </div>
  );
};

SwiperWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isSwiperDraggableDisabled: PropTypes.bool.isRequired,
};

export default SwiperWrapper;
