import React, { useState } from "react";
import { Meta } from "components/layout";
import { InputForm } from "components/forms";
import { FieldsWrapper, Loader, LoadingButton } from "components/lib";
import "../styles/validateAddress.css";
import { config } from "../config";
import { client } from "utils/apiClient";
import ValidateEmailGraph from "components/emailGraph";
import { validateEmailAddress } from "utils/validateEmailAddress";

const ValidateEmail = () => {
  const [apiResponse, setApiResponse] = useState("");
  const [nextPage, setNextPage] = useState(0);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState("");
  const pageTitle = "Validate email";
  const handleSubmit = async e => {
    e.preventDefault();
    setEmailValidationMessage("");

    if (!email) {
      setEmailValidationMessage("Email field required!");
      return;
    }

    if (!validateEmailAddress(email)) {
      setEmailValidationMessage("Please enter a valid email address.");
      return;
    }

    setLoading(true);

    try {
      const {
        validateEmail: { apiUrl: API_URL },
      } = config;

      const apiResponse = await client(API_URL, {
        data: {
          email,
        },
      });

      if (apiResponse.status === "VALID") {
        setApiResponse(apiResponse);
        setNextPage(1);
      } else {
        setEmailValidationMessage(`Email verification failed. ${apiResponse?.message}`);
      }
    } catch (error) {
      setEmailValidationMessage(error.message || "Error While Fetching");
    } finally {
      setLoading(false);
    }
  };
  const handleAnother = () => {
    setEmail("");
    setNextPage(0);
  };
  return (
    <div className="address-form-wrapper">
      <Meta title={pageTitle} />
      {nextPage === 0 ? (
        <div>
          <form onSubmit={handleSubmit} className="mt-3">
            <FieldsWrapper>
              <InputForm
                type={"text"}
                value={email}
                handleChange={event => setEmail(event.target.value)}
                placeholder={"Enter your email address"}
                autoFocus
              />
            </FieldsWrapper>
            <div className="btn-container">
              <LoadingButton type="submit" className="btn-validate mt-1">
                {loading ? <Loader /> : "Submit"}
              </LoadingButton>
            </div>
          </form>
          <div className="name-validate-error">{emailValidationMessage}</div>
        </div>
      ) : (
        <ValidateEmailGraph apiResponse={apiResponse} email={email} />
      )}
    </div>
  );
};

export default ValidateEmail;
