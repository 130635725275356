import React from "react";
import "./ChartContainer.style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ChartContainer = ({ children, to, width, height, title }) => {
  return (
    <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
      <div className="chart-container bg-white rounded">
        <Link to={to} className="close-btn">
          <FontAwesomeIcon icon={faXmark} />
        </Link>
        <p className="title">{title}</p>
        <div className="chart-box d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center" style={{ width, height }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

ChartContainer.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
};

ChartContainer.defaultProps = {
  to: "/percayso",
  width: "100%",
  height: "100%",
  title: "",
};

export default ChartContainer;
