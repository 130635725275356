import { useState } from "react";
import { BrandsSearchInput, CarQuote } from "components/searchBrands";

const SearchBrands = () => {
  const [quotes, setQuotes] = useState([]);

  return (
    <div className="">
      <div className="container">
        <BrandsSearchInput setQuotes={setQuotes} />
        <div className="mt-3 container">
          {quotes.map((quote, index) => (
            <CarQuote key={index} data={quote} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchBrands;
