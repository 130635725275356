/* eslint-disable react/prop-types */
import React, { useState } from "react";
import CloseBtn from "./CloseBtn";
import "./Modal.style.scss";

const Modal = ({ title, showModal, handleModalClose, children, variant = "back" }) => {
  return (
    <>
      {showModal ? (
        <div className="back_drop">
          <div className="card_modal">
            <div className="card_modal_scroll_area">
              <div
                className="d-inline-flex align-items-center justify-content-start cursor_pointer"
                onClick={handleModalClose}
              >
                <CloseBtn onClose={handleModalClose} variant={variant} />
                <h4 className="card_modal_title">{title}</h4>
              </div>
              <div className="card_modal_content">{children}</div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
