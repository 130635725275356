import React, { useContext, useEffect, useState, useReducer } from "react";
import PropTypes from "prop-types";
import { folderModalReducer, initialFolderModalState } from "reducer/FolderModalReducer";

export const FolderModalContext = React.createContext(undefined);

FolderModalContext.displayName = "FolderModalContext";

const FolderModalProvider = props => {
  const [isFolderModalShow, setIsFolderModalShow] = useState(false);
  const [folderName, setFolderName] = useState("Folder name");
  const [folderContentArr, dispatchFolderContent] = useReducer(folderModalReducer, initialFolderModalState);

  return (
    <FolderModalContext.Provider
      value={{
        isFolderModalShow,
        setIsFolderModalShow,
        folderContentArr: folderContentArr.items,
        dispatchFolderContent,
        folderName,
        setFolderName,
      }}
      {...props}
    >
      {props.children}
    </FolderModalContext.Provider>
  );
};

FolderModalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default FolderModalProvider;

export function useFolderModal() {
  const context = useContext(FolderModalContext);
  if (context === undefined) {
    throw new Error(`useFolderModal must be used within a FolderModalProvider`);
  }
  return context;
}
