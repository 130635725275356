import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { GraphContentContainer } from "components/graphUI";
import CompanyGraph from "./CompanyGraph";

const CompanyGraphContainer = ({ graphDataObj, setIsShowCompanyGraph }) => {
  const [isShowGraph, setIsShowGraph] = useState(false);
  const [graphData, setGraphData] = useState(null);
  return isShowGraph ? (
    <CompanyGraph graphData={graphData} setIsShowGraph={setIsShowCompanyGraph} />
  ) : (
    <GraphContentContainer
      setIsShowGraph={setIsShowGraph}
      setGraphData={setGraphData}
      defaultValue={graphDataObj?._source}
      isCheckChild={false}
    />
  );
};

CompanyGraphContainer.propTypes = {
  graphDataObj: PropTypes.object.isRequired,
  setIsShowCompanyGraph: PropTypes.func.isRequired,
};

export default CompanyGraphContainer;
