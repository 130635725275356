import botUp from "assest/botUP.png";
import botOver from "assest/botOVER.png";
import testUp from "assest/testUP.png";
import testOver from "assest/testOVER.png";
import graphUp from "assest/graphUP.png";
import graphOver from "assest/graphOVER.png";

const NavRoute = [
  {
    path: "/test-harness",
    iconOver: testOver,
    IconUp: testUp,
  },
  {
    path: "/graphs",
    iconOver: graphOver,
    IconUp: graphUp,
  },
  {
    path: "/robots",
    iconOver: botOver,
    IconUp: botUp,
  },
];

export default NavRoute;
