import React, { useEffect } from "react";
import { Meta } from "components/layout";
import { KibanaDataTable } from "components/kibana/DataTable";
import "@elastic/eui/dist/eui_theme_light.css";
import { EuiProvider } from "@elastic/eui";
import createCache from "@emotion/cache";

const cache = createCache({
  key: "codesandbox",
  container: document.querySelector('meta[name="emotion-styles"]'),
});
cache.compat = true;

const Kibana = () => {
  const pageTitle = "Kibana";

  return (
    <>
      <EuiProvider cache={cache}>
        <Meta title={pageTitle} />
        <div className="container">
          <div
            style={{
              boxShadow: "rgb(0 0 0 / 10%) 0px 4px 8px 0px",
              border: "1px solid rgba(0, 0, 0, 0.1)",
            }}
            className="mx-auto p-3"
          >
            <KibanaDataTable />
          </div>
        </div>
      </EuiProvider>
    </>
  );
};

export default Kibana;
