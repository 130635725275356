import React, { useEffect, useState } from "react";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import { MultipleContainersCombine } from "components/dnd/MultipleContainerWithCombine";
import { INITIAL_ICONS_QUANTITY, MEDIA_QUERY } from "constants/general";
import { iconsData } from "data/iconsData";
import { usePrevious } from "hooks/usePrevious";
import { useWindowSize } from "usehooks-ts";
import { separateArray } from "utils/FormatIconsArray";
import { calculateIconQuantity } from "utils/calculateIconQuantity";
import { useMediaQuery } from "react-responsive";

const MultipleContainersCombinePage = () => {
  const [columnsCount, setColumnsCount] = useState(5);
  const [icons, setIcons] = useState({});
  const [count, setCount] = useState(INITIAL_ICONS_QUANTITY);
  const previousCount = usePrevious(count);
  const { width, height } = useWindowSize();

  useEffect(() => {
    calculateIconQuantity(width, height, setCount);
    if (previousCount !== count) {
      const _icons = separateArray(iconsData, count);
      if (_icons && _icons.length) {
        setIcons(Object.assign({}, _icons));
      }
    }
  }, [width, height, count]);

  const isBigScreen = useMediaQuery({
    query: MEDIA_QUERY.BIG_SCREEN,
  });
  const isTabletScreen = useMediaQuery({
    query: MEDIA_QUERY.TABLET,
  });
  const isMobileScreen = useMediaQuery({
    query: MEDIA_QUERY.SMALL_MOBILE,
  });

  useEffect(() => {
    if (isBigScreen) {
      setColumnsCount(4);
    }
    if (isTabletScreen) {
      setColumnsCount(5);
    }
    if (isMobileScreen) {
      setColumnsCount(4);
    }
  }, [isBigScreen, isTabletScreen, isMobileScreen]);

  return (
    <MultipleContainersCombine
      columns={columnsCount}
      items={icons}
      setItems={setIcons}
      strategy={rectSortingStrategy}
      wrapperStyle={() => ({
        maxWidth: 74,
        maxHeight: 74,
      })}
      activationConstraint={{ distance: 5 }}
      getItemStyles={({ shouldCombine }) => {
        if (shouldCombine) {
          return { opacity: ".65" };
        }
        return {};
      }}
    />
  );
};

export default MultipleContainersCombinePage;
