import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { GraphWrapper } from "./SearchGraphComponent.styles";
import { formatQuoteData, getImage } from "utils/IllustrationQuote";

const ClusterTitle = {
  provenance: "Provenance",
  subjects: "Subjects",
  risk: "Risk",
  percayso: "Percayso",
};

const positionData = {
  GoSkippy: [0, -80],
  Provenance: [0, -80],
  openGl: [0, -160],
  channel: [0, -160],
  Compare_the_market: [0, -240],
  Risk: [0, 80],
  AX03LCW: [60, 170],
  vehicle: [-60, 160],
  Cover: [60, 160],
  Subjects: [-80, 0],
  ["John Smith"]: [-160, 80],
  ["person1"]: [-160, -80],
  ["SMITH01642123456"]: [-140, 160],
  ["j.smith@btinternet.com"]: [-220, 160],
  ["email2"]: [-220, 160],
  ["31_Oakwood_Court_TS7_8TA"]: [-260, 110],
  ["01642123456"]: [-250, 45],
  ["OTHER01642312845"]: [-100, -150],
  ["dln1"]: [-100, -150],
  ["a.other.hotmail.com"]: [-220, -10],
  ["email1"]: [-220, -10],
  ["01642312845"]: [-220, -140],
  ["telephone1"]: [-220, -140],
  Percayso_Inform: [80, 0],
  inform: [160, 0],
  deploy: [160, -60],
  enrich: [160, 60],
  Community_Gateway: [240, 0],
  Private_Gateway: [240, 40],
  ["Partner_Gateway"]: [300, 40],
  Percayso_Quote_Lake: [240, 80],
  Percayso_Gateway: [240, 120],
};

const getPosition = label => {
  const data = positionData[label];
  return data || [undefined, undefined];
};

const SearchGraphComponent = ({ graphData: graphDataObj }) => {
  const graphData = JSON.parse(JSON.stringify(graphDataObj));
  const domNode = useRef(null);
  const network = useRef(null);
  const options = {
    autoResize: true,
    height: "100%",
    width: "100%",
    nodes: {
      borderWidth: 0,
      borderWidthSelected: 0,
      font: { color: "white", align: "left", size: 7, background: "transparent" },
      color: "transparent",
      shadow: {
        enabled: false,
        size: 0,
      },
      shape: "image",
      fixed: true,
      size: 10,
      scaling: {
        min: 10,
        max: 10,
      },
    },
    edges: {
      color: "black",
      length: 80,
      selectionWidth: 0,
      width: 1,
    },
    physics: {
      enabled: false,
    },
  };

  const nodes = new DataSet([]);

  const edges = new DataSet([]);

  const data = {
    nodes,
    edges,
  };

  function generateTitles(data) {
    const textArr = Object.entries(formatQuoteData(data));
    const text = `${textArr.map(node => {
      return `${node[0]} : ${node[1]}\n`;
    })}`;
    return text.replace(/,/g, "");
  }

  function clusterByCid(id, label, nodeLabel, cid, image, imageType) {
    const clusterOptionsByData = {
      joinCondition: function (childOptions) {
        return childOptions.cid == id;
      },
      clusterNodeProperties: {
        id: `cidCluster${id}`,
        shape: "image",
        image: getImage(image ? image : "blueCircle", "png"),
        label,
        cid: cid ? cid : undefined,
        x: getPosition(nodeLabel)[0],
        y: getPosition(nodeLabel)[1],
        allowSingleNodeCluster: true,
        font: { color: "white", align: "left", size: 7, background: "transparent" },
        color: "transparent",
      },
    };
    network.current.cluster(clusterOptionsByData);
  }

  useEffect(() => {
    network.current = new Network(domNode.current, data, options);

    network.current.on("selectNode", function (params) {
      network.current.fit({
        maxZoomLevel: 2.5,
        minZoomLevel: 0.5,
      });

      if (params.nodes.length == 1) {
        if (network.current.isCluster(params.nodes[0]) == true) {
          network.current.openCluster(params.nodes[0]);
        }
      }
    });

    network.current.on("selectNode", function (params) {
      network.current.fit({
        maxZoomLevel: 2.5,
        minZoomLevel: 0.5,
      });

      if (params.nodes.length == 1) {
        const nodeInfo = nodes.get(params.nodes[0]);
        if (!nodeInfo?.isCidParent) return;
        switch (nodeInfo?.cid) {
          case 1:
            clusterByCid(1, ClusterTitle.subjects, ClusterTitle.subjects);
            break;
          case 2:
            clusterByCid(2, ClusterTitle.risk, ClusterTitle.risk);
            break;
          case 3:
            clusterByCid(3, ClusterTitle.provenance, ClusterTitle.provenance);
            break;
          default:
            break;
        }
      }
    });
  }, [domNode, network, data, options]);

  useEffect(() => {
    nodes.add({
      id: 0,
      label: graphData.client,
      image: getImage(graphData.client),
      x: 0,
      y: 0,
    });
    nodes.add({
      id: 1,
      label: "Subjects",
      image: getImage("Subjects"),
      x: getPosition("Subjects")[0],
      y: getPosition("Subjects")[1],
      cid: 1,
      isCidParent: true,
    });
    nodes.add({
      id: 2,
      label: `${graphData.subjects[0].validateName.surname} ${graphData.subjects[0].validateName.forename}`,
      image: getImage(`person-${graphData.subjects[0].gender.toLowerCase()}`),
      x: getPosition("person1")[0],
      y: getPosition("person1")[1],
      cid: 1,
    });
    nodes.add({
      id: 3,
      label: graphData.subjects[0].email,
      image: getImage("email"),
      x: getPosition("email1")[0],
      y: getPosition("email1")[1],
      cid: 1,
    });
    nodes.add({
      id: 4,
      label: graphData.subjects[0].telephone,
      image: getImage("telephone"),
      x: getPosition("telephone1")[0],
      y: getPosition("telephone1")[1],
      cid: 1,
    });
    nodes.add({
      id: 5,
      label: graphData.subjects[0].dln,
      image: getImage("dln"),
      x: getPosition("dln1")[0],
      y: getPosition("dln1")[1],
      cid: 1,
    });
    nodes.add({
      id: 6,
      label: "Risk",
      image: getImage("Risk"),
      x: getPosition("Risk")[0],
      y: getPosition("Risk")[1],
      cid: 2,
      isCidParent: true,
    });
    nodes.add({
      id: 7,
      label: "Cover",
      image: getImage("Cover"),
      x: getPosition("Cover")[0],
      y: getPosition("Cover")[1],
      title: generateTitles(formatQuoteData(graphData.risk.cover)),
      cid: 2,
    });
    nodes.add({
      id: 8,
      label: graphData.risk.vehicle[0].vrn,
      image: getImage("car"),
      x: getPosition("vehicle")[0],
      y: getPosition("vehicle")[1],
      cid: 2,
    });
    nodes.add({
      id: 9,
      label: graphData.provenance.channel,
      image: getImage("channel"),
      x: getPosition("channel")[0],
      y: getPosition("channel")[1],
      cid: 3,
      isCidParent: true,
    });

    edges.add({ from: 0, to: 1 });
    edges.add({ from: 1, to: 2 });
    edges.add({ from: 2, to: 3 });
    edges.add({ from: 2, to: 4 });
    edges.add({ from: 2, to: 5 });
    edges.add({ from: 0, to: 6 });
    edges.add({ from: 6, to: 7 });
    edges.add({ from: 6, to: 8 });
    edges.add({ from: 0, to: 9 });
  }, []);

  useEffect(() => {
    clusterByCid(1, ClusterTitle.subjects, ClusterTitle.subjects);
    clusterByCid(2, ClusterTitle.risk, ClusterTitle.risk);
    clusterByCid(3, ClusterTitle.provenance, ClusterTitle.provenance);

    network.current.fit({
      maxZoomLevel: 2.5,
      minZoomLevel: 0.5,
    });
  }, [network.current]);

  return <GraphWrapper ref={domNode} />;
};

SearchGraphComponent.propTypes = {
  graphData: PropTypes.object.isRequired,
};

export default SearchGraphComponent;
