import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, TextAreaContainer, TextArea } from "./ConeGraphContentContainer.styles";
import { CONE_JSON_DATA_FOUR as CONE_JSON_DATA } from "data/coneGraphData";

const GraphContentContainer = ({ setIsShowGraph, setGraphData }) => {
  const [isShowError, setIsShowError] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [isShowInvalidJsonError, setIsShowInvalidJsonError] = useState(false);
  const [isShowInvalidJsonErrMsg, setIsShowInvalidJsonErrMsg] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(stringify(CONE_JSON_DATA));

  const setGraphDataHandler = () => {
    setValidationErrorMessage("");
    setIsShowError(false);
    setIsShowInvalidJsonError(false);
    setIsShowInvalidJsonErrMsg(false);
    if (!textAreaValue) {
      setIsShowError(true);
      return;
    }

    const data = tryParseJSONObject(textAreaValue);

    if (!data) {
      setIsShowInvalidJsonError(true);
      return;
    }

    if (!data.enrich) {
      return setValidationErrorMessage("You can't view graph without enabling Enrich.");
    }

    if (data.inform && !data.enrich) {
      return setValidationErrorMessage("You can't enable Inform without enabling Enrich.");
    }

    if (data.deploy && !data.inform) {
      return setValidationErrorMessage("You can't enable Deploy without enabling Inform.");
    }

    if (data.percaysoInfoManager && !data.inform) {
      return setValidationErrorMessage("You can't enable Percayso Inform Manager without enabling Inform.");
    }

    if (data.deploy && !data.inform) {
      return setValidationErrorMessage("You can't enable Percayso Inform Manager without enabling Inform.");
    }

    if (data.clientArea && !data.inform) {
      return setValidationErrorMessage("You can't enable Client Area without enabling Inform.");
    }

    const checkResult = false;

    if (checkResult !== false) {
      setIsShowInvalidJsonErrMsg(checkResult);
      return;
    }

    setGraphData(data);
    setIsShowGraph(true);
  };

  return (
    <Container className="w-100 d-flex justify-content-center pb-5">
      <TextAreaContainer className="text-center">
        <TextArea
          spellCheck={false}
          className="w-100 h-100 rounded-3 p-2 form-control"
          placeholder="Data"
          value={(textAreaValue && textAreaValue) || ""}
          onChange={event => setTextAreaValue(event.target.value)}
        />
        {validationErrorMessage && <p className="name-validate-error">{validationErrorMessage}</p>}
        {isShowError && <p className="name-validate-error">The graph data is required field!</p>}
        {isShowInvalidJsonError && <p className="name-validate-error">Invalid JSON format.</p>}
        {isShowInvalidJsonErrMsg && <p className="name-validate-error">{isShowInvalidJsonErrMsg}</p>}
        <button className="mx-auto d-block mt-3 text-center btn-validate" onClick={setGraphDataHandler}>
          Submit
        </button>
      </TextAreaContainer>
    </Container>
  );
};

GraphContentContainer.propTypes = {
  setIsShowGraph: PropTypes.func.isRequired,
  setGraphData: PropTypes.func.isRequired,
};

export default GraphContentContainer;

function tryParseJSONObject(jsonString) {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.error(e);
  }

  return false;
}

function stringify(data) {
  return JSON.stringify(data, undefined, 3);
}

function parse(data) {
  return JSON.parse(data);
}
