/* eslint-disable react/prop-types */
import { default as ConfusedQuoteCard } from "components/confused/Card";
import { default as GoCompareQuoteCard } from "components/goCompare/Card";

const goCompareCarInsurance = "car_insurance";
const confusedCarInsurance = "confused_car_insurance";

const CarQuote = ({ data }) => {
  switch (data?._index) {
    case goCompareCarInsurance:
      return <GoCompareQuoteCard data={data} />;
    case confusedCarInsurance:
      return <ConfusedQuoteCard data={data} />;

    default:
      return null;
  }
};

export default CarQuote;
