import styled from "@emotion/styled/macro";

export const Container = styled.div`
  height: 100vh;
  padding-top: 0.5rem;
  @media (min-width: 993px) {
    height: calc(100vh - 9rem);
  }
  @media (max-width: 992px) {
    align-items: center;
    padding-top: 0;
  }
`;

export const TextAreaContainer = styled.div`
  width: min(100% - 4rem, 860px);
  height: min(100% - 4rem, 980px);
  margin-inline: auto;
`;

export const TextArea = styled.textarea`
  background-color: var(--tapestry);
  border: none;
  color: var(--white) !important;
  font-size: 1rem;
  font-family: "GreycliffCf-Medium";

  &:focus {
    background-color: var(--tapestry);
  }
`;
