import React from "react";
import { usePercaysoDataTable } from "hooks/usePercaysoDataTable";
import SearchItem from "./SearchItem";
import PropTypes from "prop-types";

const SearchItems = ({ setIsPopoverOpen }) => {
  const { apiResponse } = usePercaysoDataTable();
  return (
    <div className="p-2">
      {apiResponse.map((item, i) =>
        item.options.length ? (
          <div key={i}>
            <p className="fw-bold text-white label">{item.label}</p>
            <div>
              {item.options.map((option, i) => (
                <SearchItem key={i} option={option} setIsPopoverOpen={setIsPopoverOpen} />
              ))}
            </div>
          </div>
        ) : null,
      )}
    </div>
  );
};

SearchItems.propTypes = {
  setIsPopoverOpen: PropTypes.func.isRequired,
};

export default SearchItems;
