import React from "react";
import PropTypes from "prop-types";

function ErrorMessage({ error, ...props }) {
  return (
    <div
      role="alert"
      style={{
        position: "absolute",
        width: "100%",
        maxWidth: "320px",
        color: "#ffcb6a",
        textAlign: "center",
        margin: "2.5rem 0",
        fontFamily: "GreycliffCF-Bold",
      }}
      {...props}
    >
      <span>{error.message}</span>
    </div>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.object.isRequired,
};

ErrorMessage.defaultProps = {
  error: { message: "An Error occurred" },
};

export default ErrorMessage;
