import React, { useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { GraphWrapper } from "./DlnGraph.styles";
import dlnIcon from "./../../assest/drivinglicence-up.png";

const DlnGraph = ({ graphData: graphDataObj, setIsShowJSONField, handleAnother }) => {
  const graphData = JSON.parse(JSON.stringify(graphDataObj));
  const domNode = useRef(null);
  const network = useRef(null);
  const options = {
    autoResize: true,
    height: "100%",
    width: "100%",
    nodes: {
      borderWidth: 0,
      borderWidthSelected: 0,
      font: { color: "white", align: "left", size: 20, background: "transparent" },
      color: "transparent",
      shadow: {
        enabled: false,
        size: 0,
      },
      shape: "image",
      fixed: {
        x: true,
        y: true,
      },
      size: 35,
      scaling: {
        min: 10,
        max: 10,
      },
    },
    edges: {
      color: "black",
      length: 80,
      selectionWidth: 0,
      width: 1,
    },
    physics: {
      enabled: false,
    },
  };

  const nodes = new DataSet([]);
  const edges = new DataSet([]);

  const data = {
    nodes,
    edges,
  };

  const showOthersChild = data => {
    if (!data) return;

    let dataArr = Object.entries(data).filter((node, index) => typeof node[1] !== "object");

    const text = `${dataArr.slice(1).map(node => {
      if (node[0] !== "" && node[1] !== "") {
        return `${node[0]} : ${node[1]}\n`;
      }
    })}`;

    if (!text) return;

    nodes.updateOnly({
      id: 0,
      title: text.replace(/,/g, ""),
    });
  };

  useEffect(() => {
    nodes.add({ id: 0, label: "DLN", image: dlnIcon, shape: "image" });

    showOthersChild({ ...graphData });

    network.current = new Network(domNode.current, data, options);
  }, [data]);

  return (
    <div className="w-100 h-100 validate-address-graph">
      <GraphWrapper ref={domNode} />
      <div className="d-flex justify-content-center align-items-center flex-wrap">
        <button
          className="btn-validate mx-1"
          onClick={() => {
            setIsShowJSONField(true);
          }}
        >
          View JSON
        </button>
        <button className="btn-validate mx-1" onClick={handleAnother}>
          Another
        </button>
      </div>
    </div>
  );
};

DlnGraph.propTypes = {
  graphData: PropTypes.object.isRequired,
  setIsShowJSONField: PropTypes.func.isRequired,
  handleAnother: PropTypes.func.isRequired,
};

export default DlnGraph;
