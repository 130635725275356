import React, { useContext, useEffect, useRef } from "react";
import { element, PropTypes } from "prop-types";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { GraphWrapper } from "./PercaysoGraph.styles";
import companyImage from "./../../assest/companyGraph/company.jpg";
import licenseImage from "./../../assest/companyGraph/license.png";
import mailImage from "./../../assest/companyGraph/mail.png";
import flatten from "flat";
import { PercaysoContext } from "pages/percayso/Percayso";
import { useLayoutEffect } from "react";
import { usePercaysoDataTable } from "hooks/usePercaysoDataTable";

const PercaysoCompanyGraph = ({ graphData: graphDataObj }) => {
  const { setIsShowGraph, setIsShowSearch, selectedOption, setSelectedOption, setCompanyGraphData } =
    usePercaysoDataTable();

  const graphData = JSON.parse(JSON.stringify(graphDataObj));
  const domNode = useRef(null);
  const network = useRef(null);
  const options = {
    autoResize: true,
    height: "100%",
    width: "100%",
    nodes: {
      borderWidth: 0,
      borderWidthSelected: 0,
      font: { color: "white", align: "left", size: 20, background: "transparent" },
      color: "transparent",
      shadow: {
        enabled: false,
        size: 0,
      },
      shape: "image",
      fixed: {
        x: true,
        y: true,
      },
      size: 35,
      scaling: {
        min: 10,
        max: 10,
      },
    },
    edges: {
      color: "black",
      length: 120,
      selectionWidth: 0,
      width: 1,
    },
    // physics: false,
    physics: {
      barnesHut: {
        gravitationalConstant: -12900,
        centralGravity: 0,
      },

      minVelocity: 1,

      stabilization: {
        enabled: true,
        iterations: 100,
      },
    },
  };

  const nodes = new DataSet([]);
  const edges = new DataSet([]);

  const data = {
    nodes,
    edges,
  };

  const showOthersChild = (data, parentId) => {
    if (!data) return;

    let dataArr = Object.entries(data).filter((node, index) => typeof node[1] !== "object");

    const text = `${dataArr.map(node => {
      if (node[0] !== "" && node[1] !== "") {
        return `${node[0]} : ${node[1]}\n`;
      }
    })}`;

    if (!text) return;

    nodes.updateOnly({
      id: parentId,
      title: text.replace(/,/g, ""),
    });
  };

  useEffect(() => {
    if (graphData?.companyDetails.length) {
      nodes.add({
        id: 0,
        label: graphData.companyDetails[0].properties.name,
        image: companyImage,
        shape: "image",
        x: 0,
        y: 0,
      });

      graphData.companyDetails.forEach(item => {
        showOthersChild(flatten({ ...item.properties }), 0);
      });
    }

    if (graphData?.contactDetails.length) {
      nodes.add({
        id: 1,
        label: "Contacts",
        image: licenseImage,
        shape: "image",
        x: -360,
        y: 0,
        title: `Contacts: ${graphData?.contactDetails.length}`,
      });

      graphData?.contactDetails.forEach((item, i) => {
        nodes.add({
          id: `contact-${item.id}`,
          label: item.properties.email,
          image: mailImage,
          shape: "image",
          fixed: false,
          size: 16,
          font: { size: 14 },
        });

        edges.add({ from: 1, to: `contact-${item.id}`, smooth: { type: "vertical", roundness: -0.1 } });
        showOthersChild(flatten({ ...item.properties }), `contact-${item.id}`);
      });

      edges.add({ from: 1, to: 0 });
    }

    if (graphData?.dealsDetails.length) {
      nodes.add({
        id: 2,
        label: "Deals",
        image: licenseImage,
        shape: "image",
        x: 360,
        y: 0,
        title: `Deals: ${graphData?.dealsDetails.length}`,
      });

      graphData?.dealsDetails.forEach((item, i) => {
        nodes.add({
          id: `deal-${item.id}`,
          label: item.properties.dealname,
          image: licenseImage,
          shape: "image",
          fixed: false,
          size: 16,
          font: { size: 14 },
        });

        edges.add({ from: 2, to: `deal-${item.id}`, smooth: { type: "vertical", roundness: -0.1 } });
        showOthersChild(flatten({ ...item.properties }), `deal-${item.id}`);
      });

      edges.add({ from: 2, to: 0 });
    }

    network.current = new Network(domNode.current, data, options);
  }, [data]);

  function fit() {
    network?.current.fit();
    network?.current.moveTo({
      position: { x: 0, y: 0 },
    });
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", fit);

    return () => window.removeEventListener("resize", fit);
  }, []);

  useEffect(() => {
    if (network?.current) {
      network?.current.on("stabilizationIterationsDone", function () {
        network?.current.setOptions({ physics: false });
        network?.current.moveTo({
          position: { x: 0, y: 0 },
        });
      });
    }
  }, [network.current]);

  return (
    <div className="w-100 h-100">
      <GraphWrapper ref={domNode} />
      <div className="text-center">
        <button
          className="btn-validate"
          onClick={() => {
            setIsShowGraph(false);
            setIsShowSearch(true);
            setCompanyGraphData(null);
            if (selectedOption) {
              setSelectedOption(null);
              return;
            }
          }}
        >
          Another
        </button>
      </div>
    </div>
  );
};

PercaysoCompanyGraph.propTypes = {
  graphData: PropTypes.object.isRequired,
};

export default PercaysoCompanyGraph;
