import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DataSet, Network } from "./vis-network";

import enrichImage from "../../assest/cone/enrich-cone.png";
import informImage from "../../assest/cone/inform-cone.png";
import deployImage from "../../assest/cone/deploy-cone.png";

import communityImage from "../../assest/cone/community.png";
import privateImage from "../../assest/cone/private.png";
import percaysoImage from "../../assest/cone/percayso.png";
import partnerImage from "../../assest/cone/partner.png";
import publishLinesImage from "../../assest/cone/publish-lines.png";
import saveLinesImage from "../../assest/cone/save-lines.png";
import pim1Image from "../../assest/cone/PIM01.png";
import pim2Image from "../../assest/cone/PIM02.png";
import extractLinesImage from "../../assest/cone/extract-lines.png";
import quoteImage from "../../assest/cone/quote.png";
import clientImage from "../../assest/cone/client.png";
import clientLineBigImage from "../../assest/cone/client-lines-big.png";
import clientLineSmallImage from "../../assest/cone/client-lines-small.png";

import { coneGraphOptions } from "./ConeGraphOptions";
import { CONE_JSON_DATA_TITLES } from "data/coneGraphData";
import { GraphWrapper } from "./ConeGraph.styles";

const ConeGraph = ({ jsonData, setIsShowGraph, isDeveloperMode }) => {
  const domNode = useRef(null);
  const network = useRef(null);

  const [selectedNodePosition, setSelectedNodePosition] = useState({ x: 0, y: 0 });

  const nodes = new DataSet([]);

  const edges = new DataSet([]);

  const data = {
    nodes,
    edges,
  };

  const addNetwork = useCallback(() => {
    network.current = new Network(domNode.current, data, coneGraphOptions);
  }, [domNode, data, coneGraphOptions]);

  useEffect(() => {
    addNetwork();
  }, []);

  useEffect(() => {
    if (isDeveloperMode) {
      network.current.on("dragging", opts => {
        setSelectedNodePosition(network.current.getPosition(opts.nodes[0], true));
      });

      return;
    }

    //disable select node
    network.current.on("selectNode", opts => {
      network.current.setSelection({
        nodes: "",
        edges: opts.edges,
      });
    });
    network.current.on("doubleClick", opts => {
      network.current.setSelection({
        nodes: "",
        edges: opts.edges,
      });
    });
    network.current.on("hold", opts => {
      network.current.setSelection({
        nodes: "",
        edges: opts.edges,
      });
    });

    network.current.on("dragStart", opts => {
      network.current.setSelection({
        nodes: "",
        edges: opts.edges,
      });
    });
  }, [network.current]);

  const getPosition = (label, direction) => {
    if (jsonData?.[label]?.[direction]) {
      return jsonData?.[label]?.[direction];
    } else {
      return false;
    }
  };

  const checkIsHideNode = label => {
    if (typeof jsonData?.[label] === "boolean") {
      return !jsonData?.[label];
    } else if (jsonData?.[label]?.enabled === undefined) {
      return false;
    } else {
      return !jsonData?.[label]?.enabled;
    }
  };

  const checkIsHideInformNodeArrowLine = () => {
    if (!checkIsHideNode(CONE_JSON_DATA_TITLES.deploy)) {
      return true;
    } else return checkIsHideNode(CONE_JSON_DATA_TITLES.inform);
  };

  const addNodesAndEdges = useCallback(() => {
    //  start:hidden node
    nodes.add({
      id: 0,
      label: "",
      image: enrichImage,
      x: getPosition(CONE_JSON_DATA_TITLES.enrich, "x") || 0,
      y: getPosition(CONE_JSON_DATA_TITLES.enrich, "y") || 0,
      size: 41,
      fixed: true,
      hidden: true,
    });
    nodes.add({
      id: 1,
      label: "",
      image: informImage,
      x: getPosition(CONE_JSON_DATA_TITLES.inform, "x") || 0,
      y: getPosition(CONE_JSON_DATA_TITLES.inform, "y") || -29,
      size: 39,
      fixed: true,
      hidden: true,
    });
    nodes.add({
      id: 2,
      label: "",
      fixed: true,
      image: deployImage,
      x: getPosition(CONE_JSON_DATA_TITLES.deploy, "x") || 0,
      y: getPosition(CONE_JSON_DATA_TITLES.deploy, "y") || -64,
      size: 20,
      hidden: true,
    });
    // end:hidden node

    nodes.add({
      id: 3,
      label: "",
      image: communityImage,
      x: getPosition(CONE_JSON_DATA_TITLES.community, "x") || 80.1,
      y: getPosition(CONE_JSON_DATA_TITLES.community, "y") || -17.9,
      size: 15.1,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.community),
    });
    nodes.add({
      id: 4,
      label: "",
      image: privateImage,
      x: getPosition(CONE_JSON_DATA_TITLES.private, "x") || 85,
      y: getPosition(CONE_JSON_DATA_TITLES.private, "y") || 6,
      size: 15.1,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.private),
    });
    nodes.add({
      id: 5,
      label: "",
      image: percaysoImage,
      x: getPosition(CONE_JSON_DATA_TITLES.percayso, "x") || 77.6763,
      y: getPosition(CONE_JSON_DATA_TITLES.percayso, "y") || 29.891,
      size: 15.1,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.percayso),
    });
    nodes.add({
      id: 6,
      label: "",
      image: partnerImage,
      x: getPosition(CONE_JSON_DATA_TITLES.partner, "x") || 53.5727,
      y: getPosition(CONE_JSON_DATA_TITLES.partner, "y") || 43.3425,
      size: 14,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.partner),
    });

    nodes.add({
      id: "enrich_node",
      label: "",
      image: enrichImage,
      x: getPosition(CONE_JSON_DATA_TITLES.enrich, "x") || 0,
      y: getPosition(CONE_JSON_DATA_TITLES.enrich, "y") || 0,
      size: 41,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.enrich),
    });
    nodes.add({
      id: "inform_node",
      label: "",
      image: informImage,
      x: getPosition(CONE_JSON_DATA_TITLES.inform, "x") || 0,
      y: getPosition(CONE_JSON_DATA_TITLES.inform, "y") || -30,
      size: 39,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.inform),
    });
    nodes.add({
      id: "deploy_node",
      label: "",
      image: deployImage,
      x: getPosition(CONE_JSON_DATA_TITLES.deploy, "x") || 0,
      y: getPosition(CONE_JSON_DATA_TITLES.deploy, "y") || -65,
      size: 20,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.deploy),
    });
    nodes.add({
      id: "client_line_big_image",
      label: "",
      image: clientLineBigImage,
      x: getPosition(CONE_JSON_DATA_TITLES.clientLineBig, "x") || -85,
      y: getPosition(CONE_JSON_DATA_TITLES.clientLineBig, "y") || -35,
      size: 14,
      hidden: checkIsHideInformNodeArrowLine(),
    });
    nodes.add({
      id: "client",
      label: "",
      image: clientImage,
      x: getPosition(CONE_JSON_DATA_TITLES.client, "x") || -145,
      y: getPosition(CONE_JSON_DATA_TITLES.client, "y") || -19,
      size: 14,
      hidden: !checkIsHideNode(CONE_JSON_DATA_TITLES.deploy),
    });
    nodes.add({
      id: "client_line_sm_image",
      label: "",
      image: clientLineSmallImage,
      x: getPosition(CONE_JSON_DATA_TITLES.clientLineSmall, "x") || -95,
      y: getPosition(CONE_JSON_DATA_TITLES.clientLineSmall, "y") || -5,
      size: 14,
      hidden: !checkIsHideNode(CONE_JSON_DATA_TITLES.inform),
    });
    nodes.add({
      id: 7,
      label: "",
      image: saveLinesImage,
      x: getPosition(CONE_JSON_DATA_TITLES.saveLines, "x") || 81,
      y: getPosition(CONE_JSON_DATA_TITLES.saveLines, "y") || -69.5,
      size: 10,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.clientArea),
    });
    nodes.add({
      id: 8,
      label: "",
      image: publishLinesImage,
      x: getPosition(CONE_JSON_DATA_TITLES.publishLines, "x") || -80,
      y: getPosition(CONE_JSON_DATA_TITLES.publishLines, "y") || -67,
      size: 12,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.percaysoInfoManager),
    });
    nodes.add({
      id: 9,
      label: "",
      image: pim1Image,
      x: getPosition(CONE_JSON_DATA_TITLES.percaysoInfoManager, "x") || -100,
      y: getPosition(CONE_JSON_DATA_TITLES.percaysoInfoManager, "y") || -103,
      size: 23.6,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.percaysoInfoManager),
    });
    nodes.add({
      id: 10,
      label: "",
      image: pim2Image,
      x: getPosition(CONE_JSON_DATA_TITLES.clientArea, "x") || 101,
      y: getPosition(CONE_JSON_DATA_TITLES.clientArea, "y") || -103,
      size: 23.6,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.clientArea),
    });
    nodes.add({
      id: 11,
      label: "",
      image: extractLinesImage,
      x: getPosition(CONE_JSON_DATA_TITLES.extractLines, "x") || 1,
      y: getPosition(CONE_JSON_DATA_TITLES.extractLines, "y") || -151,
      size: 24,
      hidden:
        !checkIsHideNode(CONE_JSON_DATA_TITLES.percaysoInfoManager) &&
        !checkIsHideNode(CONE_JSON_DATA_TITLES.clientArea)
          ? false
          : true,
    });
    nodes.add({
      id: 12,
      label: "",
      image: quoteImage,
      x: getPosition(CONE_JSON_DATA_TITLES.quote, "x") || 0,
      y: getPosition(CONE_JSON_DATA_TITLES.quote, "y") || -125,
      size: 21,
      hidden: checkIsHideNode(CONE_JSON_DATA_TITLES.deploy),
    });

    edges.add({ from: 0, to: 1 });
    edges.add({ from: 1, to: 2 });
    edges.add({ from: 0, to: 3 });
    edges.add({ from: 0, to: 4 });
    edges.add({ from: 0, to: 5 });
    edges.add({ from: 0, to: 6 });
    edges.add({ from: "deploy_node", to: 7 });
    edges.add({ from: "deploy_node", to: 8 });
    edges.add({ from: 8, to: 9 });
    edges.add({ from: 7, to: 10 });
    edges.add({ from: 0, to: 11 });
    edges.add({ from: 0, to: 12 });
  }, [jsonData, network.current]);

  useEffect(() => {
    addNodesAndEdges();
    network.current.fit({
      maxZoomLevel: 2.5,
      minZoomLevel: 0.5,
    });
  }, [jsonData, network.current]);

  return (
    <div className="w-100 h-100">
      <GraphWrapper ref={domNode} />
      <div className="text-center">
        <button className="btn-validate" onClick={() => setIsShowGraph(false)}>
          Another
        </button>
        {isDeveloperMode && <span className="fs4">{JSON.stringify(selectedNodePosition)}</span>}
      </div>
    </div>
  );
};

ConeGraph.propTypes = {
  jsonData: PropTypes.object.isRequired,
  setIsShowGraph: PropTypes.func.isRequired,
  isDeveloperMode: PropTypes.bool,
};

ConeGraph.defaultProps = {
  isDeveloperMode: false,
};

export default ConeGraph;
