import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./KibanaRowCountTable.style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useIsShowNavBar } from "hooks/useIsShowNavBar";
import WebView from "components/percayso/WebView";

const KibanaRowCountTable = () => {
  const { setIsShowNavBar } = useIsShowNavBar();

  useEffect(() => {
    setIsShowNavBar(false);
    return () => {
      setIsShowNavBar(true);
    };
  }, []);

  return (
    <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
      <div className="iframe-box">
        <Link to="/percayso" className="close-btn">
          <FontAwesomeIcon icon={faXmark} />
        </Link>

        <div className="rounded position-absolute top-0 start-0 bg-white h-100 w-100">
          <WebView isShow={true} url={process.env.REACT_APP_KIBANA_DASHBOARD_URL} />
        </div>
      </div>
    </div>
  );
};

export default KibanaRowCountTable;
