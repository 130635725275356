import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const FormattedTable = ({ data, columns, type }) => {
  const navigate = useNavigate();

  const goToDetail = id => {
    if (type === "company") navigate(`detail/${id}`);
  };

  return (
    <div>
      <div className="table-card">
        <Table hover>
          <thead>
            <tr>
              {columns.map(record => (
                <th key={record.text}>{record.text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(record => (
              <tr
                key={record.id + (record.name ? record.name : record.ripped_name)}
                onClick={() => goToDetail(record.id)}
              >
                {columns.map(column => (
                  <td
                    key={column.dataField + record[column.dataField]}
                    className={column.dataField === "number" ? "companyId" : null}
                  >
                    {`${record[column.dataField]}`.toLowerCase()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

FormattedTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string.isRequired,
      name: PropTypes.string,
      number: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,

  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,

  type: PropTypes.string.isRequired,
};

export default FormattedTable;
