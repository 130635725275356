/* eslint-disable react/prop-types */
import React from "react";
import { Button, Modal } from "react-bootstrap";

const CallInfoModal = ({ data, ...props }) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>Buy this policy over the phone</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center px-0">
        <h4 className="text-danger fs-4">{data.partnerPhoneNumber}</h4>
        <hr />
        <p className="mb-0 sm_text">Your quote reference is:</p>
        <p className="fs-6 fw-bold m-0">{data.quoteReferenceNumber}</p>
        <div className="mt-3">
          <p className="sm_text fw-bold m-0">Call centre info</p>
          {data.partnerOpeningHours.split(",").map((item, index) => (
            <p key={index} className="m-0 sm_text">
              {item}
            </p>
          ))}
        </div>
        {data.allowsPurchaseOnline ? (
          <a
            href={`https://car.gocompare.com/GeneralHolding/Index/${data.resultId}?pageRank=${data.resultsPageRank}&clickThroughId=${data.clickThroughId}&features=`}
            className="btn btn-danger btn-large rounded-pill rounded-pill mt-4 mb-2 py-3 px-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy online instead
          </a>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default CallInfoModal;
