/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useMediaQuery } from "react-responsive";

import { ErrorFallback, FullPageErrorFallback } from "components/errors";
import { Nav } from "components/layout";
import AppRoutes from "./Routes";

import "styles/AuthenticatedApp.css";
import FeaturedAppsBar from "components/layout/FeaturedAppsBar";
import { Container, Main } from "./AuthenticatedApp.styles";
import { MEDIA_QUERY } from "constants/general";

import FolderModalProvider from "context/FolderModalContext";
import IsShowFeaturedAppsBarContextProvider from "context/IsShowFeaturedAppsBarContext";
import { PercaysoDataTableProvider } from "hooks/usePercaysoDataTable";
import { NavBarProvider } from "hooks/useIsShowNavBar";

function AuthenticatedApp() {
  const isTabletScreen = useMediaQuery({
    query: MEDIA_QUERY.TABLET,
  });

  return (
    <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
      <IsShowFeaturedAppsBarContextProvider>
        <FolderModalProvider>
          <PercaysoDataTableProvider>
            <NavBarProvider>
              {!isTabletScreen && <Nav />}
              <Container>
                <Main>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <AppRoutes />
                  </ErrorBoundary>
                </Main>
              </Container>
              {isTabletScreen && <FeaturedAppsBar />}
            </NavBarProvider>
          </PercaysoDataTableProvider>
        </FolderModalProvider>
      </IsShowFeaturedAppsBarContextProvider>
    </ErrorBoundary>
  );
}

export default AuthenticatedApp;
