import React, { createContext, useContext, useReducer, useState } from "react";
import { featuredAppsBarReducer, initialFeaturedAppsBarState, Action } from "reducer/IsShowFeaturedAppsBarReducer";
import PropTypes from "prop-types";

export const IsShowFeaturedAppsBarContext = createContext(undefined);

IsShowFeaturedAppsBarContext.displayName = "IsShowFeaturedAppsBarContext";

const IsShowFeaturedAppsBarContextProvider = props => {
  const [{ isShowAppsBar }, dispatch] = useReducer(featuredAppsBarReducer, initialFeaturedAppsBarState);

  return (
    <IsShowFeaturedAppsBarContext.Provider
      value={{
        isShowAppsBar,
        dispatch,
        Action,
      }}
    >
      {props.children}
    </IsShowFeaturedAppsBarContext.Provider>
  );
};

IsShowFeaturedAppsBarContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default IsShowFeaturedAppsBarContextProvider;

export function useIsShowFeaturedAppsBar() {
  const context = useContext(IsShowFeaturedAppsBarContext);
  if (context === undefined) {
    throw new Error(`useIsShowFeaturedAppsBar must be used within a IsShowFeaturedAppsBarContextProvider`);
  }
  return context;
}
