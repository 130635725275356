import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FieldsWrapper } from "components/lib";
import { InputForm } from "components/forms";
import { Form } from "react-bootstrap";
import { CompanyContext } from "pages/Companies";

const SearchInput = props => {
  const { searchTerm, setSearchTerm, errorMessage, searchText, setSearchText } = useContext(CompanyContext);
  return (
    <div className="mt-3 address-form-wrapper">
      <FieldsWrapper>
        <InputForm
          placeholder={`Search ${searchTerm}`}
          type={"text"}
          value={searchText}
          handleChange={event => setSearchText(event.target.value)}
        />
      </FieldsWrapper>
      <FieldsWrapper>
        <div className="d-flex justify-content-center align-items-center">
          <p className="me-3 fw-medium fs-4">Database: </p>
          <Form.Group
            className="mb-2 text-white d-flex justify-content-center align-items-center"
            controlId="checkbox_category"
          >
            <Form.Check
              name="searchTerm"
              type="radio"
              value="category"
              label="Category"
              checked={searchTerm === "category"}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <Form.Check
              name="searchTerm"
              type="radio"
              value="companies"
              label="Companies"
              className="ms-3"
              checked={searchTerm === "companies"}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </div>
      </FieldsWrapper>
      <div className="name-validate-error">{errorMessage}</div>
    </div>
  );
};

SearchInput.propTypes = {};

export default SearchInput;
