const container = {
  position: "relative",

  /* flexbox */
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  /* selectable span when even components are nested */
  pointerEvents: "none",
};

const absoluteContainer = {
  position: "absolute",
  top: 0,
};

const text = {
  position: "relative",
  margin: 0,

  /* Firefox support */
  display: "block",

  /* selectable span when even components are nested */
  pointerEvents: "initial",
};

const character = {
  position: "absolute",
};

const upsideDown = {
  display: "inline-block",
  transform: "rotateX(180deg)",
};

export const styles = {
  container,
  absoluteContainer,
  text,
  character,
  upsideDown,
};
