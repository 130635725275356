export const formatSearchResult = dataArr => {
  const dealsArr = [];
  const productsArr = [];
  const companiesArr = [];
  const contactsArr = [];
  const pipelineArr = [];

  const data = [
    {
      label: "Deals",
      options: dealsArr,
    },
    {
      label: "Products",
      options: productsArr,
    },
    {
      label: "Contacts",
      options: contactsArr,
    },
    {
      label: "Companies",
      options: companiesArr,
    },

    {
      label: "Pipeline",
      options: pipelineArr,
    },
  ];

  for (const item of dataArr) {
    if (item._index === "hubspot_deals") {
      dealsArr.push({
        label: item?._source?.properties?.dealname,
        value: { ...item?._source, categoryName: "deals" },
      });
    }
    if (item._index === "hubspot_products") {
      productsArr.push({
        label: item?._source?.properties?.name,
        value: { ...item?._source, categoryName: "products" },
      });
    }
    if (item._index === "hubspot_companies") {
      companiesArr.push({
        label: item?._source?.properties?.name,
        value: { ...item?._source, categoryName: "companies" },
      });
    }
    if (item._index === "hubspot_contacts") {
      contactsArr.push({
        label: item?._source?.properties?.email,
        value: { ...item?._source, categoryName: "contacts" },
      });
    }
    if (item._index === "hubspot_pipeline") {
      pipelineArr.push({ label: item?._source?.label, value: { ...item?._source, categoryName: "pipeline" } });
    }
  }

  return data;
};
