export const coneGraphOptions = {
  autoResize: true,
  height: "100%",
  width: "100%",
  nodes: {
    borderWidth: 0,
    borderWidthSelected: 0,
    font: { color: "white", align: "left", size: 7, background: "transparent" },
    color: "transparent",
    shadow: {
      enabled: false,
      size: 0,
    },
    shape: "image",
    fixed: true,
    size: 10,
    scaling: {
      min: 60,
      max: 60,
    },
  },
  edges: {
    color: "black",
    length: 80,
    selectionWidth: 0,
    width: 1,
    hidden: true,
  },
  physics: {
    enabled: false,
  },
};
