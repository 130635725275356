import PropTypes from "prop-types";

import "../../styles/check.css";

const SearchForm = ({ handleSubmit, search, onChange, searchError, isValidTerm }) => {
  return (
    <form className="form-wrapper mt-3" onSubmit={handleSubmit}>
      <div className="search-wrapper form-group">
        <input
          type="text"
          value={search}
          placeholder="Company Name or Number"
          className={"input-search-form form-control input-form" + (searchError && "search-error")}
          onChange={onChange}
          autoFocus
        />
        <input
          type="submit"
          value="Submit"
          className={!isValidTerm ? "submit-search btn-validate search" : "search-btn btn-validate search"}
        />
        {searchError && <p className="name-validate-error">Search returned multiple possible values</p>}
      </div>
    </form>
  );
};

SearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  searchError: PropTypes.bool,
  isValidTerm: PropTypes.bool,
};

export default SearchForm;
