import { useState } from "react";
import React, { useEffect } from "react";
import QuoteComponent from "components/quote/quote";
import { GraphContentContainer } from "components/graphUI";
import { useIsShowFeaturedAppsBar } from "context/IsShowFeaturedAppsBarContext";
import { JSON_DATA } from "data/QuoteData";

const QuotePage = () => {
  const [isShowGraph, setIsShowGraph] = useState(false);
  const [graphData, setGraphData] = useState({});

  const { dispatch: dispatchIsShowFeaturedAppsBar, Action } = useIsShowFeaturedAppsBar();

  useEffect(() => {
    dispatchIsShowFeaturedAppsBar({ type: Action.HideAppsBar });

    return () => {
      dispatchIsShowFeaturedAppsBar({ type: Action.ShowAppsBar });
    };
  }, []);

  return isShowGraph ? (
    <QuoteComponent graphData={graphData} isAddControllerNodes={false} />
  ) : (
    <GraphContentContainer
      setIsShowGraph={setIsShowGraph}
      setGraphData={setGraphData}
      defaultValue={JSON_DATA}
      isCheckChild={false}
    />
  );
};

export default QuotePage;
