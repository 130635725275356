import React from "react";
import ErrorMessage from "./ErrorMessage";
import PropTypes from "prop-types";

function ErrorFallback({ error }) {
  return (
    <ErrorMessage
      error={error}
      css={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
};

export default ErrorFallback;
