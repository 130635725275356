export function tryParseJSONObject(jsonString) {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.error(e);
  }

  return false;
}

export default tryParseJSONObject;
