import React from "react";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import IconImg from "./IconImg";
import { IconContainer, ImageWrapper } from "./AppIcon.styles";

const AppIcon = ({ data }) => {
  let navigate = useNavigate();
  const { url, icon, hoverIcon, key, text } = data;
  return (
    <IconContainer onClick={() => navigate(url)} className="w-100">
      <ImageWrapper className="image-wrapper">
        <IconImg normalIcon={icon} hoverIcon={hoverIcon} />
        <p className="inner text-truncate">{text}</p>
      </ImageWrapper>
    </IconContainer>
  );
};

AppIcon.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AppIcon;
