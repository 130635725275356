import React, { useState } from "react";
import PropTypes from "prop-types";
import SearchDropdown from "./SearchDropdown";
import Select from "react-select";

const btnStyles = { minHeight: "38px", minWidth: "78px" };

const options = [
  { value: "client", label: "Client" },
  { value: "accountId", label: "Account ID" },
  { value: "requestId", label: "Request ID" },
];

const SearchContainer = ({ setIsShowGraph, setGraphData }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [category, setCategory] = useState({ value: "", label: "" });
  const [searchOptions, setSearchOptions] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const setGraphDataHandler = e => {
    e.preventDefault();

    if (!inputValue) {
      return setErrorMessage("Search is required field!");
    }

    setErrorMessage("");
    setIsShowGraph(true);
  };

  const resetData = isOptionsClear => {
    setSelectedValue(null);
    isOptionsClear && setSearchOptions(null);
    setInputValue("");
    setGraphData([]);
    setErrorMessage("");
  };

  return (
    <div>
      <form className="address-form-wrapper" onSubmit={setGraphDataHandler}>
        <Select
          options={options}
          placeholder="Select Category"
          onChange={e => {
            resetData(true);
            setCategory(e);
          }}
        />
        <br />
        <SearchDropdown
          inputValue={inputValue}
          setInputValue={setInputValue}
          setGraphData={setGraphData}
          setErrorMessage={setErrorMessage}
          category={category.value}
          searchOptions={searchOptions}
          setSearchOptions={setSearchOptions}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          resetData={resetData}
        />
        <button type="submit" className="mt-5 mx-auto text-center d-block btn-validate" style={btnStyles}>
          Submit
        </button>
        <div className="name-validate-error">{errorMessage}</div>
      </form>
    </div>
  );
};

SearchContainer.propTypes = {
  setIsShowGraph: PropTypes.func.isRequired,
  setGraphData: PropTypes.func.isRequired,
};

export default SearchContainer;
