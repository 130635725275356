import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { GraphContentContainer } from "components/graphUI";
import PercaysoGraph from "./PercaysoGraph";
import PercaysoCompanyGraph from "./PercaysoCompanyGraph";

const PercaysoGraphContainer = ({ graphDataObj, showCompanyGraph }) => {
  const [_isShowGraph, _setIsShowGraph] = useState(false);
  const [graphData, setGraphData] = useState(null);

  const showGraphComponent = () => {
    if (showCompanyGraph) {
      return <PercaysoCompanyGraph graphData={graphData} />;
    }

    return <PercaysoGraph graphData={graphData} />;
  };

  const showGraphContainerComponent = () => {
    return (
      <GraphContentContainer
        setIsShowGraph={_setIsShowGraph}
        setGraphData={setGraphData}
        defaultValue={showCompanyGraph ? graphDataObj : graphDataObj?.value}
        isCheckChild={false}
      />
    );
  };

  return _isShowGraph ? showGraphComponent() : showGraphContainerComponent();
};

PercaysoGraphContainer.propTypes = {
  graphDataObj: PropTypes.object.isRequired,
  showCompanyGraph: PropTypes.bool,
};

PercaysoGraphContainer.defaultProps = {
  showCompanyGraph: false,
};

export default PercaysoGraphContainer;
