import { SortableContext } from "@dnd-kit/sortable";
import React from "react";
import DroppableContainer from "./DroppableContainer";
import SortableItem from "./SortableItem";

export function renderContainers(
  containerId,
  isMultipleContainer,
  items,
  minimal,
  columns,
  scrollable,
  containerStyle,
  strategy,
  isSortingContainer,
  handle,
  getItemStyles,
  wrapperStyle,
  renderItem,
  getIndex,
) {
  if (!items[containerId]) return null;
  return (
    <DroppableContainer
      id={containerId}
      key={containerId}
      label={minimal ? undefined : `Column ${containerId}`}
      columns={columns}
      items={items[containerId]}
      scrollable={scrollable}
      style={containerStyle}
      unstyled={minimal}
      onRemove={() => {}}
      isMultipleContainer={isMultipleContainer}
    >
      <SortableContext items={items[containerId]} strategy={strategy}>
        {items[containerId].map((icon, index) => {
          if (!icon) return null;
          return (
            <SortableItem
              disabled={isSortingContainer}
              key={icon.id}
              id={icon.id}
              data={icon}
              index={index}
              handle={handle}
              style={getItemStyles}
              wrapperStyle={wrapperStyle}
              renderItem={renderItem}
              containerId={containerId}
              getIndex={getIndex}
            />
          );
        })}
      </SortableContext>
    </DroppableContainer>
  );
}
