/* eslint-disable react/prop-types */
import { InputForm } from "components/forms";
import { FieldsWrapper } from "components/lib";
import { config } from "config";
import React, { useEffect, useState } from "react";
import { client } from "utils/apiClient";
import { useDebouncedCallback } from "use-debounce";

const BrandsSearchInput = ({ setQuotes }) => {
  const [searchText, setSearchText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = async () => {
    try {
      const {
        carInsurancesQuotes: { apiUrl: API_URL },
      } = config;

      const url = searchText ? `${API_URL}?partnerName=${searchText}` : API_URL;

      const apiResponse = await client(url);
      setQuotes(apiResponse?.data);
    } catch (error) {
      setErrorMessage(error?.message);
    }
  };

  const debouncedFetchData = useDebouncedCallback(fetchData, 500);

  useEffect(() => {
    debouncedFetchData();
  }, [searchText]);

  return (
    <div className="mt-3 address-form-wrapper">
      <FieldsWrapper>
        <InputForm
          placeholder="Search"
          type={"text"}
          value={searchText}
          handleChange={event => setSearchText(event.target.value)}
          autoFocus={true}
        />
      </FieldsWrapper>
      <div className="name-validate-error">{errorMessage}</div>
    </div>
  );
};

export default BrandsSearchInput;
