/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";
import { StyledComponent } from "./Container.styles";

export const Container = forwardRef(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      ...props
    },
    ref,
  ) => {
    return (
      <StyledComponent
        {...props}
        ref={ref}
        style={{
          ...style,
          "--columns": columns,
        }}
        unstyled={unstyled}
        horizontal={horizontal}
        hover={hover}
        placeholder={placeholder}
        scrollable={scrollable}
        shadow={shadow}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {placeholder ? children : <ul>{children}</ul>}
      </StyledComponent>
    );
  },
);

Container.displayName = "Container";
