import company from "../assest/App-icon/company-up.svg";
import companyOver from "../assest/App-icon/company-over.svg";
import address from "../assest/App-icon/address-up.svg";
import addressOver from "../assest/App-icon/address-over.svg";
import DL from "../assest/App-icon/drivinglicence-up.svg";
import DLOver from "../assest/App-icon/drivinglicence-over.svg";
import name from "../assest/App-icon/name-up.svg";
import nameOver from "../assest/App-icon/name-down.svg";
import quoteZone from "../assest/App-icon/quote_zone.png";
import directLine from "../assest/App-icon/direct-line-logo.png";
import moneySuperMarket from "../assest/App-icon/money-super-market.svg";
import confusedLogo from "../assest/App-icon/confused-logo.png";
import yellLogo from "../assest/App-icon/yell-logo.png";
import goCompareLogo from "../assest/App-icon/gocompare.jpg";
import compareTheMarket from "../assest/App-icon/compare-the-market.png";
import companyInfomationOver from "../assest/App-icon/company-information.svg";
import thomson from "../assest/App-icon/thomson.png";
import { v4 as uuidv4 } from "uuid";
import percaysoIcon from "../assest/graph/percayso-red.png";
/**
 * ! not use index 0-9. Somehow icons not moving correctly if we use 0-9 index number.
 */
export const iconsData = [
  {
    key: uuidv4(),
    id: 10,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 11,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 12,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 13,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 14,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 15,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 16,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 17,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 18,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 19,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 20,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 21,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 22,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 23,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 24,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 25,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 26,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 27,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 28,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 29,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 30,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 31,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 32,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 33,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 34,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 35,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 36,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 37,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 38,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 39,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 40,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 41,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 42,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 43,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 44,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 45,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 46,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 47,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 48,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 49,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 50,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 51,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 52,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 53,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 54,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 55,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 56,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 57,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 58,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 59,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 60,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 61,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 62,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 63,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 64,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 65,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 66,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 67,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 68,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 69,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 70,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 71,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 72,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 73,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 74,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 75,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 76,
    icon: company,
    hoverIcon: companyOver,
    text: "Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 77,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
];

export const graphBoxIconsData = [
  {
    key: uuidv4(),
    id: 79,
    icon: name,
    hoverIcon: nameOver,
    text: "Death Star",
    url: "/graph",
  },
  {
    key: uuidv4(),
    id: 80,
    icon: name,
    hoverIcon: nameOver,
    text: "Quote",
    url: "/quote",
  },
  {
    key: uuidv4(),
    id: 80,
    icon: name,
    hoverIcon: nameOver,
    text: "Search",
    url: "/search",
  },
  {
    key: uuidv4(),
    id: 81,
    icon: name,
    hoverIcon: nameOver,
    text: "Cone",
    url: "/cone",
  },
];

export const boxRobotIconsData = [
  {
    key: uuidv4(),
    id: 82,
    icon: companyInfomationOver,
    hoverIcon: companyInfomationOver,
    text: "Validate Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 87,
    icon: yellLogo,
    hoverIcon: yellLogo,
    text: "Yell Validate Company",
    url: "/validate-company-yell",
  },
  {
    key: uuidv4(),
    id: 83,
    icon: quoteZone,
    hoverIcon: quoteZone,
    text: "QuoteZone",
    url: "/get-quotes-from-quote-zone",
  },
  {
    key: uuidv4(),
    id: 84,
    icon: directLine,
    hoverIcon: directLine,
    text: "DirectLine",
    url: "/get-quotes-from-direct-line",
  },
  {
    key: uuidv4(),
    id: 85,
    icon: moneySuperMarket,
    hoverIcon: moneySuperMarket,
    text: "MoneySuperMarket",
    url: "/get-quotes-from-money-supermarket",
  },
  {
    key: uuidv4(),
    id: 86,
    icon: confusedLogo,
    hoverIcon: confusedLogo,
    text: "Confused",
    url: "/get-quotes-from-confused",
  },
  {
    key: uuidv4(),
    id: 87,
    icon: goCompareLogo,
    hoverIcon: goCompareLogo,
    text: "GoCompare",
    url: "/get-quotes-from-go-compare",
  },
  {
    key: uuidv4(),
    id: 88,
    icon: compareTheMarket,
    hoverIcon: compareTheMarket,
    text: "CompareTheMarket",
    url: "/get-quotes-from-compare-the-market",
  },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "Car Quotes",
  //   url: "/get-car-quotes",
  // },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "Bike Quotes",
  //   url: "/get-bike-quotes",
  // },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "House Quotes",
  //   url: "/get-house-quotes",
  // },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "Van Quotes",
  //   url: "/get-van-quotes",
  // },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "Travel Quotes",
  //   url: "/get-travel-quotes",
  // },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "Pet Quotes",
  //   url: "/get-pet-quotes",
  // },
  {
    key: uuidv4(),
    id: 89,
    icon: goCompareLogo,
    hoverIcon: goCompareLogo,
    text: "Go Compare Private Car",
    url: "/go-compare-services",
  },
];

export const businessRobotsIconsData = [
  {
    key: uuidv4(),
    id: 1,
    icon: companyInfomationOver,
    hoverIcon: companyInfomationOver,
    text: "Validate Company",
    url: "/validate-company",
  },
  {
    key: uuidv4(),
    id: 2,
    icon: yellLogo,
    hoverIcon: yellLogo,
    text: "Yell Validate Company",
    url: "/validate-company-yell",
  },
  {
    key: uuidv4(),
    id: 3,
    icon: thomson,
    hoverIcon: thomson,
    text: "Thomson",
    url: "/scrape-business-details",
  },
];

export const comparisonRobotsIconsData = [
  {
    key: uuidv4(),
    id: 83,
    icon: quoteZone,
    hoverIcon: quoteZone,
    text: "QuoteZone",
    url: "/get-quotes-from-quote-zone",
  },
  {
    key: uuidv4(),
    id: 84,
    icon: directLine,
    hoverIcon: directLine,
    text: "DirectLine",
    url: "/get-quotes-from-direct-line",
  },
  {
    key: uuidv4(),
    id: 85,
    icon: moneySuperMarket,
    hoverIcon: moneySuperMarket,
    text: "MoneySuperMarket",
    url: "/get-quotes-from-money-supermarket",
  },
  {
    key: uuidv4(),
    id: 86,
    icon: confusedLogo,
    hoverIcon: confusedLogo,
    text: "Confused",
    url: "/get-quotes-from-confused",
  },
  {
    key: uuidv4(),
    id: 87,
    icon: goCompareLogo,
    hoverIcon: goCompareLogo,
    text: "GoCompare",
    url: "/get-quotes-from-go-compare",
  },
  {
    key: uuidv4(),
    id: 88,
    icon: compareTheMarket,
    hoverIcon: compareTheMarket,
    text: "CompareTheMarket",
    url: "/get-quotes-from-compare-the-market",
  },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "Car Quotes",
  //   url: "/get-car-quotes",
  // },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "Bike Quotes",
  //   url: "/get-bike-quotes",
  // },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "House Quotes",
  //   url: "/get-house-quotes",
  // },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "Van Quotes",
  //   url: "/get-van-quotes",
  // },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "Travel Quotes",
  //   url: "/get-travel-quotes",
  // },
  // {
  //   key: uuidv4(),
  //   id: 83,
  //   icon: companyInfomationOver,
  //   hoverIcon: companyInfomationOver,
  //   text: "Pet Quotes",
  //   url: "/get-pet-quotes",
  // },
  {
    key: uuidv4(),
    id: 89,
    icon: goCompareLogo,
    hoverIcon: goCompareLogo,
    text: "Go Compare Private Car",
    url: "/go-compare-services",
  },
  {
    key: uuidv4(),
    id: 90,
    icon: confusedLogo,
    hoverIcon: confusedLogo,
    text: "Car Insurance",
    url: "/confused-car-insurance",
  },
  {
    key: uuidv4(),
    id: 91,
    icon: address,
    hoverIcon: addressOver,
    text: "Search Brands",
    url: "/search-brands",
  },
];

export const boxRobotTwoIconsData = [
  {
    key: uuidv4(),
    id: 1,
    groupIcon: [...businessRobotsIconsData],
    text: "Business Robots",
    url: "/business-robots",
  },
  {
    key: uuidv4(),
    id: 2,
    groupIcon: [...comparisonRobotsIconsData],
    text: "Comparison Robots",
    url: "/comparison-robots",
  },
];

export const testHarnessBoxIconsData = [
  {
    key: uuidv4(),
    id: 10,
    icon: name,
    hoverIcon: nameOver,
    text: "Name",
    url: "/validate-name",
  },
  {
    key: uuidv4(),
    id: 87,
    icon: name,
    hoverIcon: nameOver,
    text: "Match Name",
    url: "/match-name",
  },
  {
    key: uuidv4(),
    id: 11,
    icon: address,
    hoverIcon: addressOver,
    text: "Address",
    url: "/validate-address",
  },
  {
    key: uuidv4(),
    id: 13,
    icon: DL,
    hoverIcon: DLOver,
    text: "Driving License",
    url: "/validate-dln",
  },
  {
    key: uuidv4(),
    id: 78,
    icon: name,
    hoverIcon: nameOver,
    text: "Telephone",
    url: "/validate-tel",
  },
  {
    key: uuidv4(),
    id: 78,
    icon: name,
    hoverIcon: nameOver,
    text: "Email",
    url: "/validate-email",
  },
  {
    key: uuidv4(),
    id: 78,
    icon: name,
    hoverIcon: nameOver,
    text: "MultiBox",
    url: "/multi-box",
  },
];

export const businessDetailsBoxIconsData = [
  {
    key: uuidv4(),
    id: 2,
    icon: company,
    hoverIcon: companyOver,
    text: "Search Companies",
    url: "/search-companies",
  },
  {
    key: uuidv4(),
    id: 3,
    icon: percaysoIcon,
    hoverIcon: percaysoIcon,
    text: "Percayso",
    url: "/percayso",
  },
  {
    key: uuidv4(),
    id: 4,
    icon: company,
    hoverIcon: companyOver,
    text: "Kibana",
    url: "/kibana",
  },
];

export const percaysoBoxOneIconsData = [
  {
    id: 1,
    isCombinedEnabled: true,
    folderName: "Company",
    data: [
      {
        key: uuidv4(),
        id: 1,
        icon: company,
        hoverIcon: companyOver,
        text: "Kibana Chart",
        url: "/chart-1",
      },
    ],
  },
  {
    key: uuidv4(),
    id: 2,
    icon: company,
    hoverIcon: companyOver,
    text: "Summary",
    url: "/summary",
  },
  {
    key: uuidv4(),
    id: 3,
    icon: company,
    hoverIcon: companyOver,
    text: "Pie chart",
    url: "/pie-chart",
  },
];

export const goCompareBoxIconsData = [
  {
    id: 1,
    isCombinedEnabled: true,
    folderName: "Insurances",
    data: [
      {
        key: uuidv4(),
        id: 1,
        icon: company,
        hoverIcon: companyOver,
        text: "Car Insurance",
        url: "/car-insurance",
      },
    ],
  },
];
