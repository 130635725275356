import { useReducer } from "react";

// initial state
export const initialFolderModalState = { items: [] };

export const folderModalReducer = (state, action) => {
  switch (action.type) {
    case "ADD_MODAL_ITEMS":
      return { items: [...state.items, ...action.payload] };
    case "REMOVE_MODAL_ITEMS":
      return initialFolderModalState;
    default:
      throw new Error();
  }
};
