import { config } from "config";
import React, { useContext, useState } from "react";
import { client } from "utils/apiClient";

const PercaysoDataTableContext = React.createContext();

// eslint-disable-next-line react/prop-types
export const PercaysoDataTableProvider = ({ children }) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [isShowSearch, setIsShowSearch] = useState(true);

  const [isRefreshBtnLoading, setIsRefreshBtnLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isShowGraph, setIsShowGraph] = useState(false);
  const [isShowCompanyGraph, setIsShowCompanyGraph] = useState(false);
  const [companyGraphData, setCompanyGraphData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState([]);

  const [isShowSearchIcon, setIsShowSearchIcon] = useState(false);

  const handleRefreshBtn = async () => {
    setIsRefreshBtnLoading(true);
    try {
      const {
        percayso: { apiUrl: API_URL },
      } = config;
      await client(`${API_URL}/refresh`);
    } finally {
      setIsRefreshBtnLoading(false);
      setDefaultOptions([]);
    }
  };

  return (
    <PercaysoDataTableContext.Provider
      value={{
        isLoading,
        isShowSearch,
        setIsShowSearch,
        selectedOption,
        setSelectedOption,
        searchInputValue,
        setSearchInputValue,
        handleRefreshBtn,
        isRefreshBtnLoading,
        setIsLoading,
        defaultOptions,
        setDefaultOptions,
        companyGraphData,
        setCompanyGraphData,
        loading,
        setLoading,
        isShowCompanyGraph,
        setIsShowCompanyGraph,
        isShowGraph,
        setIsShowGraph,
        isShowSearchIcon,
        setIsShowSearchIcon,
        apiResponse,
        setApiResponse,
      }}
    >
      {children}
    </PercaysoDataTableContext.Provider>
  );
};

PercaysoDataTableProvider.displayName = "PercaysoDataTableProvider";

export function usePercaysoDataTable() {
  const {
    isLoading,
    isShowSearch,
    setIsShowSearch,
    selectedOption,
    setSelectedOption,
    searchInputValue,
    setSearchInputValue,
    handleRefreshBtn,
    isRefreshBtnLoading,
    setIsLoading,
    defaultOptions,
    setDefaultOptions,
    companyGraphData,
    setCompanyGraphData,
    loading,
    setLoading,
    isShowCompanyGraph,
    setIsShowCompanyGraph,
    isShowGraph,
    setIsShowGraph,
    isShowSearchIcon,
    setIsShowSearchIcon,
    apiResponse,
    setApiResponse,
  } = useContext(PercaysoDataTableContext);

  return {
    isLoading,
    isShowSearch,
    setIsShowSearch,
    selectedOption,
    setSelectedOption,
    searchInputValue,
    setSearchInputValue,
    handleRefreshBtn,
    isRefreshBtnLoading,
    setIsLoading,
    defaultOptions,
    setDefaultOptions,
    companyGraphData,
    setCompanyGraphData,
    loading,
    setLoading,
    isShowCompanyGraph,
    setIsShowCompanyGraph,
    isShowGraph,
    setIsShowGraph,
    isShowSearchIcon,
    setIsShowSearchIcon,
    apiResponse,
    setApiResponse,
  };
}
