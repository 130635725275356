import React, { useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { GraphWrapper } from "./AddressGraph.styles";
import homeIcon from "./../../../assest/addressGraph/home.png";
import crossIcon from "./../../../assest/addressGraph/cross.png";
import "./AddressGraph.css";

const AddressGraph = ({ graphData: graphDataObj, setIsShowJSONField, handleAnother }) => {
  const graphData = JSON.parse(JSON.stringify(graphDataObj));
  const domNode = useRef(null);
  const network = useRef(null);
  const options = {
    autoResize: true,
    height: "100%",
    width: "100%",
    nodes: {
      borderWidth: 0,
      borderWidthSelected: 0,
      font: { color: "white", align: "left", size: 20, background: "transparent" },
      color: "transparent",
      shadow: {
        enabled: false,
        size: 0,
      },
      shape: "image",

      size: 35,
      scaling: {
        min: 10,
        max: 10,
      },
    },
    edges: {
      color: "black",
      length: 400,
      selectionWidth: 0,
      width: 3,
    },
  };

  const nodes = new DataSet([]);
  const edges = new DataSet([]);

  const data = {
    nodes,
    edges,
  };

  const showOthersChild = data => {
    // Check if data is present
    if (!data) return;

    // Create an array of entries from the data object and filter out any entries that have a value of type "object"
    let dataArr = Object.entries(data).filter((node, index) => typeof node[1] !== "object");

    // Create a string from the filtered data array
    const text = `${dataArr.map(node => {
      // Check if the key and value are not empty
      if (node[0] !== "" && node[1] !== "") {
        // Add the key and value to the string, separated by a colon and followed by a newline and "______"
        return `${node[0]} : ${node[1]} \n______`;
      }
    })}`;

    // Check if the text variable is not empty
    if (!text) return;

    // Update the node's title with the text, removing any multiple "______," that may be present
    nodes.updateOnly({
      id: 0,
      title: text.replace(/______(,{1,})/g, "").replace("______", ""),
    });
  };

  const getIcon = label => {
    switch (label) {
      case "ERROR":
        return crossIcon;
      case "NO MATCH":
        return crossIcon;
      default:
        return homeIcon;
    }
  };

  const getLabel = data => {
    switch (data.status) {
      case "VALID":
        return `${graphData.inputAddress} → ${data?.addressLine2} ${data?.addressLine3} ${data?.addressLine4}`;
      //pick list
      case undefined:
        return `${graphData.postcode} → ${data?.address}`;

      default:
        return graphData.inputAddress;
    }
  };

  useEffect(() => {
    nodes.add({
      id: 0,
      label: getLabel(graphData),
      image: getIcon(graphData.status),
      shape: "image",
    });

    edges.add({
      from: 0,
      to: 1,
      length: 400,
    });

    showOthersChild({ ...graphData });

    network.current = new Network(domNode.current, data, options);
  }, [data]);

  return (
    <div className="w-100 h-100 validate-address-graph">
      <GraphWrapper ref={domNode} />
      <div className="d-flex justify-content-center align-items-center flex-wrap">
        <button
          className="btn-validate mx-1"
          onClick={() => {
            setIsShowJSONField(true);
          }}
        >
          View JSON
        </button>
        <button className="btn-validate mx-1" onClick={handleAnother}>
          Another
        </button>
      </div>
    </div>
  );
};

AddressGraph.propTypes = {
  graphData: PropTypes.object.isRequired,
  setIsShowJSONField: PropTypes.func.isRequired,
  handleAnother: PropTypes.func.isRequired,
};

export default AddressGraph;
