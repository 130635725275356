import React from "react";
import { graphBoxIconsData, iconsData } from "data/iconsData";
import SliderBox from "components/quote/SliderBox";

const pageTitle = "Graphs";
const boxTitle = "Graphs";

const Graphs = () => {
  return <SliderBox pageTitle={pageTitle} icons={graphBoxIconsData} boxTitle={boxTitle} />;
};

export default Graphs;
