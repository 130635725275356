import styled from "@emotion/styled";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
`;

export const Main = styled.main`
  width: 100%;
`;
