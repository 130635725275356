import styled from "@emotion/styled";

export const IconContainer = styled.div`
  color: var(--white);
  text-decoration: none;

  &:hover {
    color: var(--white);
    text-decoration: none;
  }
`;

export const ImageWrapper = styled.div`
  width: 80%;
  max-width: 64px;
  min-width: 52px;
  margin: auto;
  @media only screen and (min-width: 481px) and (max-width: 992px) {
    max-width: 75px;
    min-width: 70px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
