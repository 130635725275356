import React from "react";
import PropTypes from "prop-types";
import SearchGraphComponent from "./SearchGraphComponent";

const SearchGraph = ({ graphData }) => {
  return <SearchGraphComponent graphData={graphData} />;
};

SearchGraph.propTypes = {
  graphData: PropTypes.object.isRequired,
};

export default SearchGraph;
