import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, TextAreaContainer, TextArea } from "./GraphContentContainer.styles";

const GraphContentContainer = ({
  setIsShowJSONField,
  setIsShowGraph,
  setGraphData,
  defaultValue,
  isCheckChild,
  isBtnDisabled,
}) => {
  const [isShowError, setIsShowError] = useState(false);
  const [isShowInvalidJsonError, setIsShowInvalidJsonError] = useState(false);
  const [isShowInvalidJsonErrMsg, setIsShowInvalidJsonErrMsg] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(stringify(defaultValue));

  const setGraphDataHandler = () => {
    setIsShowError(false);
    setIsShowInvalidJsonError(false);
    setIsShowInvalidJsonErrMsg(false);
    if (!textAreaValue) {
      setIsShowError(true);
      return;
    }

    const data = tryParseJSONObject(textAreaValue);

    if (!data) {
      setIsShowInvalidJsonError(true);
      return;
    }

    // const checkResult = checkJsonIsValid(data);
    const checkResult = false;

    if (checkResult !== false) {
      setIsShowInvalidJsonErrMsg(checkResult);
      return;
    }

    if (isCheckChild && parse(textAreaValue).child.length > 8) {
      setIsShowInvalidJsonErrMsg("You can add maximum 8 line of 2nd level child !");
      return;
    }
    setGraphData(data);
    if (setIsShowJSONField) setIsShowJSONField(false);
    if (setIsShowGraph) setIsShowGraph(true);
  };

  return (
    <Container className="w-100 d-flex justify-content-center pb-5">
      <TextAreaContainer className="text-center">
        <TextArea
          className="w-100 h-100 rounded-3 p-2 form-control"
          placeholder="Data"
          value={(textAreaValue && textAreaValue) || ""}
          onChange={event => setTextAreaValue(event.target.value)}
        />
        {isShowError && <p className="name-validate-error">The graph data is required field!</p>}
        {isShowInvalidJsonError && <p className="name-validate-error">Invalid JSON format.</p>}
        {isShowInvalidJsonErrMsg && <p className="name-validate-error">{isShowInvalidJsonErrMsg}</p>}
        <button
          className="mt-3 mx-auto text-center d-block btn-validate"
          onClick={() => {
            setGraphDataHandler();
          }}
          disabled={isBtnDisabled}
        >
          View Graph
        </button>
      </TextAreaContainer>
    </Container>
  );
};

GraphContentContainer.propTypes = {
  setIsShowJSONField: PropTypes.func,
  setIsShowGraph: PropTypes.func,
  setGraphData: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isCheckChild: PropTypes.bool,
  isBtnDisabled: PropTypes.bool,
};

GraphContentContainer.defaultProps = {
  isCheckChild: true,
  isBtnDisabled: false,
  setIsShowJSONField: null,
  setIsShowGraph: null,
};

export default GraphContentContainer;

function tryParseJSONObject(jsonString) {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.error(e);
  }

  return false;
}

function stringify(data) {
  return JSON.stringify(data, undefined, 3);
}

function parse(data) {
  return JSON.parse(data);
}

function checkJsonIsValid(data) {
  let err = false;
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (node?.id === undefined) {
      err = `Can not find node id at in index ${i}`;
    }

    if (node?.x === undefined) {
      err = `Can not find node x value at in index ${i}`;
    }

    if (node?.y === undefined) {
      err = `Can not find node y value at in index ${i}`;
    }
  }
  return err;
}
