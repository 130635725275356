import { GraphContentContainer } from "components/graphUI";
import React, { useState } from "react";
import { PropTypes } from "prop-types";
import DlnGraph from "./DlnGraph";

const ValidateDlnGraph = ({ apiResponse, handleAnother }) => {
  const [isShowJSONField, setIsShowJSONField] = useState(true);
  const [graphData, setGraphData] = useState(null);
  return !isShowJSONField ? (
    <DlnGraph graphData={graphData} setIsShowJSONField={setIsShowJSONField} handleAnother={handleAnother} />
  ) : (
    <GraphContentContainer
      setIsShowJSONField={setIsShowJSONField}
      setGraphData={setGraphData}
      defaultValue={apiResponse}
      isCheckChild={false}
    />
  );
};

ValidateDlnGraph.propTypes = {
  apiResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  handleAnother: PropTypes.func.isRequired,
};

export default ValidateDlnGraph;
