/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import CloseBtn from "./CloseBtn";

const Modal = ({ title, showModal, handleModalClose, children }) => {
  return (
    <>
      {showModal ? (
        <div className="card_modal">
          <div className="card_modal_scroll_area">
            <div className="card_modal_header">
              <h4 className="card_modal_title">{title}</h4>
              <CloseBtn onClose={handleModalClose} />
            </div>
            <div className="card_modal_content">{children}</div>
            <div className="card_modal_footer">
              <Button variant="outline-dark" onClick={handleModalClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
