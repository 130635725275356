/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Button } from "react-bootstrap";
import { CompanyContext } from "pages/Companies";
import { client } from "utils/apiClient";
import { Loader } from "components/lib";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const categoryColumns = [
  {
    dataField: "category",
    text: "Category Name",
  },
  {
    dataField: "count",
    text: "Companies Count",
  },
];

const companiesColumns = [
  {
    dataField: "_source.name",
    text: "Company Name",
  },

  {
    dataField: "_source.category",
    text: "Category Name",
  },
];

const CategoryTable = props => {
  const {
    categoriesApiResponse,
    isShowCompanyTable,
    setIsShowCompanyTable,
    selectedCategoryName,
    setSelectedCategoryName,
    setSearchTerm,
  } = useContext(CompanyContext);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companiesApiResponse, setCompaniesApiResponse] = useState([]);

  const { SearchBar } = Search;

  const fetchCompaniesUsingCategory = async (query, isStrict) => {
    const apiResponse = await client(
      `${process.env.REACT_APP_ELASTIC_SEARCH_URL}/business-details/?category=${query}&strict=${isStrict}`,
    );
    return apiResponse?.hits?.hits;
  };

  const getCompaniesDataUsingSelectedRow = async mounted => {
    if (selectedRow && mounted) {
      setLoading(true);
      const data = await fetchCompaniesUsingCategory(encodeURIComponent(selectedRow?.category), false);
      setCompaniesApiResponse(data);
      setLoading(false);
    }
  };

  const getCompaniesDataUsingCategory = async mounted => {
    if (selectedCategoryName && mounted) {
      setLoading(true);
      const data = await fetchCompaniesUsingCategory(encodeURIComponent(selectedCategoryName), true);
      setCompaniesApiResponse(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    getCompaniesDataUsingSelectedRow(mounted);
    return () => {
      mounted = false;
    };
  }, [selectedRow]);

  useEffect(() => {
    let mounted = true;
    getCompaniesDataUsingCategory(mounted);
    return () => {
      mounted = false;
    };
  }, [selectedCategoryName]);

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="position-absolute top-50 start-50">
            <Loader width="3rem" height="3rem" />
          </div>
        </div>
      ) : null}
      {isShowCompanyTable ? (
        <div>
          <button
            className="btn-validate"
            onClick={() => {
              if (selectedCategoryName) {
                setSearchTerm("companies");
                setSelectedCategoryName("");
              }
              setIsShowCompanyTable(false);
            }}
          >
            Go Back
          </button>
          <ToolkitProvider keyField="_id" data={companiesApiResponse} columns={companiesColumns} search>
            {props => (
              <div>
                <SearchBar
                  placeholder="Search"
                  {...props.searchProps}
                  className="input-form form-control mb-2 ms-auto"
                  style={{ background: "#ae587a", height: "2.9rem", fontSize: "1rem" }}
                  srText=""
                />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  pagination={paginationFactory({ sizePerPage: 10 })}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      ) : (
        <ToolkitProvider
          keyField="id"
          data={categoriesApiResponse}
          columns={categoryColumns}
          search={{ searchFormatted: true }}
        >
          {props => (
            <div>
              <SearchBar
                placeholder="Search"
                {...props.searchProps}
                className="input-form form-control mb-2 ms-auto"
                style={{ background: "#ae587a", height: "2.9rem", fontSize: "1rem" }}
                srText=""
              />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory({ sizePerPage: 10 })}
                striped
                hover
                selectRow={{
                  mode: "checkbox",
                  clickToSelect: true,
                  bgColor: "#ae587a",
                  selected: selectedIndex,
                  onSelect: (row, isSelect) => {
                    if (!isSelect) {
                      setSelectedIndex([]);
                      setSelectedRow(null);
                      return;
                    }
                    setSelectedIndex([row.id]);
                    setSelectedRow(row);
                    setIsShowCompanyTable(true);
                  },
                }}
              />
            </div>
          )}
        </ToolkitProvider>
      )}
    </div>
  );
};

CategoryTable.propTypes = {};

export default CategoryTable;
