import React from "react";
import PropTypes from "prop-types";

const TableCell = ({ name, count }) => (
  <div className="d-flex justify-content-between align-item-center px-5">
    <p className="m-0 mb-2">{name}</p>
    <p className="m-0 mb-2">{count}</p>
  </div>
);

TableCell.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

export default TableCell;
