import React, { useRef } from "react";
import { useFolderModal } from "context/FolderModalContext";
import { useOnClickOutside } from "usehooks-ts";
import IconImg from "./IconImg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import arrowIcon from "assest/App-icon/arrow-icon.svg";
import "styles/home.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const FolderContent = () => {
  const { folderContentArr, dispatchFolderContent, setIsFolderModalShow, folderName } = useFolderModal();

  const outsideClickRef = useRef(null);

  const navigate = useNavigate();

  const outSideClickHandler = () => {
    setIsFolderModalShow(false);
    dispatchFolderContent({ type: "REMOVE_MODAL_ITEMS" });
  };

  useOnClickOutside(outsideClickRef, outSideClickHandler);

  return (
    <div style={{ position: "relative" }} className="home_container my-2">
      <div className="home-wrapper" ref={outsideClickRef}>
        <div className="title-wrapper">{folderName ? <h1>{folderName}</h1> : null}</div>
        <div className="slider-wrapper">
          <Swiper
            modules={[Pagination, Navigation]}
            navigation={{
              nextEl: ".button-next",
              prevEl: ".button-prev",
            }}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true,
            }}
            spaceBetween={50}
            slidesPerView={1}
          >
            <SwiperSlide>
              <div className="icon-wrapper">
                {folderContentArr.map(iconData => {
                  const { key, icon, hoverIcon, text, url } = iconData;
                  return (
                    <div className="icons" key={key}>
                      <Link className="icon-link" to={url}>
                        <div className="image-wrapper">
                          <IconImg normalIcon={icon} hoverIcon={hoverIcon} />
                        </div>
                        <p className="inner multine-ellipsis ">{text}</p>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="arrow_icons_container">
        <i className="fa fa-angle-left icon1 button-prev"></i>

        <img className="icons-jimg icon2" src={arrowIcon} alt="name" onClick={() => navigate(-1)} />

        <i className="fa fa-angle-right icon3 button-next"></i>
      </div>
      <div className="swiper-pagination"></div>
    </div>
  );
};

export default FolderContent;
