/* eslint-disable react/prop-types */
import React, { useMemo, memo } from "react";

import { styles } from "./ArcText.styles";

const ArcText = ({ text, characterWidth, upsideDown, children, className, textColor, ...props }) => {
  const characters = text.split("");

  const [arc, degree] = useMemo(() => {
    const arc = characters.length * characterWidth;
    const degree = arc / characters.length;
    return [arc, degree];
  }, [characters.length, characterWidth]);

  const radius = "radius" in props ? props.radius : props.width / 2;

  const arcText = useMemo(
    () =>
      characters.map((letter, index) => (
        <span
          key={index}
          className="character"
          style={{
            ...styles.character,
            height: `${radius}px`,
            transform: `rotate(${degree * index - arc / 2}deg)`,
            transformOrigin: `0 ${radius}px 0`,
            color: textColor,
          }}
        >
          {!upsideDown ? ( //
            letter
          ) : (
            <span
              className="upside-down"
              style={{
                ...styles.upsideDown,
                color: textColor,
              }}
            >
              {letter}
            </span>
          )}
        </span>
      )),
    [arc, degree, radius, upsideDown],
  );

  return (
    <>
      <div
        className={className}
        style={{
          ...styles.container,
          width: radius * 2,
          height: radius * 2,
          transform: !upsideDown ? "unset" : "rotateX(180deg)",
        }}
        {...props}
      >
        <div style={styles.absoluteContainer}>
          <span style={styles.text}>{arcText}</span>
        </div>
        {children}
      </div>
    </>
  );
};

export default memo(ArcText);
