// TODO: add more break point to handle icons quantity in different width & height

export const calculateIconQuantity = (width, height, setCount) => {
  // /if (height < 360) {
  //   setCount(4);
  // } else
  if (height < 450 && height > 360) {
    setCount(8);
  } else if (height < 552 && height > 450) {
    setCount(12);
  } else if (height < 750 && height > 667) {
    setCount(20);
  } else if (height <= 667) {
    setCount(16);
  } else if (width > 992) {
    setCount(24);
  } else if (width < 992 && width > 480) {
    setCount(25);
  } else {
    setCount(24);
  }
};
