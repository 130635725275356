/* eslint-disable react/prop-types */
import React from "react";

const FeesExcessesAndYourInfo = ({ title, onClick }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center cursor_pointer"
      style={{ height: 37 }}
      onClick={onClick}
    >
      <p className="m-0 my-1 fs-6">{title}</p>
      <span data-v-707f3db0="" className="moreinfo-your-info__icon">
        <svg version="1.1" viewBox="0 0 32 32" className="svg-icon svg-fill" style={{ width: 8, height: 8 }}>
          <defs></defs>
          <path
            fill="#D3DFD8"
            stroke="none"
            pid="0"
            d="M10 32a4 4 0 0 1-2.83-6.83L16.34 16 7.17 6.83a4 4 0 0 1 5.66-5.66l12 12a4 4 0 0 1 0 5.66l-12 12A4 4 0 0 1 10 32z"
          ></path>
        </svg>
      </span>
    </div>
  );
};

export default FeesExcessesAndYourInfo;
