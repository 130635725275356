import React, { useState } from "react";

// eslint-disable-next-line react/prop-types
const IconImg = ({ normalIcon, hoverIcon }) => {
  const [icon, setIcon] = useState(normalIcon);
  return (
    <img
      className="icons-img "
      src={icon}
      alt="icon"
      onMouseEnter={() => {
        setIcon(hoverIcon);
      }}
      onMouseLeave={() => {
        setIcon(normalIcon);
      }}
    />
  );
};

export default IconImg;
