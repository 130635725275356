import React, { useState } from "react";
import Meta from "components/layout/Meta";
import { Link, useNavigate, useRoutes } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import IconImg from "components/appIcon/IconImg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import arrowIcon from "assest/App-icon/arrow-icon.svg";
import PropTypes from "prop-types";
import "styles/home.css";
import AppIconCombine from "components/appIcon/AppIconCombine";
import GroupIcon from "../appIcon/GroupIcon";
import { IoIosSettings } from "react-icons/io";

const SliderBox = ({ pageTitle, icons, boxTitle, classes, showNavigateIcon = true }) => {
  const navigate = useNavigate();

  const [isModalShow, setIsModalShow] = useState(false);

  return (
    <div style={{ position: "relative" }} className={`${classes} home_container`}>
      <div className="home-wrapper">
        <Meta title={pageTitle} />
        <div className="title-wrapper">
          <h1>{boxTitle}</h1>
        </div>
        <div className="slider-wrapper">
          <Swiper
            modules={[Pagination, Navigation]}
            navigation={{
              nextEl: ".button-next",
              prevEl: ".button-prev",
            }}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true,
            }}
            spaceBetween={50}
            slidesPerView={1}
          >
            <SwiperSlide>
              <div className="icon-wrapper">
                {icons.map(iconData => {
                  if (iconData?.isCombinedEnabled) {
                    return (
                      <AppIconCombine
                        key={iconData?.id}
                        combineData={{ data: iconData?.data }}
                        isModalShow={isModalShow}
                        setIsModalShow={setIsModalShow}
                        folderName={iconData?.folderName}
                      />
                    );
                  }

                  if (iconData?.groupIcon) {
                    const { key, groupIcon, text, url } = iconData;
                    return (
                      <div className="icons" key={key}>
                        <Link className="icon-link" to={url}>
                          <GroupIcon groupIcon={groupIcon} text={text} />
                          <p className="inner multine-ellipsis ">{text}</p>
                        </Link>
                      </div>
                    );
                  }

                  const { key, icon, hoverIcon, text, url } = iconData;
                  return (
                    <div className="icons" key={key}>
                      <Link className="icon-link" to={url}>
                        <div className="image-wrapper">
                          <IconImg normalIcon={icon} hoverIcon={hoverIcon} />
                        </div>
                        <p className="inner multine-ellipsis ">{text}</p>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      {showNavigateIcon && (
        <div>
          <div className="arrow_icons_container">
            <i className="fa fa-angle-left icon1 button-prev"></i>

            <img className="icons-jimg icon2" src={arrowIcon} alt="name" onClick={() => navigate(-1)} />

            <i className="fa fa-angle-right icon3 button-next"></i>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      )}
    </div>
  );
};

SliderBox.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  icons: PropTypes.array.isRequired,
  boxTitle: PropTypes.string.isRequired,
  classes: PropTypes.string,
  showNavigateIcon: PropTypes.bool,
};

SliderBox.defaultProps = {
  classes: "",
};

export default SliderBox;
