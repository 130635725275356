import React from "react";

// eslint-disable-next-line
const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const handleHello = () => {
    const botMessage = createChatBotMessage("Hello. Nice to meet you.");

    setState(prev => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleWidget = () => {
    const botMessage = createChatBotMessage("Here's a nice dog picture for you!", { widget: "chatWidget" });

    setState(prev => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  // Put the handleHello function in the actions object to pass to the MessageParser
  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleWidget,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
