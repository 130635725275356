import React, { useEffect, useState } from "react";
import { Meta } from "components/layout";
import "../../styles/validateAddress.css";
import { API_URL_BY_QUOTE_TYPES, QUOTE_TYPES } from "../../config";
import { client } from "utils/apiClient";
import { Container, TextArea, TextAreaContainer } from "../../components/graphUI/GraphContentContainer.styles";
import { mockData } from "pages/QuotezoneServices/mock";
import tryParseJSONObject from "utils/tryParseJSONObject";
import { FieldsWrapper, Loader, LoadingButton } from "components/lib";
import Select from "react-select";

const options = [
  {
    value: QUOTE_TYPES.CAR_QUOTES,
    label: "Get Car Quotes",
    needQuery: true,
    api: API_URL_BY_QUOTE_TYPES.CAR_QUOTES,
  },
  // {
  //   value: QUOTE_TYPES.BIKE_QUOTES,
  //   label: "Get Bike Quotes",
  //   api: API_URL_BY_QUOTE_TYPES.BIKE_QUOTES,
  // },
  {
    value: QUOTE_TYPES.HOUSE_QUOTES,
    label: "Get House Quotes",
    needQuery: true,
    api: API_URL_BY_QUOTE_TYPES.HOUSE_QUOTES,
  },
  // {
  //   value: QUOTE_TYPES.PET_QUOTES,
  //   label: "Get Pet Quotes",
  //   api: API_URL_BY_QUOTE_TYPES.PET_QUOTES,
  // },
  // {
  //   value: QUOTE_TYPES.TRAVEL_QUOTES,
  //   label: "Get Travel Quotes",
  //   api: API_URL_BY_QUOTE_TYPES.TRAVEL_QUOTES,
  // },
  {
    value: QUOTE_TYPES.VAN_QUOTES,
    label: "Get Van Quotes",
    api: API_URL_BY_QUOTE_TYPES.VAN_QUOTES,
  },
];

function stringify(data) {
  return JSON.stringify(data, undefined, 3);
}

const GetQuotesFromQuoteZonePage = () => {
  const [sourceData, setSourceData] = useState(stringify(mockData));
  const [apiResponse, setApiResponse] = useState(null);
  const [source, setSource] = useState(options[0]);
  const [validationMessage, setValidationMessage] = useState("");
  const pageTitle = "Get Quotes From QuoteZone";
  const [isShowError, setIsShowError] = useState(false);
  const [isShowInvalidJsonError, setIsShowInvalidJsonError] = useState(false);
  const [isShowInvalidJsonErrMsg, setIsShowInvalidJsonErrMsg] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const getQuoteHandler = async () => {
    setIsShowError(false);
    setIsShowInvalidJsonError(false);
    setIsShowInvalidJsonErrMsg(false);
    if (!sourceData) {
      setIsShowError(true);
      return;
    }

    const data = tryParseJSONObject(sourceData);

    if (!data) {
      setIsShowInvalidJsonError(true);
      return;
    }

    // const checkResult = checkJsonIsValid(data);
    const checkResult = false;

    if (checkResult !== false) {
      setIsShowInvalidJsonErrMsg(checkResult);
      return;
    }
    setLoading(true);
    try {
      const apiResponse = await client(source?.api, {
        query: {
          source: "quote_zone",
        },
        data,
      });

      setApiResponse(apiResponse);
      setValidationMessage("");
      setIsShowInvalidJsonError(false);
      setIsShowInvalidJsonErrMsg("");
      setIsShowError(false);
      setShowRes(true);
    } catch (e) {
      setValidationMessage("Getting quotes from QuoteZone failed");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if ([QUOTE_TYPES.CAR_QUOTES].includes(source?.value)) {
  //     setQuery("FG62OOX");
  //   }
  //   if ([QUOTE_TYPES.HOUSE_QUOTES].includes(source?.value)) {
  //     setQuery("TN13 1YL");
  //   }
  // }, [source]);
  return (
    <div className="address-form-wrapper">
      <Meta title={pageTitle} />
      <div>
        {!showRes && (
          <>
            <FieldsWrapper>
              <div style={{ marginTop: "10px" }}>
                <Select
                  options={options}
                  placeholder="Select Category"
                  onChange={e => {
                    setSource(e);
                  }}
                  value={source}
                />
              </div>
            </FieldsWrapper>
            <Container className="w-100 d-flex justify-content-center pb-5 mt-2">
              <TextAreaContainer className="text-center">
                <TextArea
                  className="w-100 h-100 rounded-3 p-2 form-control"
                  placeholder="Data"
                  value={(sourceData && sourceData) || ""}
                  onChange={event => setSourceData(event.target.value)}
                />
                {isShowError && <p className="name-validate-error">The graph data is required field!</p>}
                {isShowInvalidJsonError && <p className="name-validate-error">Invalid JSON format.</p>}
                {isShowInvalidJsonErrMsg && <p className="name-validate-error">{isShowInvalidJsonErrMsg}</p>}
                <div className="btn-container">
                  <LoadingButton className="mt-3 mx-auto text-center d-block btn-validate" onClick={getQuoteHandler}>
                    {loading ? <Loader /> : "Submit"}
                  </LoadingButton>
                </div>
              </TextAreaContainer>
            </Container>
            <div className="name-validate-error">{validationMessage}</div>
          </>
        )}
        {apiResponse && showRes && (
          <TextArea
            className="w-100 h-100 rounded-3 p-2 form-control"
            style={{ minHeight: "800px" }}
            placeholder="Data"
            value={JSON.stringify(apiResponse, undefined, 3)}
            readOnly
          />
        )}
      </div>
    </div>
  );
};

export default GetQuotesFromQuoteZonePage;
