import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ContainerWrapper = styled.div`
  display: ${props => (props.isFolderModalShow ? "block" : "flex")};
  justify-content: center;
  flex-wrap: wrap;
  max-width: 992px;
  margin: auto;
  @media only screen and (min-width: 993px) {
    max-width: ${props => (!props.isMultipleContainer ? "310px" : "980px")};
    ${props =>
      !props.isMultipleContainer &&
      !props.isFolderModalShow &&
      css`
        border: 0.02rem solid rgb(190, 184, 184);
        border-radius: 8px;
      `}
  }
`;
