import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  grid-auto-rows: max-content;
  overflow: hidden;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  min-width: 350px;
  margin: 10px;
  border-radius: 5px;
  min-height: 200px;
  background-color: transparent;
  font-size: 1em;

  @media (min-width: 992px) {
    max-width: 310px;
    min-width: 310px;
    height: 590px;
    ${props =>
      props.isMultipleContainer &&
      css`
        border: 0.02rem solid rgb(190, 184, 184);
      `}
    border-radius: 8px;
    padding: 1.2rem 0 1.2rem 0;
    position: relative;
    margin: 0px 5px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ul {
    display: grid;
    grid-gap: 15px 0;
    grid-template-columns: repeat(var(--columns, 1), 1fr);
    list-style: none;
    padding: 1.2rem 0 1.2rem 0;
    margin: 0;
    justify-items: center;
    @media only screen and (min-width: 610px) and (max-width: 992px) {
      grid-gap: 2rem;
    }
    @media (max-width: 609px) {
      grid-gap: 2rem 20px;
    }
    @media (max-width: 480px) {
      grid-gap: 1rem 0px;
      padding: 10px 0 0;
    }
    user-select: none;
  }

  ${props =>
    props.scrollable &&
    css`
      ul {
        overflow-y: auto;
      }
    `}

  ${props =>
    props.placeholder &&
    css`
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);
      background-color: transparent;
      border-style: dashed;
      border-color: rgba(0, 0, 0, 0.08);
      &:hover {
        border-color: rgba(0, 0, 0, 0.15);
      }
    `}

  ${props =>
    props.hover &&
    css`
      background-color: transparent;
    `}

  ${props =>
    props.unstyled &&
    css`
      overflow: visible;
      background-color: transparent !important;
      border: none !important;
    `}

  ${props =>
    props.horizontal &&
    css`
      width: 100%;
      ul {
        grid-auto-flow: column;
      }
    `}

 ${props =>
    props.shadow &&
    css`
      box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
    `}

  &:focus-visible {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
  }
`;
