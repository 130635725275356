import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { GraphContentContainer } from "components/graphUI";
import TelephoneGraph from "./TelephoneGraph";

const ValidateTelephoneGraph = ({ apiResponse }) => {
  const [isShowGraph, setIsShowGraph] = useState(false);
  const [graphData, setGraphData] = useState(null);
  return isShowGraph ? (
    <TelephoneGraph graphData={graphData} setIsShowGraph={setIsShowGraph} />
  ) : (
    <GraphContentContainer
      setIsShowGraph={setIsShowGraph}
      setGraphData={setGraphData}
      defaultValue={apiResponse}
      isCheckChild={false}
    />
  );
};

ValidateTelephoneGraph.propTypes = {
  apiResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default ValidateTelephoneGraph;
