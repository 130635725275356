/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import classNames from "classnames";

import AppIconCombine from "components/appIcon/AppIconCombine";
import AppIcon from "components/appIcon/AppIcon";
import { StyledItem, WrapperLI } from "./Item.styles";

export const Item = React.memo(
  React.forwardRef(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        data,
        wrapperStyle,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);
      const [isModalShow, setIsModalShow] = useState(false);
      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
          data,
        })
      ) : (
        <WrapperLI
          fadeIn={fadeIn}
          sorting={sorting}
          dragOverlay={dragOverlay}
          style={{
            ...wrapperStyle,
            transition: [transition, wrapperStyle?.transition].filter(Boolean).join(", "),
            "--translate-x": transform ? `${Math.round(transform.x)}px` : undefined,
            "--translate-y": transform ? `${Math.round(transform.y)}px` : undefined,
            "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
            "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
            "--index": index,
            "--color": color,
          }}
          ref={ref}
        >
          <StyledItem
            dragging={dragging}
            handle={handle}
            dragOverlay={dragOverlay}
            disabled={disabled}
            color={color}
            style={style}
            className={classNames(dragOverlay && "dragOverlay", handle && "handle", dragging && "dragging")}
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            {data?.isCombineEnabled ? (
              <AppIconCombine combineData={data} isModalShow={isModalShow} setIsModalShow={setIsModalShow} />
            ) : (
              <AppIcon data={data} />
            )}
          </StyledItem>
        </WrapperLI>
      );
    },
  ),
);

Item.displayName = "Item";
