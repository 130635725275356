import React, { useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const DataTableContext = React.createContext(undefined);
DataTableContext.displayName = "DataTableContext";

export function DataTableProvider(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [status, setStatus] = useState({ loading: false, msg: "" });
  const [companies, setCompanies] = useState([]);
  const [searched, setSearched] = useState(false);
  const [officers, setOfficers] = useState([]);
  const [isValidTerm, setIsValidTerm] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [search, setSearch] = useState("");

  const pageSize = 10;

  const getAccessToken = async scope => {
    (async () => {
      try {
        setStatus({ loading: true, msg: "Getting access token..." });
        const token = await getAccessTokenSilently({
          audience: "",
          scope: scope,
        });
        const rawResponse = await fetch("", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const response = await rawResponse.json();
        setStatus({ loading: false, msg: "" });
        return response;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    })();
  };

  const handleSearch = async params => {
    const { auto = "" } = params;
    if (auto !== "auto") setSearched(false);
    setStatus({ loading: true, msg: "Searching..." });
    const response = await fetch(`${process.env.REACT_APP_API_URL}/search`);
    const data = await response.json();
    setCompanies(data);
    if (auto !== "auto") setSearched(true);
  };

  const fetchCompanies = async () => {
    setStatus({ loading: true, msg: "Fetching data..." });
    const response = await fetch(`${process.env.REACT_APP_API_URL}/companies`);
    const data = await response.json();
    setCompanies(data);
    setStatus({ loading: false, msg: "" });
    return { data };
  };

  const fetchOfficers = async companyId => {
    setStatus({ loading: true, msg: "Fetching data..." });
    const response = await fetch(`${process.env.REACT_APP_API_URL}/officers`);
    const data = await response.json();
    setOfficers(data);
    setStatus({ loading: false, msg: "" });
    return { data };
  };

  useEffect(() => {
    fetchCompanies();
    fetchOfficers();
  }, []);

  return (
    <DataTableContext.Provider
      value={{
        handleSearch,
        companies,
        status,
        getAccessToken,
        pageSize,
        searched,
        officers,
        isValidTerm,
        setIsValidTerm,
        searchError,
        setSearchError,
        showSuggestions,
        setShowSuggestions,
        search,
        setSearch,
      }}
      {...props}
    />
  );
}

export function useDataTable() {
  const context = useContext(DataTableContext);
  if (context === undefined) {
    throw new Error(`useDataTable must be used within a DataTableProvider`);
  }
  return context;
}
