/** @jsxImportSource @emotion/react */
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import * as colors from "styles/colors";

function NavLink(props) {
  return (
    <RouterLink
      css={[
        {
          display: "flex",
          padding: "8px 15px 8px 10px",
          margin: "5px 0",
          width: "100%",
          height: "100%",
          color: colors.base,
          borderRadius: "2px",
          borderLeft: "5px solid transparent",
        },
      ]}
      {...props}
    />
  );
}

export default NavLink;
