import React from "react";

import { useAuth } from "./context/AuthContext";
import AuthenticatedApp from "./routes/AuthenticatedApp";
import UnauthenticatedApp from "./routes/UnauthenticatedApp";

function App() {
  const { user } = useAuth();
  return user?.token ? <AuthenticatedApp /> : <UnauthenticatedApp />;
}

export { App };
