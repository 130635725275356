import React, { createContext, useEffect } from "react";
import "styles/companies.css";

import { MEDIA_QUERY } from "constants/general";
import { useMediaQuery } from "react-responsive";
import { goCompareBoxIconsData, percaysoBoxOneIconsData } from "data/iconsData";
import SliderBox from "components/quote/SliderBox";
import { useFolderModal } from "context/FolderModalContext";
import FolderContent from "components/appIcon/FolderContent";

export const PercaysoContext = createContext(null);

const GoCompareServices = () => {
  const pageTitle = "Go Compare Services";

  const isBigScreen = useMediaQuery({
    query: MEDIA_QUERY.BIG_SCREEN,
  });

  const { isFolderModalShow, setIsFolderModalShow, dispatchFolderContent } = useFolderModal();

  useEffect(() => {
    return () => {
      setIsFolderModalShow(false);
      dispatchFolderContent({ type: "REMOVE_MODAL_ITEMS" });
    };
  }, []);

  return (
    <>
      <div
        className={`w-100 h-100 ${isBigScreen ? "" : "min-vh-100"} d-flex justify-content-center align-items-center`}
      >
        <div className="mx-auto w-100">
          <div className={`d-flex ${isBigScreen ? "flex-row" : "flex-column"} flex-wrap justify-content-center`}>
            {isFolderModalShow ? (
              <FolderContent />
            ) : (
              <SliderBox pageTitle={pageTitle} icons={goCompareBoxIconsData} boxTitle="Go Compare" classes="my-2" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GoCompareServices;
