import React, { useContext, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { GraphWrapper } from "./CompanyGraph.styles";
import licenseImage from "./../../assest/companyGraph/license.png";
import flatten from "flat";
import { CompanyContext } from "pages/Companies";

const CompanyGraph = ({ graphData: graphDataObj, setIsShowGraph }) => {
  const { setIsShowSearch } = useContext(CompanyContext);
  const graphData = JSON.parse(JSON.stringify(graphDataObj));
  const domNode = useRef(null);
  const network = useRef(null);
  const options = {
    autoResize: true,
    height: "100%",
    width: "100%",
    nodes: {
      borderWidth: 0,
      borderWidthSelected: 0,
      font: { color: "white", align: "left", size: 20, background: "transparent" },
      color: "transparent",
      shadow: {
        enabled: false,
        size: 0,
      },
      shape: "image",
      fixed: {
        x: true,
        y: true,
      },
      size: 35,
      scaling: {
        min: 10,
        max: 10,
      },
    },
    edges: {
      color: "black",
      length: 80,
      selectionWidth: 0,
      width: 1,
    },
    physics: {
      enabled: false,
    },
  };

  const nodes = new DataSet([]);
  const edges = new DataSet([]);

  const data = {
    nodes,
    edges,
  };

  const showOthersChild = data => {
    if (!data) return;

    delete data.name;

    let dataArr = Object.entries(data).filter((node, index) => typeof node[1] !== "object");

    const text = `${dataArr.map(node => {
      if (node[0] !== "" && node[1] !== "") {
        return `${node[0]} : ${node[1]}\n`;
      }
    })}`;

    if (!text) return;

    nodes.updateOnly({
      id: 0,
      title: text.replace(/,/g, ""),
    });
  };

  useEffect(() => {
    nodes.add({
      id: 0,
      label: graphData?.name,
      image: graphData?.logo ? graphData?.logo : licenseImage,
      shape: "image",
    });

    showOthersChild(flatten({ ...graphData }));

    network.current = new Network(domNode.current, data, options);
  }, [data]);

  return (
    <div className="w-100 h-100">
      <GraphWrapper ref={domNode} />
      <div className="text-center">
        <button
          className="btn-validate"
          onClick={() => {
            setIsShowGraph(false);
            setIsShowSearch(true);
          }}
        >
          Another
        </button>
      </div>
    </div>
  );
};

CompanyGraph.propTypes = {
  graphData: PropTypes.object.isRequired,
  setIsShowGraph: PropTypes.func.isRequired,
};

export default CompanyGraph;
