import React, { useState } from "react";
import { Container, TextAreaContainer, TextArea } from "./CarInsuranceJsonEditor.styles";
import PropTypes from "prop-types";
import { Loader, LoadingButton } from "components/lib";

const CarInsuranceJsonEditor = ({ isLoading, fetchData, textAreaValue, setTextAreaValue }) => {
  const [isShowError, setIsShowError] = useState(false);
  const [isShowInvalidJsonError, setIsShowInvalidJsonError] = useState(false);
  const [isShowInvalidJsonErrMsg, setIsShowInvalidJsonErrMsg] = useState(false);

  const submitHandler = () => {
    setIsShowError(false);
    setIsShowInvalidJsonError(false);
    setIsShowInvalidJsonErrMsg(false);
    if (!textAreaValue) {
      setIsShowError(true);
      return;
    }

    const data = tryParseJSONObject(textAreaValue);

    if (!data) {
      setIsShowInvalidJsonError(true);
      return;
    }

    // const checkResult = checkJsonIsValid(data);
    const checkResult = false;

    if (checkResult !== false) {
      setIsShowInvalidJsonErrMsg(checkResult);
      return;
    }

    fetchData();
  };

  return (
    <Container className="w-100 d-flex justify-content-center pb-5">
      <TextAreaContainer className="text-center">
        <TextArea
          className="w-100 h-100 rounded-3 p-2 form-control"
          placeholder="Data"
          value={(textAreaValue && textAreaValue) || ""}
          onChange={event => setTextAreaValue(event.target.value)}
        />
        {isShowError && <p className="name-validate-error">JSON data is required!</p>}
        {isShowInvalidJsonError && <p className="name-validate-error">Invalid JSON format.</p>}
        {isShowInvalidJsonErrMsg && <p className="name-validate-error">{isShowInvalidJsonErrMsg}</p>}
        <LoadingButton
          className="mt-3 mx-auto text-center d-block btn-validate"
          onClick={submitHandler}
          disabled={isLoading}
        >
          {isLoading ? <Loader /> : "Submit"}
        </LoadingButton>
      </TextAreaContainer>
    </Container>
  );
};

export default CarInsuranceJsonEditor;

CarInsuranceJsonEditor.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
  textAreaValue: PropTypes.string.isRequired,
  setTextAreaValue: PropTypes.func.isRequired,
};

function stringify(data) {
  return JSON.stringify(data, undefined, 3);
}

function tryParseJSONObject(jsonString) {
  try {
    const o = JSON.parse(jsonString);

    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    // console.error(e);
  }

  return false;
}

function parse(data) {
  return JSON.parse(data);
}
