import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { GraphWrapper, RobotWrapper } from "./robot.styles";
import dummyImage from "assest/quoteicons/blueCircle.png";
import CompanyOverIcon from "assest/companyOVER.png";
import CompanyUpIcon from "assest/companyUP.png";
import NameOverIcon from "assest/nameOVER.png";
import OfficerIcon from "assest/officer.svg";
import RetiredIcon from "assest/retired.svg";
import UserIcon from "assest/user.svg";
import VipIcon from "assest/vip.svg";
import { Spinner } from "react-bootstrap";

const MAIN_NODE = "main_node";

const formatLabel = text => {
  if (!text || typeof text !== "string") return "";
  return text.split("_").join(" ");
};

const positionData = {
  overview: [0, -80],
  confirmationStatement: [80, -80],
  accounts: [80, -160],
  previousCompanyNames: [-80, -160],
  natureOfBusiness: [0, -160],
  filingHistory: [80, 0],
  people: [0, 80],
  officers: [80, 160],
  personsWithSignificantControl: [-80, 160],
  charges: [-80, 0],
};

const htmlTitle = innerHTML => {
  const container = document.createElement("div");
  container.innerHTML = innerHTML;
  return container;
};

const titleWrap = titleText => {
  return `<span class='title'>${titleText}</span>`;
};

const truncateLabel = label => {
  if (label.length > 60) {
    return label.slice(0, 57) + "...";
  }
  return label;
};

const getImage = imageLink => {
  try {
    return require(imageLink);
  } catch (error) {
    return dummyImage;
  }
};

const Robot = ({ graphData: graphDataObj }) => {
  const graphData = JSON.parse(JSON.stringify(graphDataObj));
  const domNode = useRef(null);
  const network = useRef(null);

  const options = {
    autoResize: true,
    height: "100%",
    width: "100%",
    layout: { improvedLayout: false },
    interaction: {
      hover: true,
    },
    nodes: {
      borderWidth: 0,
      borderWidthSelected: 0,
      font: { color: "white", align: "left", size: 7, background: "transparent" },
      color: "transparent",
      shadow: {
        enabled: false,
        size: 0,
      },
      shape: "image",
      fixed: false,
      size: 10,
      scaling: {
        min: 10,
        max: 10,
      },
      shapeProperties: {
        // interpolation: false,
      },
    },
    edges: {
      color: "black",
      length: 80,
      selectionWidth: 0,
      width: 1,
    },
    physics: {
      // enabled: false,
      stabilization: {
        enabled: true,
      },
    },
  };

  const nodeData = [];
  const edgeData = [];
  const mainNodeIds = [MAIN_NODE];
  const subCompanies = {};
  const filingTypes = {};
  const chargeStatuses = {};

  const addCompanyNode = (mainNodeId, companyData, needCreateMainNode = true) => {
    // mainNodeIds.push(mainNodeId);
    const companyOverview = companyData.company;
    if (needCreateMainNode) {
      const mainNode = {
        id: mainNodeId,
        // cid: parentNodeId,
        label: companyOverview?.name || "companyName",
        image: CompanyOverIcon,
        x: 0,
        y: 0,
        title: htmlTitle(`
        <br> ${titleWrap("Name:")} ${companyOverview.name}
        ${companyOverview.number ? `<br> ${titleWrap("Number:")} ${companyOverview.number}` : ""}
        ${companyOverview.address ? `<br> ${titleWrap("Address:")} ${companyOverview.address}` : ""}
        ${
          companyOverview.incorporatedOn ? `<br> ${titleWrap("Incorporate On:")} ${companyOverview.incorporatedOn}` : ""
        }
        ${companyOverview.type ? `<br> ${titleWrap("Company Type:")} ${companyOverview.type}` : ""}
    `),
      };
      nodeData.push(mainNode);
    }
    // if (parentNodeId) {
    //   edgeData.push({
    //     from: mainNodeId,
    //     to: parentNodeId,
    //   });
    //   subCompanies[parentNodeId] = companyData;
    // }
    // Address node
    if (companyOverview.validatedCompanyAddress || companyOverview.address) {
      const addressNode = {
        id: `${mainNodeId}-address`,
        label: "Company address",
        image: dummyImage,
        title: companyOverview.address
          ? htmlTitle(`<br> ${titleWrap("Address:")} ${companyOverview.address}`)
          : undefined,
      };
      nodeData.push(addressNode);
      edgeData.push({
        from: `${mainNodeId}-address`,
        to: mainNodeId,
      });
    }

    // Official Page
    if (companyOverview.officialPage) {
      const officialPageNode = {
        id: `${mainNodeId}-official-page`,
        label: "Official page",
        image: dummyImage,
        link: companyOverview.officialPage,
        title: htmlTitle(`<br> ${titleWrap("Official page link:")} ${companyOverview.officialPage}`),
      };
      nodeData.push(officialPageNode);
      edgeData.push({
        from: `${mainNodeId}-official-page`,
        to: mainNodeId,
      });
    }

    // Previous name nodes
    const previousNames = companyOverview.previousCompanyNames || [];
    previousNames.forEach((nameObject, index) => {
      const id = `${mainNodeId}-previous-name-${index}`;
      const nameNode = {
        id,
        label: truncateLabel(nameObject.name),
        image: CompanyUpIcon,
        x: 0,
        y: -80 * (index + 1),
        title: htmlTitle(`
        <br> ${titleWrap("Name:")} ${nameObject.name} <br>
        ${titleWrap("Period:")} ${nameObject.period}
      `),
      };
      nodeData.push(nameNode);
      edgeData.push({
        from: id,
        to: index === 0 ? mainNodeId : `previous-name-${index - 1}`,
      });
    });

    // People nodes
    const officers = companyData.officers;
    const activeOfficers = officers?.active || [];
    const resignedOfficers = officers?.resigned || [];
    const significantPeople = companyData.personsWithSignificantControl;
    const activeSignificantPeople = significantPeople?.active || [];
    const ceasedSignificantPeople = significantPeople?.ceased || [];
    const peopleList = [...activeOfficers, ...resignedOfficers, ...activeSignificantPeople, ...ceasedSignificantPeople];
    if (peopleList.length) {
      if (activeOfficers.length) {
        const groupId = `${mainNodeId}-active-officers`;
        nodeData.push({
          id: groupId,
          label: "Active officers",
          cid: groupId,
          image: OfficerIcon,
        });
        edgeData.push({
          from: mainNodeId,
          to: groupId,
        });
        activeOfficers.forEach((ao, index) => {
          const id = `${mainNodeId}-ao-${index}`;
          nodeData.push({
            id,
            companyDetails: ao.companyDetails,
            cid: groupId,
            label: truncateLabel(ao.name || `Active officer - ${index}`),
            image: NameOverIcon,
            title: htmlTitle(`
                <br>  ${titleWrap("Name:")} ${ao.name}
              ${ao.correspondenceAddress ? `<br> ${titleWrap("Address:")} ${ao.correspondenceAddress}` : ""}
              ${ao.role ? `<br> ${titleWrap("Role:")} ACTIVE - ${ao.role}` : ""}
              ${ao.appointedOn ? `<br> ${titleWrap("Appointed On:")} ${ao.appointedOn}` : ""}
          `),
            // x: index % 2 === 0 ? -160 : -240,
            // y: currentY,
          });
          edgeData.push({
            from: id,
            to: groupId,
          });
        });
      }
      if (resignedOfficers.length) {
        const groupId = `${mainNodeId}-resigned-officers`;
        nodeData.push({
          id: groupId,
          label: "Resigned officers",
          cid: groupId,
          image: RetiredIcon,
          // x: -80,
          // y: 0,
        });
        edgeData.push({
          from: mainNodeId,
          to: groupId,
        });
        resignedOfficers.forEach((ro, index) => {
          const id = `${mainNodeId}-ro-${index}`;
          nodeData.push({
            id,
            cid: groupId,
            companyDetails: ro.companyDetails,
            label: truncateLabel(ro.name || `Resigned officer - ${index}`),
            image: NameOverIcon,
            title: htmlTitle(`
              <br> ${titleWrap("Name:")} ${ro.name}
              ${ro.correspondenceAddress ? `<br> ${titleWrap("Address:")} ${ro.correspondenceAddress}` : ""}
              ${ro.role ? `<br> ${titleWrap("Role:")} RESIGNED - ${ro.role}` : ""}
              ${ro.appointedOn ? `<br> ${titleWrap("Appointed On:")} ${ro.appointedOn}` : ""}
          `),
            // x: index % 2 === 0 ? -160 : -240,
            // y: currentY,
          });
          edgeData.push({
            from: id,
            to: groupId,
          });
        });
      }
      if (activeSignificantPeople.length) {
        const groupId = `${mainNodeId}-active-significant-people`;
        nodeData.push({
          id: groupId,
          label: "Active Significant People",
          cid: groupId,
          image: OfficerIcon,
          // x: -80,
          // y: 0,
        });
        edgeData.push({
          from: mainNodeId,
          to: groupId,
        });
        activeSignificantPeople.forEach((asp, index) => {
          const id = `${mainNodeId}-asp-${index}`;
          nodeData.push({
            id,
            cid: groupId,
            companyDetails: asp.companyDetails,
            label: truncateLabel(asp.name || `Active Significant Person - ${index}`),
            image: NameOverIcon,
            title: htmlTitle(`
              <br> ${titleWrap("Name:")} ${asp.name}
              ${asp.correspondenceAddress ? `<br> ${titleWrap("Address:")} ${asp.correspondenceAddress}` : ""}
              ${asp.notifiedOn ? `<br> ${titleWrap("Notified On:")} ${asp.notifiedOn}` : ""}
              ${asp.ceasedOn ? `<br> ${titleWrap("Ceased On:")} ${asp.appointedOn}` : ""}
          `),
          });
          edgeData.push({
            from: id,
            to: groupId,
          });
        });
      }
      if (ceasedSignificantPeople.length) {
        const groupId = `${mainNodeId}-ceased-significant-people`;
        nodeData.push({
          id: groupId,
          label: "Ceased Significant People",
          cid: groupId,
          image: RetiredIcon,
          // x: -80,
          // y: 0,
        });
        edgeData.push({
          from: mainNodeId,
          to: groupId,
        });
        ceasedSignificantPeople.forEach((csp, index) => {
          const id = `${mainNodeId}-csp-${index}`;
          nodeData.push({
            id,
            cid: groupId,
            companyDetails: csp.companyDetails,
            label: truncateLabel(csp.name || `Ceased Significant Person - ${index}`),
            image: NameOverIcon,
            title: htmlTitle(`
              <br> ${titleWrap("Name:")} ${csp.name}
              ${csp.correspondenceAddress ? `<br> ${titleWrap("Address:")} ${csp.correspondenceAddress}` : ""}
              ${csp.notifiedOn ? `<br> ${titleWrap("Notified On:")} ${csp.notifiedOn}` : ""}
              ${csp.ceasedOn ? `<br> ${titleWrap("Ceased On:")} ${csp.appointedOn}` : ""}
          `),
          });
          edgeData.push({
            from: id,
            to: groupId,
          });
        });
      }
    }

    // Charge Nodes
    const chargeList = companyData.charges || {};
    const currentChargeStatuses = Object.entries(chargeList);
    chargeStatuses[mainNodeId] = currentChargeStatuses;
    if (currentChargeStatuses.length) {
      nodeData.push({
        id: `${mainNodeId}-charges`,
        cid: `${mainNodeId}-charges`,
        label: "Charges",
        image: getImage(),
        // x: 0,
        // y: 80,
      });
      edgeData.push({
        from: `${mainNodeId}-charges`,
        to: mainNodeId,
      });
      currentChargeStatuses.forEach(([status, list], index) => {
        const typeId = `${mainNodeId}-charge-${status}`;
        nodeData.push({
          id: typeId,
          cid: typeId,
          label: status,
          image: getImage(),
        });
        edgeData.push({
          from: typeId,
          to: `${mainNodeId}-charges`,
        });
        list.forEach((charge, index) => {
          const chargeId = `${typeId}-${index}`;
          nodeData.push({
            id: chargeId,
            cid: typeId,
            label: truncateLabel(charge.chargeCode),
            image: getImage(),
            title: htmlTitle(`
              ${charge.created ? `<br> ${titleWrap("Created:")} ${charge.created}` : ""}
              ${charge.delivered ? `<br> ${titleWrap("Delivered:")} ${charge.delivered}` : ""}
              ${charge.status ? `<br> ${titleWrap("Status:")} ${charge.status}` : ""}
              ${
                charge.personsEntitled?.length
                  ? `<br> ${titleWrap("Persons Entitled:")} ${charge.personsEntitled.map(
                      person => `<br> <span style="padding-left: 8px">${person}</span>`,
                    )}`
                  : ""
              }
              ${
                charge.briefDescription?.length
                  ? `<br> ${titleWrap("Brief Description:")} ${charge.briefDescription.map(
                      person => `<br> <span style="padding-left: 8px">${person}</span>`,
                    )}`
                  : ""
              }
          `),
            // x: 0,
            // y: index % 2 === 0 ? 160 : 240,
          });
          edgeData.push({
            from: chargeId,
            to: typeId,
          });
        });
      });
    }

    // Filing History nodes
    const filingHistory = companyData.filingHistory || {};
    const currentFilingTypes = Object.entries(filingHistory);
    filingTypes[mainNodeId] = currentFilingTypes;
    if (currentFilingTypes.length) {
      nodeData.push({
        id: `${mainNodeId}-filingHistory`,
        cid: `${mainNodeId}-filingHistory`,
        label: "Filing history",
        image: getImage(),
      });
      edgeData.push({
        from: `${mainNodeId}-filingHistory`,
        to: mainNodeId,
      });
      currentFilingTypes.forEach(([type, list], index) => {
        const typeId = `${mainNodeId}-filingHistory-${index}`;
        nodeData.push({
          id: typeId,
          cid: typeId,
          label: type,
          image: getImage(),
        });
        edgeData.push({
          from: typeId,
          to: `${mainNodeId}-filingHistory`,
        });
        list.forEach((file, index) => {
          const fileId = `${mainNodeId}-${typeId}-${index}`;
          nodeData.push({
            id: fileId,
            cid: typeId,
            link: file.link,
            label: truncateLabel(file.description),
            image: getImage(),
            title: htmlTitle(`
              ${file.date ? `<br> ${titleWrap("Date:")} ${file.date}` : ""}
              ${file.description ? `<br> ${titleWrap("Description:")} ${file.description}` : ""}
          `),
          });
          edgeData.push({
            from: fileId,
            to: typeId,
          });
        });
      });
    }
    return [nodeData, edgeData];
  };

  // build the main company graph
  addCompanyNode(MAIN_NODE, graphData);
  const nodes = new DataSet(nodeData);
  const edges = new DataSet(edgeData);

  let data = {
    nodes,
    edges,
  };

  // TODO: apply cluster
  const clusterByCid = (id, label, nodeLabel, cid, image, imageType, isNotUseCircularShape) => {
    const clusterOptionsByData = {
      joinCondition: function (childOptions) {
        return childOptions.cid === id;
      },
      clusterNodeProperties: {
        id: `cidCluster${id}`,
        shape: "image",
        image,
        label,
        cid: cid ? cid : undefined,
        allowSingleNodeCluster: true,
        font: { color: "white", align: "left", size: 7, background: "transparent" },
        color: "transparent",
      },
    };
    network.current.cluster(clusterOptionsByData);
  };

  const clusterActions = useCallback(
    id => {
      clusterByCid(`${id}-active-officers`, "Active Officers", "Active Officers", null, OfficerIcon);
      clusterByCid(`${id}-resigned-officers`, "Resigned officers", "Resigned officers", "people", RetiredIcon);
      clusterByCid(
        `${id}-active-significant-people`,
        "Active Significant People",
        "Active Significant People",
        "people",
        OfficerIcon,
      );
      clusterByCid(
        `${id}-ceased-significant-people`,
        "Ceased Significant People",
        "Ceased Significant People",
        "people",
        RetiredIcon,
      );
      // clusterByCid("charges", "Charges", "Charges", null, dummyImage);
      filingTypes[id]?.forEach(([type, list], index) => {
        clusterByCid(`${id}-filingHistory-${index}`, type, type, null, dummyImage);
      });
      chargeStatuses[id]?.forEach(([status, list], index) => {
        clusterByCid(`${id}-charge-${status}`, status, status, null, dummyImage);
      });
    },
    [network.current],
  );

  useEffect(() => {
    network.current = new Network(domNode.current, data, options);
    network.current.once("stabilizationIterationsDone", function () {
      document.getElementById("robot-loading-spinner").style.opacity = 0;
      network.current.focus(MAIN_NODE, {
        scale: 2.0,
      });
      // really clean the dom element
      setTimeout(function () {
        document.getElementById("robot-loading-spinner").style.display = "none";
      }, 500);
    });
    network.current.on("selectNode", function (params) {
      if (params.nodes.length == 1) {
        if (network.current.isCluster(params.nodes[0]) == true) {
          network.current.openCluster(params.nodes[0]);
        } else {
          const nodeData = data.nodes.get(params.nodes[0]);
          if (nodeData?.link) {
            const a = document.createElement("a");
            a.href = nodeData.link;
            a.target = "_blank";
            a.click();
          } else if (nodeData?.companyDetails) {
            const [nodes, edges] = addCompanyNode(nodeData.id, nodeData.companyDetails, false);
            network.current.setData({ nodes, edges });
            network.current.redraw();

            data = {
              nodes: new DataSet(nodes),
              edges: new DataSet(edges),
            };
            clusterActions(nodeData.id);
          }
        }
        network.current.focus(params.nodes[0], {
          scale: 2.0,
        });
        // network.current.fit({
        //   maxZoomLevel: 2.5,
        //   minZoomLevel: 0.5,
        // });
      }
    });
  }, [domNode, network, data, options]);
  useEffect(() => {
    // TODO: remove the animation when open a cluster
    // mainNodeIds.forEach(id => {
    //   clusterByCid(`${id}-active-officers`, "Active Officers", "Active Officers", null, OfficerIcon);
    //   clusterByCid(`${id}-resigned-officers`, "Resigned officers", "Resigned officers", "people", RetiredIcon);
    //   clusterByCid(`${id}-significant-people`, "Significant People", "Significant People", "people", VipIcon);
    //   // clusterByCid("charges", "Charges", "Charges", null, dummyImage);
    //   filingTypes[id]?.forEach(([type, list], index) => {
    //     clusterByCid(`${id}-filingHistory-${index}`, type, type, null, dummyImage);
    //   });
    //   chargeStatuses[id]?.forEach(([status, list], index) => {
    //     clusterByCid(`${id}-charge-${status}`, status, status, null, dummyImage);
    //   });
    // });
    // Object.entries(subCompanies).forEach(([parentNodeId, companyData]) => {
    //   clusterByCid(parentNodeId, companyData.company?.name, companyData.company?.name, null, CompanyOverIcon);
    // });
    // clusterByCid("filingHistory", "Filing history", "Filing history", null, dummyImage);
    // clusterByCid("people", "Active Officers", "Active Officers");
    clusterActions(MAIN_NODE);
  }, [network.current]);
  return (
    <RobotWrapper>
      <GraphWrapper ref={domNode} />
      <div id="robot-loading-spinner">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </RobotWrapper>
  );
};

export default Robot;

Robot.propTypes = {
  graphData: PropTypes.object.isRequired,
};
