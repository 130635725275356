/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  closestCenter,
  rectIntersection,
  DndContext,
  DragOverlay,
  defaultDropAnimation,
  getFirstCollision,
  MouseSensor,
  TouchSensor,
  useSensors,
  useSensor,
  MeasuringStrategy,
  useDndContext,
  PointerSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SwiperSlide } from "swiper/react";

import { Container } from "./Container";
import { Item } from "./Item";
import SwiperWrapper from "./SwiperWrapper";
import { useMediaQuery } from "react-responsive";
import { MEDIA_QUERY } from "constants/general";
import { useFolderModal } from "context/FolderModalContext";

import { ContainerWrapper } from "./MultipleContainerWithCombine.styles";
import { fillBoxEmptySpace } from "utils/FormatIconsArray";
import { renderContainers } from "./RenderContainers";
import FolderContent from "components/appIcon/FolderContent";

const dropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5,
};

export function MultipleContainersCombine({
  adjustScale = false,
  itemCount = 3,
  cancelDrop,
  columns,
  handle = false,
  items,
  setItems,
  containerStyle,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  vertical = false,
  scrollable,
  activationConstraint,
}) {
  const [containers, setContainers] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;
  const [isSwiperDraggableDisabled, setIsSwiperDraggableDisabled] = useState(false);
  const [fillBoxEmptySpaceCountLimit, setFillBoxEmptySpaceCountLimit] = useState(24);
  const isTabletScreenIconsCountShow = useMediaQuery({ minWidth: 481, maxWidth: 992 });

  useEffect(() => {
    if (isTabletScreenIconsCountShow) {
      setFillBoxEmptySpaceCountLimit(25);
    } else {
      setFillBoxEmptySpaceCountLimit(24);
    }
  }, [isTabletScreenIconsCountShow]);

  useEffect(() => {
    setContainers(Object.keys(items));
  }, [items]);

  const [clonedItems, setClonedItems] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
    useSensor(PointerSensor, { activationConstraint }),
  );

  const findContainer = id => {
    const _containerId = containers.find(key => {
      return items[key].some(item => {
        return +item.id === +id;
      });
    });

    return _containerId;
  };

  const getIndex = id => {
    const container = findContainer(id);
    if (!container) {
      return -1;
    }
    const index = items[container].findIndex(item => +item.id === +id);
    return index;
  };

  const onDragCancel = () => {
    setIsSwiperDraggableDisabled(false);
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const isBigScreen = useMediaQuery({
    query: MEDIA_QUERY.BIG_SCREEN,
  });

  const { isFolderModalShow } = useFolderModal();

  const [isMultipleContainer, setIsMultipleContainer] = useState(false);

  useEffect(() => {
    setIsMultipleContainer(containers.length <= 3);
  }, [containers]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setIsSwiperDraggableDisabled(true);
        setActiveId(active.id);
        setClonedItems(items);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (!overId || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems(items => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.findIndex(item => item.id === overId);
            const activeIndex = activeItems.findIndex(item => item.id === active.id);

            let newIndex;

            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;

            recentlyMovedToNewContainer.current = true;

            const newItems = {
              ...items,
              [activeContainer]: items[activeContainer].filter(item => item.id !== active.id),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(newIndex, items[overContainer].length),
              ],
            };

            const newItemsArr = Object.assign([], newItems);
            fillBoxEmptySpace(newItemsArr, fillBoxEmptySpaceCountLimit);

            return Object.assign({}, newItemsArr);
          });
        }
      }}
      onDragEnd={({ active, collisions, over }) => {
        setIsSwiperDraggableDisabled(false);
        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (!overId) {
          setActiveId(null);
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          const activeIndex = items[activeContainer].findIndex(item => item.id === active.id);
          const overIndex = items[overContainer].findIndex(item => item.id === overId);

          /**
           * Items combine
           */
          if (collisions?.length > 1) {
            const {
              id: secondCollidingId,
              data: { value: collisionRatio },
            } = collisions && collisions.length > 1 ? collisions[1] : [];
            if (secondCollidingId) {
              const shouldCombine =
                secondCollidingId !== active.id &&
                collisionRatio != null &&
                collisionRatio > 0.1 &&
                collisionRatio < 0.4;

              if (shouldCombine && activeId) {
                const newItems = JSON.parse(JSON.stringify(items));
                const secondCollidingIndex = newItems[overContainer].findIndex(item => item.id === secondCollidingId);

                const activeItemContainer = newItems[activeContainer];
                const secondItemContainer = newItems[overContainer];
                const firstItem = activeItemContainer[activeIndex];
                const secondItem = secondItemContainer[secondCollidingIndex];

                if (secondItem) {
                  // disable already grouped items grouping
                  if (firstItem?.isCombineEnabled) return;

                  const refineSecondItem = secondItem?.isCombineEnabled ? secondItem.data : [secondItem];

                  const combinedItems = {
                    id: firstItem.id,
                    isCombineEnabled: true,
                    data: [firstItem, ...refineSecondItem],
                  };

                  newItems[activeContainer][secondCollidingIndex] = combinedItems;

                  newItems[activeContainer].splice(activeIndex, 1);

                  const newItemsArr = Object.assign([], newItems);
                  fillBoxEmptySpace(newItemsArr, fillBoxEmptySpaceCountLimit);

                  setItems(Object.assign({}, newItemsArr));

                  return;
                }
              }
            }
          }
          /**
           * re-arrange items
           */
          if (activeIndex !== overIndex) {
            const newItems = {
              ...items,
              [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
            };

            const newItemsArr = Object.assign([], newItems);
            fillBoxEmptySpace(newItemsArr, fillBoxEmptySpaceCountLimit);

            setItems(Object.assign({}, newItemsArr));
          }
        }

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <ContainerWrapper isFolderModalShow={isFolderModalShow} isMultipleContainer={isMultipleContainer}>
        {isFolderModalShow ? <FolderContent /> : null}
        {!isFolderModalShow && containers.length ? (
          <SortableContext
            items={[...containers]}
            strategy={vertical ? verticalListSortingStrategy : horizontalListSortingStrategy}
          >
            {isMultipleContainer && isBigScreen ? (
              containers.map(containerId => {
                return renderContainers(
                  containerId,
                  isMultipleContainer,
                  items,
                  minimal,
                  columns,
                  scrollable,
                  containerStyle,
                  strategy,
                  isSortingContainer,
                  handle,
                  getItemStyles,
                  wrapperStyle,
                  renderItem,
                  getIndex,
                );
              })
            ) : (
              <SwiperWrapper isSwiperDraggableDisabled={isSwiperDraggableDisabled}>
                {containers.map(containerId => (
                  <SwiperSlide key={containerId}>
                    {renderContainers(
                      containerId,
                      isMultipleContainer,
                      items,
                      minimal,
                      columns,
                      scrollable,
                      containerStyle,
                      strategy,
                      isSortingContainer,
                      handle,
                      getItemStyles,
                      wrapperStyle,
                      renderItem,
                      getIndex,
                    )}
                  </SwiperSlide>
                ))}
              </SwiperWrapper>
            )}
          </SortableContext>
        ) : null}
      </ContainerWrapper>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ? containers.includes(activeId)
              ? renderContainerDragOverlay(activeId)
              : renderSortableItemDragOverlay(activeId)
            : null}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );

  function renderSortableItemDragOverlay(id) {
    const containerId = findContainer(id);
    const selectedItem = items[containerId].find(item => item.id === id);
    return (
      <Item
        value={id}
        data={selectedItem}
        handle={handle}
        style={getItemStyles({
          containerId,
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        color={undefined}
        wrapperStyle={wrapperStyle({ index: 0 })}
        renderItem={renderItem}
        dragOverlay
      />
    );
  }

  function renderContainerDragOverlay(containerId) {
    return (
      <Container
        label={`Column ${containerId}`}
        columns={columns}
        style={{
          height: "100%",
        }}
        shadow
        unstyled={true}
      >
        {items[containerId].map((item, index) => {
          if (!item) return null;
          return (
            <Item
              key={item.key}
              value={item.id}
              data={item}
              handle={handle}
              style={getItemStyles({
                containerId,
                overIndex: -1,
                index: getIndex(item),
                value: item,
                isDragging: false,
                isSorting: false,
                isDragOverlay: false,
              })}
              color={undefined}
              wrapperStyle={wrapperStyle({ index })}
              renderItem={renderItem}
            />
          );
        })}
      </Container>
    );
  }
}
