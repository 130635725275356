const included = {
  legalCover: `Offers financial protection against legal fees if you're involved in an accident that's not your fault. This may include personal injury, excess recovery and loss of earnings.`,

  courtesyCar: `Provided if your car is being repaired following an accident.`,

  breakdownCover: `Provides you with roadside assistance if your car breaks down. Levels of cover will vary between insurers and can include anything from vehicle recovery to onward travel cover.`,

  personalAccidentCover: `Provides you or your family with financial support if you're injured or die in an accident. Levels of cover will vary between insurers.`,

  personalEffectsCover: `Provides cover for lost, stolen or damaged personal belongings left in the car.`,

  windscreenCover: `Covers damages to your car's windscreen, including repairing chips and cracks as well as full replacements.`,

  drivingAbroad: `Provides cover for driving your car in countries outside of the UK.`,

  guaranteeOfRepairs: `Repairs to your car following an accident are guaranteed.`,

  protectedNoClaimsBonus: `Protects your no-claims bonus (NCB) if you make a claim for an accident that was deemed to be your fault. However, subsequent claims may result in your NCB being reduced.`,
};

export const helpText = {
  ...included,
};
