import React from "react";
import { testHarnessBoxIconsData } from "data/iconsData";
import SliderBox from "components/quote/SliderBox";

const pageTitle = "Test Harness";
const boxTitle = "Test Harness";

const TestHarness = () => {
  return <SliderBox pageTitle={pageTitle} icons={testHarnessBoxIconsData} boxTitle={boxTitle} />;
};

export default TestHarness;
