/* eslint-disable react/prop-types */
import React from "react";
import "./ExternalLink.scss";

const ExternalLink = ({ href, label, classes = "text-danger" }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={`btn btn-link external_link ${classes}`}>
      {label}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="external_link_icon"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
        />
      </svg>
    </a>
  );
};

export default ExternalLink;
