/* eslint-disable react/prop-types */
import React, { memo, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Container, FolderContainer, FolderIconContainer, ImageSection, SmallIcon } from "./AppIconCombine.styles";
import { useFolderModal } from "context/FolderModalContext";
import FolderName from "./FolderName";

const AppIconCombine = ({ combineData, isModalShow, setIsModalShow, folderName }) => {
  const { data } = combineData;

  const { setIsFolderModalShow, dispatchFolderContent, setFolderName } = useFolderModal();

  const modalShowHandler = isShow => {
    setIsFolderModalShow(true);
    if (isShow) {
      setIsModalShow(false);
      dispatchFolderContent({ type: "REMOVE_MODAL_ITEMS" });
    } else {
      setIsModalShow(true);
      dispatchFolderContent({ type: "ADD_MODAL_ITEMS", payload: data });
    }
  };

  useEffect(() => {
    setIsFolderModalShow(isModalShow);
  }, [isModalShow]);

  return (
    <div onClick={() => setFolderName(folderName)}>
      {/* render tablet & mobile screen modal */}
      <Container>
        <FolderContainer className="image-wrapper" onClick={() => modalShowHandler(isModalShow)}>
          <div style={{ margin: "10px 4px" }}>
            <FolderIconContainer className="image-wrapper">
              {data.slice(-9).map(_data => {
                return <SmallIcon className="small-icons-img" src={_data.icon} alt="address" key={_data.key} />;
              })}
            </FolderIconContainer>
            <FolderName name={folderName} />
          </div>
        </FolderContainer>
      </Container>
    </div>
  );
};

AppIconCombine.propTypes = {
  combineData: PropTypes.object.isRequired,
  folderName: PropTypes.string,
};

AppIconCombine.defaultProps = {
  folderName: "Unnamed Folder",
};

export default memo(AppIconCombine);
