import React, { useState } from "react";
import { Popover } from "react-tiny-popover";
import SearchPopoverContent from "./SearchPopoverContent";

const SearchPopover = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div>
      <Popover
        isOpen={isPopoverOpen}
        onClickOutside={() => setIsPopoverOpen(false)}
        positions={["bottom"]}
        containerStyle={{ zIndex: 9999 }}
        content={<SearchPopoverContent setIsPopoverOpen={setIsPopoverOpen} />}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="nav-menu-icon me-3 text-white"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </Popover>
    </div>
  );
};

export default SearchPopover;
