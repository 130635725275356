import { TextArea, TextAreaContainer } from "./styled";
import PropTypes from "prop-types";

const TextAreaForm = ({ cols, rows, jsonValue, readOnly = false }) => {
  return (
    <form className="mt-3">
      <TextAreaContainer className="form-group">
        <TextArea className="input-form-textarea" readOnly={readOnly} cols={cols} rows={rows} value={jsonValue} />
      </TextAreaContainer>
    </form>
  );
};

TextAreaForm.propTypes = {
  cols: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  jsonValue: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
  readOnly: PropTypes.bool,
};

TextAreaForm.defaultProps = {
  readOnly: false,
};

export default TextAreaForm;
