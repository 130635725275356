/* eslint-disable react/prop-types */
import React from "react";
import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { Container } from "./Container";
import { CSS } from "@dnd-kit/utilities";

const DroppableContainer = ({ children, columns = 1, disabled, id, items, style, ...props }) => {
  const animateLayoutChanges = args => (args.isSorting || args.wasDragging ? defaultAnimateLayoutChanges(args) : true);

  const { active, attributes, isDragging, listeners, over, setNodeRef, transition, transform } = useSortable({
    id,
    data: {
      type: "container",
      children: items,
    },
    animateLayoutChanges,
  });

  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== "container") || items.includes(over.id)
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
    >
      {children}
    </Container>
  );
};

export default DroppableContainer;
