import React from "react";

const StartTypingToSearch = () => {
  return (
    <div className="mt-2 content_box d-flex align-items-center justify-content-center">
      <div className="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="text-white content_box_icon"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
        <h5 className="text-white">Start typing to search</h5>
      </div>
    </div>
  );
};

export default StartTypingToSearch;
