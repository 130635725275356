import React, { useState } from "react";
import Chatbot from "react-chatbot-kit";
import config from "../../utils/chatBoxConfig";
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import PercaysoLogo from "assest/percayso-logo-roundel.svg";

const ChatBox = () => {
  const [showBot, toggleBot] = useState(false);
  return (
    <>
      {showBot && (
        <div className="app-chatbot-container">
          <Chatbot config={config} messageParser={MessageParser} actionProvider={ActionProvider} />
        </div>
      )}

      <button className="app-chatbot-button" onClick={() => toggleBot(prev => !prev)}>
        <img src={PercaysoLogo} alt="Percayso Logo" />
      </button>
    </>
  );
};

export default ChatBox;
