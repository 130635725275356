/* eslint-disable react/prop-types */
import React from "react";
import { ListGroup } from "react-bootstrap";
import "./DropDown.css";

const DropDown = ({ apiResponse, setSelectedItem, setNextPage }) => {
  return (
    <>
      {apiResponse.status === "PICKLIST" ? (
        <div className="position-absolute w-100 mt-2 drop-down">
          {Object.keys(apiResponse).length ? (
            <div className="bg-tapestry">
              {apiResponse.picklist.map((item, key) => {
                return (
                  <div key={key} className="cursor_pointer bg-tapestry m-0 mb-1 picklist-item text-truncate">
                    <p
                      className="text-truncate mb-0"
                      onClick={() => {
                        setSelectedItem(item);
                        setNextPage(1);
                      }}
                    >
                      {item.address || item.inputAddress || item.status}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default DropDown;
