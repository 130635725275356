import React, { useState } from "react";
import { Meta } from "components/layout";
import { InputForm } from "components/forms";
import { FieldsWrapper, Loader, LoadingButton } from "components/lib";
import "../styles/validateAddress.css";
import { config } from "../config";
import { client } from "utils/apiClient";
import ValidateMatchNameGraph from "components/matchNameGraph";

const MatchName = () => {
  const [apiResponse, setApiResponse] = useState("");
  const [nextPage, setNextPage] = useState(0);
  const [nameOne, setNameOne] = useState("");
  const [nameTwo, setNameTwo] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const pageTitle = "Match name";
  const handleSubmit = async e => {
    e.preventDefault();
    setValidationMessage("");

    if (!nameOne) {
      setValidationMessage("Name one field required!");
      return;
    }

    if (!nameTwo) {
      setValidationMessage("Name two field required!");
      return;
    }

    setLoading(true);

    const {
      matchName: { apiUrl: API_URL },
    } = config;

    const apiResponse = await client(API_URL, {
      data: {
        name_1: nameOne,
        name_2: nameTwo,
      },
    });

    if (apiResponse.match_level !== "ERROR") {
      setApiResponse(apiResponse);
      setNextPage(1);
    } else {
      setValidationMessage(`${apiResponse?.message}`);
    }

    setLoading(false);
  };
  const handleAnother = () => {
    setNameOne("");
    setNameTwo("");
    setNextPage(0);
  };
  return (
    <div className="address-form-wrapper">
      <Meta title={pageTitle} />
      {nextPage === 0 ? (
        <div>
          <form onSubmit={handleSubmit} className="mt-3">
            <FieldsWrapper>
              <InputForm
                type={"text"}
                value={nameOne}
                handleChange={event => setNameOne(event.target.value)}
                placeholder={"Name one"}
                autoFocus
                isLimitValeLength={false}
              />
              <InputForm
                type={"text"}
                value={nameTwo}
                handleChange={event => setNameTwo(event.target.value)}
                placeholder={"Name two"}
                isLimitValeLength={false}
              />
            </FieldsWrapper>
            <div className="btn-container">
              <LoadingButton type="submit" className="btn-validate mt-1">
                {loading ? <Loader /> : "Submit"}
              </LoadingButton>
            </div>
          </form>
          <div className="name-validate-error">{validationMessage}</div>
        </div>
      ) : (
        <ValidateMatchNameGraph apiResponse={apiResponse} />
      )}
    </div>
  );
};

export default MatchName;
