import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";

import { useDataTable, DataTableProvider } from "context/DataTableContext";
import { Meta } from "components/layout";
import Loader from "components/Loader";
import Overview from "./Overview";
import Officers from "./Officers";

import "../../styles/detail.css";

const DetailComponent = () => {
  const { status, companies } = useDataTable();
  const [company, setCompany] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const pageTitle = "Detail Page";

  useEffect(() => {
    if (!status.loading && companies.length > 0) {
      const com = companies.find(c => c.id === parseInt(id));
      setCompany(com);
      if (!com) navigate("not-found");
    }
  }, [status, companies, id]);

  return (
    <div className="company-detail">
      <Meta title={pageTitle} />

      {company ? (
        <>
          <h2 className="overview-heading">{company.name}</h2>
          <p>Company Number: {company.number}</p>
          <Tabs defaultActiveKey="overview" className=" mt-3">
            <Tab className="tab-style" eventKey="overview" title="Overview">
              <Overview company={company} />
            </Tab>

            <Tab eventKey="officers" title="Officers">
              <Officers />
            </Tab>
          </Tabs>
          <Link to="/check">
            <button className="back-detailPage">Another</button>
          </Link>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default function Detail() {
  return (
    <DataTableProvider>
      <DetailComponent />
    </DataTableProvider>
  );
}
