import React, { useEffect, useState } from "react";
import { Meta } from "components/layout";
import { Loader } from "components/lib";
import { client } from "utils/apiClient";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "styles/companies.css";
import CategoryTable from "components/companies/CategoryTable";
import CompaniesTable from "components/companies/CompaniesTable";
import SearchInput from "components/companies/SearchInput";
import { useDebouncedCallback } from "use-debounce";

export const CompanyContext = React.createContext(null);

const Companies = () => {
  const pageTitle = "Companies";

  const [searchText, setSearchText] = useState("");
  const [searchTerm, setSearchTerm] = useState("category");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoriesApiResponse, setCategoriesApiResponse] = useState([]);
  const [companiesApiResponse, setCompaniesApiResponse] = useState([]);
  const [isShowSearch, setIsShowSearch] = useState(true);
  const [isShowCompanyTable, setIsShowCompanyTable] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const fetchAllCategory = async () => {
    setLoading(true);
    const apiResponse = await client(`${process.env.REACT_APP_ELASTIC_SEARCH_URL}/business-details/category`);
    setLoading(false);
    return apiResponse;
  };

  const fetchAllCompanies = async () => {
    setLoading(true);
    const apiResponse = await client(`${process.env.REACT_APP_ELASTIC_SEARCH_URL}/business-details`);
    setLoading(false);
    return apiResponse;
  };

  const fetchAllData = async () => {
    const category = await fetchAllCategory();
    setCategoriesApiResponse(category);
    const companies = await fetchAllCompanies();
    setCompaniesApiResponse(companies?.hits?.hits);
  };

  const fetchQuoteDataUsingQuery = async query => {
    if (searchTerm === "category") {
      setLoading(true);
      const apiResponse = await client(
        `${process.env.REACT_APP_ELASTIC_SEARCH_URL}/business-details/category/?q=${query}`,
      );
      setLoading(false);
      return apiResponse;
    }

    if (searchTerm === "companies") {
      setLoading(true);
      const apiResponse = await client(`${process.env.REACT_APP_ELASTIC_SEARCH_URL}/business-details/?q=${query}`);
      setLoading(false);
      return apiResponse;
    }
  };

  const fetchData = inputValue => {
    return new Promise(resolve => {
      resolve(fetchQuoteDataUsingQuery(inputValue));
    }).then(data => {
      if (searchTerm === "category") {
        setCategoriesApiResponse(data);
      } else {
        setCompaniesApiResponse(data?.hits?.hits);
      }
    });
  };

  const debouncedLoadOptions = useDebouncedCallback(fetchData, 500);

  useEffect(() => {
    setSearchText("");
  }, [searchTerm]);

  useEffect(() => {
    if (searchText.length >= 2) {
      debouncedLoadOptions(searchText);
    }
  }, [searchText]);

  useEffect(async () => {
    fetchAllData();
  }, []);

  return (
    <CompanyContext.Provider
      value={{
        selectedCategoryName,
        setSelectedCategoryName,
        isShowCompanyTable,
        setIsShowCompanyTable,
        setIsShowSearch,
        setLoading,
        companiesApiResponse,
        categoriesApiResponse,
        searchTerm,
        setSearchTerm,
        errorMessage,
        setErrorMessage,
        searchText,
        setSearchText,
      }}
    >
      <div className="container-md">
        <Meta title={pageTitle} />

        {isShowSearch ? <SearchInput /> : null}

        {loading ? (
          <div className="d-flex justify-content-center">
            <Loader width="3rem" height="3rem" />
          </div>
        ) : null}

        {searchTerm === "category" && !loading && categoriesApiResponse?.length ? <CategoryTable /> : null}

        {searchTerm === "companies" && !loading && companiesApiResponse?.length ? <CompaniesTable /> : null}

        {searchTerm === "category" && !categoriesApiResponse.length && !loading ? (
          <p className="text-white fs-3 text-center">No data found</p>
        ) : null}

        {searchTerm === "companies" && !companiesApiResponse?.length && !loading ? (
          <p className="text-white fs-3 text-center">No data found</p>
        ) : null}
      </div>
    </CompanyContext.Provider>
  );
};

export default Companies;
