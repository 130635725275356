/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled/macro";

export const TextArea = styled.textarea({
  maxWidth: "100%",
  minHeight: "300px",
  padding: "1rem",
});

export const TextAreaContainer = styled.div({
  display: "flex",
  flexDirection: "column",
});
