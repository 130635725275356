import moment from "moment";
import * as _ from "lodash";

export const MEDIA_QUERY = {
  TABLET: "(max-width: 992px)",
  BIG_SCREEN: "(min-width: 993px)",
  SMALL_MOBILE: "(max-width: 480px)",
};

export const LONG_PRESS_DURATION = 600;

export const INITIAL_ICONS_QUANTITY = 16;

export const BIG_SCREEN_ICONS_LIMIT = 72;

export const GraphColor = {
  mercury: "#e3e3e3",
  royalBlue: "#2B7CE9",
  malibu: "#97C2FC",
  onahau: "#D2E5FF",

  black: "#000000",
  violetRed: "#fa4878",
  red: "red",
  gullGray: "hsl(215, 20%, 68%)",
  gullGrayDark: "hsl(215, 20%, 48%)",
  cosmic: "#7f3a74",
  frenchGray: "#B9B9BF",
  white: "#fff",
  geraldine: "#ff8183",
  koromiko: "#ffc569",
  scooter: "#34aed7",
  madison: "#0c3069",
  cosmicDeep: "#7a3b77",
  frenchRose: "#d64d79",
  frenchRoseDeep: "#f35877",
  froly: "#f7806e",
  saffronMango: "#fac85a",
  borderColor: "#ebebf3",
  none: "none",
  lightGray: "#D3D3D3",
  blue: "#013366",
};

export const GraphGroup = {
  BLUE_GROUP: "blue_group",
  PINK_GROUP: "pink_group",
};

export const AddressSearchDate = [
  "07-06-2022",
  "15-07-2021",
  "07-09-2020",
  "29-04-2019",
  "22-06-2016",
  "21-12-2015",
  "02-12-2014",
  "31-12-2013",
  "01-02-2013",
  "31-03-2011",
  "14-04-2010",
];
