import React, { useState, useEffect } from "react";
import { config } from "../config";
import Meta from "components/layout/Meta";
import { client } from "../utils/apiClient";
import InputForm from "components/forms/InputForm";
import TextAreaForm from "components/forms/TextAreaForm";
import SelectFrom from "components/forms/SelectForm";
import DateInputForm from "components/forms/DateInputForm";
import { toast } from "react-toastify";
import { getParsedDateInput } from "utils/customDateParser";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton, Loader, FieldsWrapper } from "components/lib";
import "../styles/validatedln.css";
import ValidateDlnGraph from "components/validateDln";
toast.configure();

const ValidateDln = () => {
  const pageTitle = "Driver Licence Check";
  const [dl, setDl] = useState("");

  const [foreName, setForeName] = useState("");
  const [middleNames, setMiddleNames] = useState("");
  const [surname, setSurname] = useState("");
  const [nameValidationMessage, setNameValidationMessage] = useState("");
  const [dlnRequired, setDlnRequired] = useState("");
  const [dlnValidation, setDlnValidation] = useState("");
  const [errorFetching, setErrorFetching] = useState("");

  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [loading, setLoading] = useState(false);
  const [jsonResponse, setJsonResponse] = useState(null);
  const [validDobDate, setValidDobDate] = useState("");
  const handleSubmit = async event => {
    event.preventDefault();
    setNameValidationMessage("");
    setDlnRequired("");
    setDlnValidation("");
    setErrorFetching("");
    if (!dl) {
      setDlnRequired("Driver licence is required field!");
    } else if (dl.length !== 8 && dl.length !== 16 && dl.length !== 18) {
      setDlnRequired("Licence must be 8, 16 or 18 characters");
    } else {
      setLoading(true);

      try {
        const {
          validateDln: { apiUrl: API_URL },
        } = config;

        const apiResponse = await client(API_URL, {
          data: {
            dln: dl,
            title: "Mr",
            firstname: foreName,
            middlename: middleNames,
            surname,
            suffix: "",
            dob,
            gender,
          },
        });

        const { result, message } = apiResponse;

        if (result) setJsonResponse(apiResponse);
        else setDlnValidation(message || "Validation Failed!");
        setJsonResponse(apiResponse);
      } catch (err) {
        setErrorFetching(err.message || "Error While Fetching");
      } finally {
        setLoading(false);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    if (dob && dob.length === 10 && dob[2] === "-" && dob[5] === "-") {
      setValidDobDate(dob);
    }
  }, [dob]);

  const handleDateChange = event => {
    event.preventDefault();
    const currentInput = event.target.value;
    if (currentInput && dob.length < 10) {
      const parsedValue = getParsedDateInput(dob, currentInput);
      setDob(parsedValue);
    }
  };

  const handleKeyEvent = ({ key }) => {
    if (key === "Backspace" || key === "Delete") setDob("");
  };
  const handleBlur = () => {
    if (validDobDate) {
      const [dd, mm, yyyy] = dob.split("-");
      const stringDate = new Date([mm, dd, yyyy].join("-")).toDateString();
      const [day, month, date, year] = stringDate.split(" ");
      const first = date[0] === "0" ? "" : date[0];
      const second = parseInt(date[1]);
      const append = second > 2 ? "th" : second === 2 ? "nd" : "st";
      const formatedDte = first ? first + second + append : second + append;
      setDob([day, month, formatedDte, year].join(" "));
    } else setDob("Invalid Date");
  };
  const focusHandle = () => {
    setDob(validDobDate);
  };

  const handleAnother = () => {
    setJsonResponse(null);
    setDl("");
    setForeName("");
    setMiddleNames("");
    setSurname("");
    setDob("");
    setGender("");
    setValidDobDate("");
    setDlnValidation("");
    setNameValidationMessage("");
    setDlnRequired("");
    setErrorFetching("");
  };

  return (
    <div className="driver-license-page-wrapper">
      <Meta title={pageTitle} />
      {jsonResponse === null ? (
        <form className="mt-3" onSubmit={handleSubmit}>
          <FieldsWrapper>
            <InputForm
              type={"text"}
              placeholder={"Driving Licence Number"}
              value={dl}
              handleChange={event => setDl(event.target.value)}
              autoFocus
            />

            <InputForm
              type={"text"}
              placeholder={"Forename"}
              value={foreName}
              handleChange={event => setForeName(event.target.value)}
            />
            <InputForm
              type={"text"}
              placeholder={"Middle Name(s)"}
              value={middleNames}
              handleChange={event => setMiddleNames(event.target.value)}
            />
            <InputForm
              type={"text"}
              placeholder={"Surname"}
              value={surname}
              handleChange={event => setSurname(event.target.value)}
            />
            <DateInputForm
              type={"text"}
              placeholder={"Date of Birth"}
              value={dob}
              handleChange={handleDateChange}
              handleKeyEvent={handleKeyEvent}
              focusHandle={focusHandle}
              handleBlur={handleBlur}
            />
            <SelectFrom
              value={gender}
              handleChange={event => setGender(event.target.value)}
              title="Gender"
              data={["MALE", "FEMALE"]}
            />
          </FieldsWrapper>
          <div className="btn-container">
            <LoadingButton type="submit" className="btn-validate mt-2">
              {loading ? <Loader /> : "Submit"}
            </LoadingButton>
          </div>
          <div className="name-validate-error">{nameValidationMessage}</div>
          <div className="name-validate-error">{dlnRequired}</div>
          <div className="name-validate-error">{dlnValidation}</div>
          <div className="name-validate-error">{errorFetching}</div>
        </form>
      ) : (
        <div>
          {/* <div className="driver-license-wrapper">
            <div className="protect-input">{dl}</div>
            {foreName === "" && surname === "" && middleNames === "" && surname === "" ? null : (
              <div className="protect-input">
                {foreName} {middleNames} {surname}
              </div>
            )}
            {validDobDate === "" && gender === "" ? null : (
              <div className="gender-date-wrapper">
                {validDobDate === "" ? null : <div className="protect-input">{validDobDate}</div>}
                {gender === "" ? null : <div className="protect-input">{gender}</div>}
              </div>
            )}
            <TextAreaForm jsonValue={jsonResponse} cols={20} rows={10} formLabel="Response" readOnly={true} />
          </div>
          <div className="btn-container">
            <LoadingButton type="submit" onClick={handleFormReload} className="btn-validate mt-4">
              {loading ? <Loader /> : "Another"}
            </LoadingButton>
          </div> */}
          <ValidateDlnGraph apiResponse={jsonResponse} handleAnother={handleAnother} />
        </div>
      )}
    </div>
  );
};

export default ValidateDln;
