import styled from "@emotion/styled";

export const GraphWrapper = styled.div`
  height: 100vh;
  @media (min-width: 993px) {
    height: calc(100vh - 131px) !important;
  }
  .vis-tooltip {
    white-space: normal;
    > div {
      padding: 0 1rem 0.5rem;
      margin-top: -10px;
      color: black;
      max-width: 500px;
      word-break: break-word;
      .title {
        font-weight: 700;
      }
    }
  }
`;

export const RobotWrapper = styled.div`
  position: relative;
  #robot-loading-spinner {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;
