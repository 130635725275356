import React, { useCallback, useState, createContext, useContext, useRef, createRef } from "react";
import { EuiDataGrid } from "@elastic/eui";
import { fake } from "faker";
import "styles/dataTable.css";

const gridRef = createRef();
const DataContext = createContext();
const raw_data = [];

for (let i = 1; i < 100; i++) {
  const email = fake("{{internet.email}}");
  const name = fake("{{name.lastName}}, {{name.firstName}}");
  const suffix = fake("{{name.suffix}}");
  raw_data.push({
    name: {
      formatted: `${name} ${suffix}`,
      raw: name,
    },
    email,
    location: fake("{{address.country}}"),
    date: fake("{{date.past}}"),
    account: fake("{{finance.account}}"),
    amount: fake("${{commerce.price}}"),
    phone: fake("{{phone.phoneNumber}}"),
    version: fake("{{system.semver}}"),
  });
}

const RenderCellValue = ({ rowIndex, columnId, setCellProps }) => {
  const data = useContext(DataContext);

  function getFormatted() {
    return data[rowIndex][columnId].formatted ? data[rowIndex][columnId].formatted : data[rowIndex][columnId];
  }

  return Object.prototype.hasOwnProperty.call(data, rowIndex) ? getFormatted(rowIndex, columnId) : null;
};

const columns = [
  {
    id: "name",
    displayAsText: "Name",
    defaultSortDirection: "asc",
    cellActions: [
      ({ rowIndex, columnId, Component }) => {
        const data = useContext(DataContext);
        return (
          <Component
            onClick={() => alert(`Hi ${data[rowIndex][columnId].raw}`)}
            iconType="heart"
            aria-label={`Say hi to ${data[rowIndex][columnId].raw}!`}
          >
            Say hi
          </Component>
        );
      },
      ({ rowIndex, columnId, Component }) => {
        const data = useContext(DataContext);
        return (
          <Component
            onClick={() => alert(`Bye ${data[rowIndex][columnId].raw}`)}
            iconType="moon"
            aria-label={`Say bye to ${data[rowIndex][columnId].raw}!`}
          >
            Say bye
          </Component>
        );
      },
    ],
  },
  {
    id: "email",
    displayAsText: "Email address",
    initialWidth: 130,
    cellActions: [
      ({ rowIndex, columnId, Component }) => {
        const data = useContext(DataContext);
        return (
          <Component
            onClick={() => alert(data[rowIndex][columnId].raw)}
            iconType="email"
            aria-label={`Send email to ${data[rowIndex][columnId].raw}`}
          >
            Send email
          </Component>
        );
      },
    ],
  },
  {
    id: "location",
    displayAsText: "Location",
  },
  {
    id: "account",
    displayAsText: "Account",
    actions: {
      showHide: { label: "Custom hide label" },
      showMoveLeft: false,
      showMoveRight: false,
      additional: [
        {
          label: "Custom action",
          onClick: () => {},
          iconType: "cheer",
          size: "xs",
          color: "text",
        },
      ],
    },
    cellActions: [
      ({ rowIndex, columnId, Component, isExpanded }) => {
        const data = useContext(DataContext);
        const onClick = isExpanded
          ? () => alert(`Sent money to ${data[rowIndex][columnId]} when expanded`)
          : () => alert(`Sent money to ${data[rowIndex][columnId]} when not expanded`);
        return (
          <Component onClick={onClick} iconType="faceHappy" aria-label={`Send money to ${data[rowIndex][columnId]}`}>
            Send money
          </Component>
        );
      },
    ],
  },
  {
    id: "date",
    displayAsText: "Date",
    defaultSortDirection: "desc",
  },
  {
    id: "amount",
    displayAsText: "Amount",
  },
  {
    id: "phone",
    displayAsText: "Phone",
    isSortable: false,
  },
  {
    id: "version",
    displayAsText: "Version",
    defaultSortDirection: "desc",
    initialWidth: 70,
    isResizable: false,
    actions: false,
  },
];

export const KibanaDataTable = () => {
  // Pagination
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });
  const onChangeItemsPerPage = useCallback(
    pageSize =>
      setPagination(pagination => ({
        ...pagination,
        pageSize,
        pageIndex: 0,
      })),
    [setPagination],
  );
  const onChangePage = useCallback(
    pageIndex => setPagination(pagination => ({ ...pagination, pageIndex })),
    [setPagination],
  );

  // Sorting
  const [sortingColumns, setSortingColumns] = useState([]);
  const onSort = useCallback(
    sortingColumns => {
      setSortingColumns(sortingColumns);
    },
    [setSortingColumns],
  );

  // Column visibility
  const [visibleColumns, setVisibleColumns] = useState(
    columns.map(({ id }) => id), // initialize to the full set of columns
  );

  const onColumnResize = useRef(eventData => {
    // eslint-disable-next-line no-console
    console.log(eventData);
  });

  return (
    <DataContext.Provider value={raw_data}>
      <EuiDataGrid
        aria-label="Percayso data table"
        columns={columns}
        columnVisibility={{ visibleColumns, setVisibleColumns }}
        rowCount={raw_data.length}
        renderCellValue={RenderCellValue}
        inMemory={{ level: "sorting" }}
        sorting={{ columns: sortingColumns, onSort }}
        gridStyle={{
          border: "none",
          rowHover: "highlight",
          header: "underline",
          footer: "overline",
        }}
        pagination={{
          ...pagination,
          pageSizeOptions: [15, 25, 50, 100],
          onChangeItemsPerPage: onChangeItemsPerPage,
          onChangePage: onChangePage,
        }}
        onColumnResize={onColumnResize.current}
        ref={gridRef}
      />
    </DataContext.Provider>
  );
};
