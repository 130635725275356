import React from "react";
import { InputForm } from "components/forms";
import { usePercaysoDataTable } from "hooks/usePercaysoDataTable";
import { Loader } from "components/lib";
import { config } from "config";
import { client } from "utils/apiClient";
import { useDebouncedCallback } from "use-debounce";
import { formatSearchResult } from "utils/formatSearchResult";

const Input = () => {
  const { searchInputValue, setSearchInputValue, handleRefreshBtn, isRefreshBtnLoading, setIsLoading, setApiResponse } =
    usePercaysoDataTable();

  const fetchResult = inputValue => {
    setIsLoading(true);
    try {
      const {
        percayso: { apiUrl: API_URL },
      } = config;
      client(`${API_URL}/search?q=${inputValue}`)
        .then(data => {
          const formattedData = formatSearchResult(data);
          setApiResponse(formattedData);
        })
        .catch(() => {
          setApiResponse([]);
        });
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedLoadOptions = useDebouncedCallback(fetchResult, 500);

  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <InputForm
        type={"text"}
        value={searchInputValue}
        handleChange={event => {
          setSearchInputValue(event.target.value);
          if (event.target.value.length >= 3) {
            debouncedLoadOptions(event.target.value);
          }
        }}
        placeholder={"Search"}
        autoFocus
        isRemoveContainerMargin={true}
      />
      <button
        type="button"
        className="btn-validate ms-2"
        style={{ minWidth: "80px", maxWidth: "80px" }}
        onClick={handleRefreshBtn}
      >
        {isRefreshBtnLoading ? <Loader /> : "Refresh"}
      </button>
    </div>
  );
};

export default Input;
