import { createChatBotMessage } from "react-chatbot-kit";
import ChatWidget from "components/chatBot/chatWidget";
import CoBotAvatar from "components/chatBot/BotAvatar";

const botName = "Percayso";

const config = {
  initialMessages: [createChatBotMessage(`Hi! I'm ${botName}`)],
  customComponents: { botAvatar: props => <CoBotAvatar {...props} /> },
  widgets: [
    {
      widgetName: "chatWidget",
      widgetFunc: props => <ChatWidget {...props} />,
    },
  ],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: "#ae587a",
    },
    chatButton: {
      backgroundColor: "#7c3a74",
    },
  },
};

export default config;
