import React, { createRef, useEffect, useRef, useState } from "react";
import { Meta } from "components/layout";
import { InputForm } from "components/forms";
import { FieldsWrapper, Loader, LoadingButton } from "components/lib";
import "../../styles/validateAddress.css";
import { config } from "../../config";
import { client } from "utils/apiClient";
import ValidateAddressGraph from "components/address/addressGraph";
import SearchDate from "components/address/SearchDate";
import moment from "moment";
import DropDown from "components/address/DropDown";

const ValidateAddress = () => {
  const [apiResponse, setApiResponse] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [nextPage, setNextPage] = useState(0);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [addressValidationMessage, setAddressValidationMessage] = useState("");
  const [fileDate, setFileDate] = useState({ value: "2022-12-20", label: "12-20-2022" });
  const pageTitle = "Validate Address";

  const inputRef = createRef(null);
  const containerRef = createRef(null);
  const dateRef = useRef(null);

  const handleSubmit = async e => {
    e.preventDefault();
    setAddressValidationMessage("");
    setApiResponse({});

    if (!address) {
      setAddressValidationMessage("Address field required!");
      return;
    }

    if (!fileDate) {
      setAddressValidationMessage("File date is required!");
      return;
    }

    setLoading(true);

    const {
      validateAddress: { apiUrl: API_URL },
    } = config;

    const response = await client(API_URL, {
      data: {
        address,
        file_date: moment(fileDate?.value).format("YYYY-MM-DD") ?? null,
        return_picklist: true,
      },
    });

    if (response.status) {
      if (response.status !== "PICKLIST") {
        setSelectedItem(response);
        setNextPage(1);
      } else {
        setApiResponse(response);
      }
    } else setAddressValidationMessage("Something went wrong. Please try again later.");

    setLoading(false);
  };
  const handleAnother = () => {
    setAddress("");
    setNextPage(0);
    setApiResponse({});
  };

  const focusInput = () => {
    if (apiResponse.status === "PICKLIST") {
      inputRef.current.focus();
    }
  };

  const unfocusInput = () => {
    dateRef.current.blur();
  };

  useEffect(() => {
    focusInput();
  }, [apiResponse]);

  useEffect(() => {
    unfocusInput();
    setApiResponse({});
  }, [address]);

  useEffect(() => {
    unfocusInput();
  }, [fileDate]);

  return (
    <div className="address-form-wrapper">
      <Meta title={pageTitle} />
      {nextPage === 0 ? (
        <div>
          <form onSubmit={handleSubmit} className="mt-3">
            <FieldsWrapper className="d-flex align-items-center">
              <div className="position-relative w-100" ref={containerRef}>
                <InputForm
                  inputRef={inputRef}
                  type={"text"}
                  value={address}
                  handleChange={event => setAddress(event.target.value)}
                  placeholder={"Address"}
                  limitCount={255}
                  autoFocus
                  onFocus={() => apiResponse.status === "PICKLIST" && setIsShowDropdown(true)}
                />
                {isShowDropdown ? (
                  <DropDown apiResponse={apiResponse} setNextPage={setNextPage} setSelectedItem={setSelectedItem} />
                ) : null}
              </div>

              <SearchDate
                dateRef={dateRef}
                selectedValue={fileDate}
                setSelectedValue={setFileDate}
                isDisabled={apiResponse.status === "PICKLIST"}
              />
            </FieldsWrapper>
            {apiResponse.status !== "PICKLIST" ? (
              <div className="btn-container">
                <LoadingButton type="submit" className="btn-validate mt-1">
                  {loading ? <Loader /> : "Submit"}
                </LoadingButton>
              </div>
            ) : null}
          </form>
          {addressValidationMessage ? <div className="name-validate-error">{addressValidationMessage}</div> : null}
        </div>
      ) : (
        <ValidateAddressGraph apiResponse={selectedItem} handleAnother={handleAnother} />
      )}
    </div>
  );
};

export default ValidateAddress;
