import classNames from "classnames";
import { MEDIA_QUERY } from "constants/general";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

const InputForm = ({
  type = "text",
  placeholder = "",
  value,
  handleChange,
  autoFocus = false,
  isRequired,
  isLimitValeLength,
  limitCount,
  isRemoveContainerMargin,
  inputRef,
  ...rest
}) => {
  const isSmallScreen = useMediaQuery({
    query: MEDIA_QUERY.SMALL_MOBILE,
  });

  const onChangeHandler = event => {
    if (isLimitValeLength && value.length <= limitCount) {
      handleChange(event);
    }

    if (!isLimitValeLength) {
      handleChange(event);
    }
  };

  return (
    <div
      className={classNames(
        "form-group",
        "w-100",
        { ["mt-3"]: !isSmallScreen && !isRemoveContainerMargin },
        { ["mt-2"]: isSmallScreen && !isRemoveContainerMargin },
      )}
    >
      <input
        type={type}
        placeholder={placeholder}
        className="input-form form-control"
        value={value}
        onChange={onChangeHandler}
        autoFocus={autoFocus}
        required={isRequired}
        ref={inputRef}
        {...rest}
      />
    </div>
  );
};

InputForm.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  isRequired: PropTypes.bool,
  isLimitValeLength: PropTypes.bool,
  limitCount: PropTypes.number,
  isRemoveContainerMargin: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

InputForm.defaultProps = {
  type: "text",
  autoFocus: false,
  isRequired: false,
  isLimitValeLength: true,
  limitCount: 30,
  isRemoveContainerMargin: false,
  inputRef: null,
};

export default InputForm;
