import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import logo from "assest/logo-small.svg";
import PerbusAdmin from "assest/perbus-admin-roundel.svg";
import PerbusLogo from "assest/perbus-logo-roundel.svg";
import PercaysoAdmin from "assest/percayso-admin-roundel.svg";
import PercaysoLogo from "assest/percayso-logo-roundel.svg";
import PilLog from "assest/pil-logo-roundel.svg";
import PilauAdmin from "assest/pilau-admin-roundel.svg";
import PilauLogo from "assest/pilau-logo-roundel.svg";
import PilukAdmin from "assest/piluk-admin-roundel.svg";
import CommunityAdmin from "assest/community-admin-roundel.svg";
import CommunityLogo from "assest/community-logo-roundel.svg";
import { useAuth } from "context/AuthContext";
import { useAsync } from "hooks/useAsync";
import { Meta } from "components/layout";
import { InputForm } from "components/forms";
import { ErrorMessage } from "components/errors";
import { LoadingButton, Loader, FieldsWrapper } from "components/lib";

import "styles/login.css";

function LoginForm({ onSubmit }) {
  const navigate = useNavigate();
  const { isLoading, isError, error, run } = useAsync();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [messages, setMessages] = useState("");

  const handleSubmit = async event => {
    event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userName: username,
        password: password,
      }),
    };

    const loginFlow = await fetch(
      "https://us-central1-percayso-next-cloud-red-login.cloudfunctions.net/portalLogin",
      requestOptions,
    );

    const appAuth = await loginFlow.json();
    const { status, message } = appAuth;

    if (status === 200) {
      await run(
        onSubmit({
          username: username,
          password: password,
        }),
      );
      navigate("/");
    } else {
      setMessages(message);
    }
  };

  return (
    <>
      <form className="mt-3" onSubmit={handleSubmit}>
        <FieldsWrapper>
          <InputForm
            type={"text"}
            placeholder={"Your email address"}
            value={username}
            handleChange={event => setUsername(event.target.value)}
            autoFocus
          />

          <InputForm
            type={"password"}
            placeholder={"Your password"}
            value={password}
            handleChange={event => setPassword(event.target.value)}
          />
        </FieldsWrapper>
        <div className="btn-container">
          <LoadingButton type="submit" className="btn-validate mt-4">
            {isLoading ? <Loader /> : "Login"}
          </LoadingButton>
        </div>
        {isError ? <ErrorMessage error={error} /> : null}
        <div className="login-icons-wrapper">
          <div className="login-icon-wrapper">
            <a target="_blank" href="https://hs.staging.percayso.com" rel="noreferrer">
              <img src={PercaysoLogo} alt="Percayso Logo" />
            </a>
          </div>
          <div className="login-icon-wrapper">
            <a target="_blank" href="https://staging.percayso-inform.com" rel="noreferrer">
              <img src={PilLog} alt="Pil Log" />
            </a>
          </div>
          <div className="login-icon-wrapper">
            <a target="_blank" href="https://staging.percayso-inform.com.au" rel="noreferrer">
              <img src={PilauLogo} alt="Pilau Logo" />
            </a>
          </div>
          <div className="login-icon-wrapper">
            <a target="_blank" href="https://staging.percayso.biz" rel="noreferrer">
              <img src={PerbusLogo} alt="Perbus Logo" />
            </a>
          </div>
          <div className="login-icon-wrapper">
            <a target="_blank" href="https://staging.percayso.me" rel="noreferrer">
              <img src={CommunityLogo} alt="Community Logo" />
            </a>
          </div>
          <div className="login-icon-wrapper">
            <a target="_blank" href="https://app.staging.percayso.com/" rel="noreferrer">
              <img src={PercaysoAdmin} alt="Percayso Admin" />
            </a>
          </div>
          <div className="login-icon-wrapper">
            <a target="_blank" href="https://app.staging.percayso-inform.com" rel="noreferrer">
              <img src={PilukAdmin} alt="Piluk Admin" />
            </a>
          </div>
          <div className="login-icon-wrapper">
            <a target="_blank" href="https://app.staging.percayso-inform.com.au" rel="noreferrer">
              <img src={PilauAdmin} alt="Pilau Admin" />
            </a>
          </div>

          <div className="login-icon-wrapper">
            <a target="_blank" href="https://app.staging.percayso.biz" rel="noreferrer">
              <img src={PerbusAdmin} alt="Perbus Admin" />
            </a>
          </div>
          <div className="login-icon-wrapper">
            <a target="_blank" href="https://app.staging.percayso.me" rel="noreferrer">
              <img src={CommunityAdmin} alt="Community Admin" />
            </a>
          </div>
        </div>
      </form>
      <span className="message">{messages}</span>
    </>
  );
}

const Login = () => {
  // page content
  const pageTitle = "Login";

  const { login } = useAuth();
  return (
    <div className="login-wrapper">
      <Meta title={pageTitle} />
      <div className="img-wrapper">
        <img className="" src={logo} alt="percayso" />
      </div>
      <div>
        <Meta title={pageTitle} />
        <LoginForm onSubmit={login} />
        <span className="message d-none"></span>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Login;
