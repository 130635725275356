import React, { useState } from "react";
import { Meta } from "components/layout";
import { Loader, LoadingButton } from "components/lib";
import "../../styles/validateAddress.css";
import { API_URL_BY_QUOTE_TYPES, QUOTE_TYPES } from "config";
import { client } from "utils/apiClient";
import { Container, TextArea, TextAreaContainer } from "components/graphUI/GraphContentContainer.styles";

import { mockData } from "pages/QuotezoneServices/mock";
import { stringify } from "utils/stringify";
import tryParseJSONObject from "utils/tryParseJSONObject";

const options = [
  {
    value: QUOTE_TYPES.CAR_QUOTES,
    label: "Get Car Quotes",
    needQuery: true,
    api: API_URL_BY_QUOTE_TYPES.CAR_QUOTES,
  },
];

const GetQuotesFromMoneySuperMarketPage = () => {
  const [sourceData, setSourceData] = useState(stringify(mockData));
  const [apiResponse, setApiResponse] = useState(null);
  const [source, setSource] = useState(options[0]);
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const pageTitle = "Get Quotes From Compare The Market";
  const [isShowError, setIsShowError] = useState(false);
  const [isShowInvalidJsonError, setIsShowInvalidJsonError] = useState(false);
  const [isShowInvalidJsonErrMsg, setIsShowInvalidJsonErrMsg] = useState(false);
  const [showRes, setShowRes] = useState(false);

  const getQuoteHandler = async () => {
    setIsShowError(false);
    setIsShowInvalidJsonError(false);
    setIsShowInvalidJsonErrMsg(false);
    if (!sourceData) {
      setIsShowError(true);
      return;
    }

    const data = tryParseJSONObject(sourceData);

    if (!data) {
      setIsShowInvalidJsonError(true);
      return;
    }

    // const checkResult = checkJsonIsValid(data);
    const checkResult = false;

    if (checkResult !== false) {
      setIsShowInvalidJsonErrMsg(checkResult);
      return;
    }
    setLoading(true);
    try {
      const apiResponse = await client(source?.api, {
        query: {
          source: "money_supermarket",
        },
        data,
      });

      setApiResponse(apiResponse);
      setValidationMessage("");
      setIsShowInvalidJsonError(false);
      setIsShowInvalidJsonErrMsg("");
      setIsShowError(false);
      setShowRes(true);
    } catch (e) {
      setValidationMessage("Getting quotes from Money Supermarket failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="address-form-wrapper">
      <Meta title={pageTitle} />
      <div>
        {!showRes && (
          <>
            <Container className="w-100 d-flex justify-content-center pb-5">
              <TextAreaContainer className="text-center">
                <TextArea
                  className="w-100 h-100 rounded-3 p-2 form-control"
                  placeholder="Data"
                  value={(sourceData && sourceData) || ""}
                  onChange={event => setSourceData(event.target.value)}
                />
                {isShowError && <p className="name-validate-error">The graph data is required field!</p>}
                {isShowInvalidJsonError && <p className="name-validate-error">Invalid JSON format.</p>}
                {isShowInvalidJsonErrMsg && <p className="name-validate-error">{isShowInvalidJsonErrMsg}</p>}
                <div className="btn-container">
                  <LoadingButton className="mt-3 mx-auto text-center d-block btn-validate" onClick={getQuoteHandler}>
                    {loading ? <Loader /> : "Submit"}
                  </LoadingButton>
                </div>
              </TextAreaContainer>
            </Container>
            <div className="name-validate-error">{validationMessage}</div>
          </>
        )}
        {apiResponse && showRes && (
          <TextArea
            className="w-100 h-100 rounded-3 p-2 form-control"
            style={{ minHeight: "800px" }}
            placeholder="Data"
            value={JSON.stringify(apiResponse, undefined, 3)}
            // onChange={event => setTextAreaValue(event.target.value)}
          />
        )}
      </div>
    </div>
  );
};

export default GetQuotesFromMoneySuperMarketPage;
