/* eslint-disable react/prop-types */
import { useDndContext } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { useMountStatus } from "hooks/useMountStatus";
import React from "react";
import { getShouldCombine } from "utils/getShouldCombine";
import { Item } from "./Item";

const SortableItem = ({
  disabled,
  id,
  data,
  index,
  handle,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
}) => {
  const {
    setNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
    items,
    newIndex,
    active,
  } = useSortable({
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;
  const { collisions } = useDndContext();
  const shouldCombine = getShouldCombine(id, items, newIndex, collisions, active);

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      value={id}
      data={data}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
        shouldCombine,
      })}
      color={undefined}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
    />
  );
};

export default SortableItem;
