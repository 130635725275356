import React, { useCallback, useEffect, useState } from "react";
import { config } from "config";
import { useIsShowNavBar } from "hooks/useIsShowNavBar";
import { RadialChart, makeVisFlexible } from "react-vis";
import { client } from "utils/apiClient";
import ChartContainer from "./ChartContainer";
import "./PieChart.style.scss";

const PieChart = () => {
  const { setIsShowNavBar } = useIsShowNavBar();
  const [chartData, setChartData] = useState([]);
  const [setIsLoading, setSetIsLoading] = useState(false);

  const formatGraphData = data => {
    return data.map(item => ({
      angle: item.count,
      label: item.category,
    }));
  };

  const fetchChartData = useCallback(async () => {
    setSetIsLoading(true);
    try {
      const {
        percayso: { apiUrl: API_URL },
      } = config;
      const data = await client(`${API_URL}/count?format=true`);
      setChartData(formatGraphData(data));
      setSetIsLoading(false);
    } catch (err) {
      setSetIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchChartData();
    setIsShowNavBar(false);
    return () => {
      setIsShowNavBar(true);
    };
  }, []);

  const FlexRadialChart = makeVisFlexible(RadialChart);
  return (
    <ChartContainer width="90%" height="90%" title="HubSpot Data Summary by Object">
      <FlexRadialChart
        data={chartData}
        labelsRadiusMultiplier={0.6}
        labelsStyle={{
          fontSize: 16,
        }}
        style={{
          display: "flex",
        }}
        showLabels
      />
    </ChartContainer>
  );
};

export default PieChart;
