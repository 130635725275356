import { Loader } from "components/lib";
import { usePercaysoDataTable } from "hooks/usePercaysoDataTable";
import React from "react";
import Input from "./Input";
import SearchItems from "./SearchItems";
import "./SearchPopoverContent.style.css";
import StartTypingToSearch from "./StartTypingToSearch";
import PropTypes from "prop-types";

const SearchPopoverContent = ({ setIsPopoverOpen }) => {
  const { apiResponse, isLoading } = usePercaysoDataTable();

  return (
    <div className="bg-tapestry mt-3 rounded me-2 border border-2 overflow-auto" style={{ width: 400, height: 400 }}>
      <div className="px-1 py-2 bg-tapestry-dark-2">
        <Input />
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center loading_box">
          <Loader />
        </div>
      ) : (
        <>{apiResponse.length ? <SearchItems setIsPopoverOpen={setIsPopoverOpen} /> : <StartTypingToSearch />}</>
      )}
    </div>
  );
};

SearchPopoverContent.propTypes = {
  setIsPopoverOpen: PropTypes.func.isRequired,
};

export default SearchPopoverContent;
