/* eslint-disable react/prop-types */
import React from "react";

const GetCardFeature = ({ feature }) => {
  if (feature.included) {
    return (
      <div className="badge_container">
        <div className="badge_feature text-center rounded-circle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="m-2"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </div>
        <p className="fw-bold badge_title">Included</p>
      </div>
    );
  }

  if (feature.hasDefaqtoCertifiedUpgrade) {
    return (
      <div className="badge_container">
        <div className="badge_excluded text-center rounded-circle">
          <svg
            data-v-69d7a821=""
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#3fa534"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line> <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
        </div>
        <p className="badge_title">Available to add</p>
      </div>
    );
  }

  return (
    <div className="badge_container">
      <div className="badge_light text-center rounded-circle">
        <svg
          data-v-69d7a821=""
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="m-2"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line> <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
      <p className="badge_title">Not available</p>
    </div>
  );
};

export default GetCardFeature;
