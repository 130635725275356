import React from "react";
import { boxRobotIconsData } from "data/iconsData";
import SliderBox from "components/quote/SliderBox";

const pageTitle = "Robots";
const boxTitle = "Robots";

const Robots = () => {
  return (
    <SliderBox pageTitle={pageTitle} icons={boxRobotIconsData.slice(0, boxRobotIconsData.length)} boxTitle={boxTitle} />
  );
};

export default Robots;
