import { DataSet } from "vis-network/standalone/esm/vis-network";
import { GraphColor, GraphGroup } from "constants/general";

var groupNodes = {};

const topCenterEvenChildren = (nodesPerSide, children, parent, nodeData, edgeData) => {
  let left = nodesPerSide;
  let right = nodesPerSide;
  let requireHiddenNodes = children.length > 1;
  let hiddenNodeIds = {
    center: "",
    left: [],
    right: [],
  };
  //center hidden node
  if (requireHiddenNodes) {
    nodeData.push({
      id: "hiddenNode_" + parent.angle,
      value: 2,
      label: "",
      x: children[0].level * 165 * Math.sin((Math.PI * 2 * parent.angle) / 360),
      y: children[0].level * 165 * -Math.cos((Math.PI * 2 * parent.angle) / 360),
      shape: "custom",
      ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
        return {
          drawNode() {
            ctx.fillStyle = "grey";
            ctx.fillRect(x, y, 0, 0);
          },
        };
      },
    });
    edgeData.push({
      from: parent.id,
      to: "hiddenNode_" + parent.angle,
    });
    hiddenNodeIds.center = "hiddenNode_" + parent.angle;
  }
  let newParent;
  children.map(item => {
    let extra;
    let x;
    let y;
    //left side
    if (left > 0) {
      if (requireHiddenNodes) {
        if (left % 2 !== 0) {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 165 * Math.sin((Math.PI * 2 * (parent.angle - left * 12)) / 360),
            y: item.level * 165 * -Math.cos((Math.PI * 2 * (parent.angle - left * 12)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
        } else {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 165 * Math.sin((Math.PI * 2 * (parent.angle - left * 16)) / 360),
            y: item.level * 165 * -Math.cos((Math.PI * 2 * (parent.angle - left * 16)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
        }
        hiddenNodeIds.left.push("hiddenNode_" + parent.angle + item.label);
        extra = 0.5;
      }
      let r = (item.level + extra) * 155;

      let lineAngle;

      if (left % 2 !== 0) {
        lineAngle = 12;
      } else {
        lineAngle = 16;
      }

      x = r * Math.sin((Math.PI * 2 * (parent.angle - left * lineAngle)) / 360);
      y = r * -Math.cos((Math.PI * 2 * (parent.angle - left * lineAngle)) / 360);
      newParent = {
        id: item.id,
        x: x,
        y: y,
        angle: parent.angle - left * lineAngle,
      };
      nodeData.push({
        id: item.id,
        value: 2,
        label: item.label,
        x: x,
        y: y,
        color: {
          background: item.color,
        },
        font: {
          color: item?.textColor ? item?.textColor : GraphColor.white,
        },
      });
      edgeData.push({
        from: "hiddenNode_" + parent.angle + item.label,
        to: item.id,
      });
      if (!groupNodes[item.group]) {
        groupNodes[item.group] = {
          left: {
            x: x,
            y: y,
          },
          right: {
            x: x,
            y: y,
          },
        };
      } else {
        if (x < groupNodes[item.group].left.x) {
          groupNodes[item.group].left = {
            x: x,
            y: y,
          };
        }
        if (x > groupNodes[item.group].right.x) {
          groupNodes[item.group].right = {
            x: x,
            y: y,
          };
        }
      }
      left--;
    } else if (right > 0) {
      if (requireHiddenNodes) {
        if (right % 2 === 0) {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 165 * Math.sin((Math.PI * 2 * (parent.angle + right * 16)) / 360),
            y: item.level * 165 * -Math.cos((Math.PI * 2 * (parent.angle + right * 16)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
        } else {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 165 * Math.sin((Math.PI * 2 * (parent.angle + right * 12)) / 360),
            y: item.level * 165 * -Math.cos((Math.PI * 2 * (parent.angle + right * 12)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
        }
        hiddenNodeIds.right.push("hiddenNode_" + parent.angle + item.label);
        extra = 0.5;
      }
      let r = (item.level + extra) * 155;
      let lineAngle;

      if (right % 2 !== 0) {
        lineAngle = 12;
      } else {
        lineAngle = 16;
      }

      x = r * Math.sin((Math.PI * 2 * (parent.angle + right * lineAngle)) / 360);
      y = r * -Math.cos((Math.PI * 2 * (parent.angle + right * lineAngle)) / 360);
      newParent = {
        id: item.id,
        x: x,
        y: y,
        angle: parent.angle + right * lineAngle,
      };
      nodeData.push({
        id: item.id,
        value: 2,
        label: item.label,
        x: x,
        y: y,
        color: {
          background: item.color,
        },
        font: {
          color: item?.textColor ? item?.textColor : GraphColor.white,
        },
      });
      edgeData.push({
        from: "hiddenNode_" + parent.angle + item.label,
        to: item.id,
      });
      if (!groupNodes[item.group]) {
        groupNodes[item.group] = {
          left: {
            x: x,
            y: y,
          },
          right: {
            x: x,
            y: y,
          },
        };
      } else {
        if (x < groupNodes[item.group].left.x) {
          groupNodes[item.group].left = {
            x: x,
            y: y,
          };
        }
        if (x > groupNodes[item.group].right.x) {
          groupNodes[item.group].right = {
            x: x,
            y: y,
          };
        }
      }
      right--;
    }
    if (item.child && item.child.length > 0) {
      if (item.child.length % 2 === 0) {
        let nodesPerSide = item.child.length / 2;
        topCenterEvenChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
      } else {
        let nodesPerSide = (item.child.length - 1) / 2;
        topCenterOddChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
      }
    }
  });
  if (hiddenNodeIds.left.length > 0) {
    hiddenNodeIds.left.map((item, index) => {
      if (index > 0) {
        edgeData.push({
          from: hiddenNodeIds.left[index - 1],
          to: hiddenNodeIds.left[index],
          smooth: { type: "curvedCW" },
        });
      }
    });
  }
  if (hiddenNodeIds.right.length > 0) {
    hiddenNodeIds.right.map((item, index) => {
      if (index > 0) {
        edgeData.push({
          from: hiddenNodeIds.right[index - 1],
          to: hiddenNodeIds.right[index],
          smooth: { type: "curvedCCW" },
        });
      }
    });
  }
  if (hiddenNodeIds.center !== "" && hiddenNodeIds.left.length > 0) {
    edgeData.push({
      from: hiddenNodeIds.center,
      to: hiddenNodeIds.left[hiddenNodeIds.left.length - 1],
      smooth: {
        type: "curvedCCW",
      },
    });
  }
  if (hiddenNodeIds.center !== "" && hiddenNodeIds.right.length > 0) {
    edgeData.push({
      from: hiddenNodeIds.center,
      to: hiddenNodeIds.right[hiddenNodeIds.right.length - 1],
      smooth: {
        type: "curvedCW",
      },
    });
  }
};

const evenChildren = (nodesPerSide, children, parent, nodeData, edgeData) => {
  let left = nodesPerSide;
  let right = nodesPerSide;
  let requireHiddenNodes = children.length > 1;
  let hiddenNodeIds = {
    center: "",
    left: [],
    right: [],
  };
  //center hidden node
  if (requireHiddenNodes) {
    nodeData.push({
      id: "hiddenNode_" + parent.angle,
      value: 2,
      label: "",
      x: children[0].level * 95 * Math.sin((Math.PI * 2 * parent.angle) / 360),
      y: children[0].level * 95 * -Math.cos((Math.PI * 2 * parent.angle) / 360),
      shape: "custom",
      ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
        return {
          drawNode() {
            ctx.fillStyle = "grey";
            ctx.fillRect(x, y, 0, 0);
          },
        };
      },
    });
    edgeData.push({
      from: parent.id,
      to: "hiddenNode_" + parent.angle,
    });
    hiddenNodeIds.center = "hiddenNode_" + parent.angle;
  }
  let newParent;
  children.map(item => {
    let extra;
    let x;
    let y;
    //left side
    if (left > 0) {
      if (requireHiddenNodes) {
        if (left % 2 !== 0) {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 96 * Math.sin((Math.PI * 2 * (parent.angle - left * 12)) / 360),
            y: item.level * 96 * -Math.cos((Math.PI * 2 * (parent.angle - left * 12)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
        } else {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 96 * Math.sin((Math.PI * 2 * (parent.angle - left * 16)) / 360),
            y: item.level * 96 * -Math.cos((Math.PI * 2 * (parent.angle - left * 16)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
        }
        hiddenNodeIds.left.push("hiddenNode_" + parent.angle + item.label);
        extra = 0.5;
      }
      let r = (item.level + extra) * 98;

      let lineAngle;

      if (left % 2 !== 0) {
        lineAngle = 12;
      } else {
        lineAngle = 16;
      }

      x = r * Math.sin((Math.PI * 2 * (parent.angle - left * lineAngle)) / 360);
      y = r * -Math.cos((Math.PI * 2 * (parent.angle - left * lineAngle)) / 360);
      newParent = {
        id: item.id,
        x: x,
        y: y,
        angle: parent.angle - left * lineAngle,
      };
      nodeData.push({
        id: item.id,
        value: 2,
        label: item.label,
        x: x,
        y: y,
        color: {
          background: item.color,
        },
        font: {
          color: item?.textColor ? item?.textColor : GraphColor.white,
        },
      });
      edgeData.push({
        from: "hiddenNode_" + parent.angle + item.label,
        to: item.id,
      });
      if (!groupNodes[item.group]) {
        groupNodes[item.group] = {
          left: {
            x: x,
            y: y,
          },
          right: {
            x: x,
            y: y,
          },
        };
      } else {
        if (x < groupNodes[item.group].left.x) {
          groupNodes[item.group].left = {
            x: x,
            y: y,
          };
        }
        if (x > groupNodes[item.group].right.x) {
          groupNodes[item.group].right = {
            x: x,
            y: y,
          };
        }
      }
      left--;
    } else if (right > 0) {
      if (requireHiddenNodes) {
        if (right % 2 === 0) {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 96 * Math.sin((Math.PI * 2 * (parent.angle + right * 16)) / 360),
            y: item.level * 96 * -Math.cos((Math.PI * 2 * (parent.angle + right * 16)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
        } else {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 96 * Math.sin((Math.PI * 2 * (parent.angle + right * 12)) / 360),
            y: item.level * 96 * -Math.cos((Math.PI * 2 * (parent.angle + right * 12)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
        }
        hiddenNodeIds.right.push("hiddenNode_" + parent.angle + item.label);
        extra = 0.5;
      }
      let r = (item.level + extra) * 98;
      let lineAngle;

      if (right % 2 !== 0) {
        lineAngle = 12;
      } else {
        lineAngle = 16;
      }

      x = r * Math.sin((Math.PI * 2 * (parent.angle + right * lineAngle)) / 360);
      y = r * -Math.cos((Math.PI * 2 * (parent.angle + right * lineAngle)) / 360);
      newParent = {
        id: item.id,
        x: x,
        y: y,
        angle: parent.angle + right * lineAngle,
      };
      nodeData.push({
        id: item.id,
        value: 2,
        label: item.label,
        x: x,
        y: y,
        color: {
          background: item.color,
        },
        font: {
          color: item?.textColor ? item?.textColor : GraphColor.white,
        },
      });
      edgeData.push({
        from: "hiddenNode_" + parent.angle + item.label,
        to: item.id,
      });
      if (!groupNodes[item.group]) {
        groupNodes[item.group] = {
          left: {
            x: x,
            y: y,
          },
          right: {
            x: x,
            y: y,
          },
        };
      } else {
        if (x < groupNodes[item.group].left.x) {
          groupNodes[item.group].left = {
            x: x,
            y: y,
          };
        }
        if (x > groupNodes[item.group].right.x) {
          groupNodes[item.group].right = {
            x: x,
            y: y,
          };
        }
      }
      right--;
    }
    if (item.child && item.child.length > 0) {
      if (item.child.length % 2 === 0) {
        let nodesPerSide = item.child.length / 2;
        evenChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
      } else {
        let nodesPerSide = (item.child.length - 1) / 2;
        oddChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
      }
    }
  });
  if (hiddenNodeIds.left.length > 0) {
    hiddenNodeIds.left.map((item, index) => {
      if (index > 0) {
        edgeData.push({
          from: hiddenNodeIds.left[index - 1],
          to: hiddenNodeIds.left[index],
          smooth: { type: "curvedCW" },
        });
      }
    });
  }
  if (hiddenNodeIds.right.length > 0) {
    hiddenNodeIds.right.map((item, index) => {
      if (index > 0) {
        edgeData.push({
          from: hiddenNodeIds.right[index - 1],
          to: hiddenNodeIds.right[index],
          smooth: { type: "curvedCCW" },
        });
      }
    });
  }
  if (hiddenNodeIds.center !== "" && hiddenNodeIds.left.length > 0) {
    edgeData.push({
      from: hiddenNodeIds.center,
      to: hiddenNodeIds.left[hiddenNodeIds.left.length - 1],
      smooth: {
        type: "curvedCCW",
      },
    });
  }
  if (hiddenNodeIds.center !== "" && hiddenNodeIds.right.length > 0) {
    edgeData.push({
      from: hiddenNodeIds.center,
      to: hiddenNodeIds.right[hiddenNodeIds.right.length - 1],
      smooth: {
        type: "curvedCW",
      },
    });
  }
};

const topCenterOddChildren = (nodesPerSide, children, parent, nodeData, edgeData) => {
  let left = nodesPerSide;
  let right = nodesPerSide;
  let requireHiddenNodes = children.length > 1;
  let hiddenNodeIds = {
    center: "",
    left: [],
    right: [],
  };
  //center hidden node
  if (requireHiddenNodes) {
    nodeData.push({
      id: "hiddenNode_" + parent.angle,
      value: 2,
      label: "",
      x: children[0].level * 168 * Math.sin((Math.PI * 2 * parent.angle) / 360),
      y: children[0].level * 168 * -Math.cos((Math.PI * 2 * parent.angle) / 360),
      shape: "custom",
      ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
        return {
          drawNode() {
            ctx.fillStyle = "grey";
            ctx.fillRect(x, y, 0, 0);
          },
        };
      },
    });
    hiddenNodeIds.center = "hiddenNode_" + parent.angle;
  }
  let newParent;
  children.map((item, index) => {
    let extra;
    let x;
    let y;
    //center node
    if (index === 0) {
      let r;
      if (item.level > 2) {
        let extra = 0.5;
        r = (item.level + extra) * 138;
      } else {
        r = (item.level + 0.5) * 155;
      }
      x = r * Math.sin((Math.PI * 2 * parent.angle) / 360);
      y = r * -Math.cos((Math.PI * 2 * parent.angle) / 360);
      newParent = {
        id: item.id,
        x: x,
        y: y,
        angle: parent.angle,
      };
      nodeData.push({
        id: item.id,
        value: 2,
        label: item.label,
        x: x,
        y: y,
        color: {
          background: item.color,
        },
        font: {
          color: item?.textColor ? item?.textColor : GraphColor.white,
        },
      });
      edgeData.push({
        from: parent.id,
        to: item.id,
      });
      if (!groupNodes[item.group]) {
        groupNodes[item.group] = {
          left: {
            x: x,
            y: y,
          },
          right: {
            x: x,
            y: y,
          },
        };
      } else {
        if (x < groupNodes[item.group].left.x) {
          groupNodes[item.group].left = {
            x: x,
            y: y,
          };
        }
        if (x > groupNodes[item.group].right.x) {
          groupNodes[item.group].right = {
            x: x,
            y: y,
          };
        }
      }
    } else {
      // left side
      if (left > 0) {
        if (requireHiddenNodes) {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 165 * Math.sin((Math.PI * 2 * (parent.angle - left * 15)) / 360),
            y: item.level * 165 * -Math.cos((Math.PI * 2 * (parent.angle - left * 15)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
          hiddenNodeIds.left.push("hiddenNode_" + parent.angle + item.label);
          extra = 0.5;
        }
        let r = (item.level + extra) * 155;
        let lineAngle = 15;

        x = r * Math.sin((Math.PI * 2 * (parent.angle - left * lineAngle)) / 360);
        y = r * -Math.cos((Math.PI * 2 * (parent.angle - left * lineAngle)) / 360);
        newParent = {
          id: item.id,
          x: x,
          y: y,
          angle: parent.angle - left * lineAngle,
        };
        nodeData.push({
          id: item.id,
          value: 2,
          label: item.label,
          x: x,
          y: y,
          color: {
            background: item.color,
          },
          font: {
            color: item?.textColor ? item?.textColor : GraphColor.white,
          },
        });
        edgeData.push({
          from: "hiddenNode_" + parent.angle + item.label,
          to: item.id,
        });
        if (!groupNodes[item.group]) {
          groupNodes[item.group] = {
            left: {
              x: x,
              y: y,
            },
            right: {
              x: x,
              y: y,
            },
          };
        } else {
          if (x < groupNodes[item.group].left.x) {
            groupNodes[item.group].left = {
              x: x,
              y: y,
            };
          }
          if (x > groupNodes[item.group].right.x) {
            groupNodes[item.group].right = {
              x: x,
              y: y,
            };
          }
        }
        left--;
      } else if (right > 0) {
        if (requireHiddenNodes) {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 165 * Math.sin((Math.PI * 2 * (parent.angle + right * 15)) / 360),
            y: item.level * 165 * -Math.cos((Math.PI * 2 * (parent.angle + right * 15)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
          hiddenNodeIds.right.push("hiddenNode_" + parent.angle + item.label);
          extra = 0.5;
        }
        let r = (item.level + extra) * 155;
        let lineAngle = 15;

        x = r * Math.sin((Math.PI * 2 * (parent.angle + right * lineAngle)) / 360);
        y = r * -Math.cos((Math.PI * 2 * (parent.angle + right * lineAngle)) / 360);
        newParent = {
          id: item.id,
          x: x,
          y: y,
          angle: parent.angle + right * lineAngle,
        };
        nodeData.push({
          id: item.id,
          value: 2,
          label: item.label,
          x: x,
          y: y,
          color: {
            background: item.color,
          },
          font: {
            color: item?.textColor ? item?.textColor : GraphColor.white,
          },
        });
        edgeData.push({
          from: "hiddenNode_" + parent.angle + item.label,
          to: item.id,
        });
        if (!groupNodes[item.group]) {
          groupNodes[item.group] = {
            left: {
              x: x,
              y: y,
            },
            right: {
              x: x,
              y: y,
            },
          };
        } else {
          if (x < groupNodes[item.group].left.x) {
            groupNodes[item.group].left = {
              x: x,
              y: y,
            };
          }
          if (x > groupNodes[item.group].right.x) {
            groupNodes[item.group].right = {
              x: x,
              y: y,
            };
          }
        }
        right--;
      }
    }
    if (item.child && item.child.length > 0) {
      if (item.child.length % 2 === 0) {
        let nodesPerSide = item.child.length / 2;
        topCenterEvenChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
      } else {
        let nodesPerSide = (item.child.length - 1) / 2;
        topCenterOddChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
      }
    }
  });
  if (hiddenNodeIds.left.length > 0) {
    hiddenNodeIds.left.map((item, index) => {
      if (index > 0) {
        edgeData.push({
          from: hiddenNodeIds.left[index - 1],
          to: hiddenNodeIds.left[index],
          smooth: { type: "dynamic" },
        });
      }
    });
  }
  if (hiddenNodeIds.right.length > 0) {
    hiddenNodeIds.right.map((item, index) => {
      if (index > 0) {
        edgeData.push({
          from: hiddenNodeIds.right[index - 1],
          to: hiddenNodeIds.right[index],
          smooth: { type: "dynamic" },
        });
      }
    });
  }
  if (hiddenNodeIds.center !== "" && hiddenNodeIds.left.length > 0) {
    edgeData.push({
      from: hiddenNodeIds.center,
      to: hiddenNodeIds.left[hiddenNodeIds.left.length - 1],
      smooth: {
        type: "curvedCCW",
      },
    });
  }
  if (hiddenNodeIds.center !== "" && hiddenNodeIds.right.length > 0) {
    edgeData.push({
      from: hiddenNodeIds.center,
      to: hiddenNodeIds.right[hiddenNodeIds.right.length - 1],
      smooth: { type: "curvedCW" },
    });
  }
};

const oddChildren = (nodesPerSide, children, parent, nodeData, edgeData) => {
  let left = nodesPerSide;
  let right = nodesPerSide;
  let requireHiddenNodes = children.length > 1;
  let hiddenNodeIds = {
    center: "",
    left: [],
    right: [],
  };
  //center hidden node
  if (requireHiddenNodes) {
    nodeData.push({
      id: "hiddenNode_" + parent.angle,
      value: 2,
      label: "",
      x: children[0].level * 92 * Math.sin((Math.PI * 2 * parent.angle) / 360),
      y: children[0].level * 92 * -Math.cos((Math.PI * 2 * parent.angle) / 360),
      shape: "custom",
      ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
        return {
          drawNode() {
            ctx.fillStyle = "grey";
            ctx.fillRect(x, y, 0, 0);
          },
        };
      },
    });
    hiddenNodeIds.center = "hiddenNode_" + parent.angle;
  }
  let newParent;
  children.map((item, index) => {
    let extra;
    let x;
    let y;
    //center node
    if (index === 0) {
      let r;
      if (item.level > 2) {
        let extra = 0.5;
        r = (item.level + extra) * 98;
      } else {
        r = (item.level + 0.5) * 94;
      }
      x = r * Math.sin((Math.PI * 2 * parent.angle) / 360);
      y = r * -Math.cos((Math.PI * 2 * parent.angle) / 360);
      newParent = {
        id: item.id,
        x: x,
        y: y,
        angle: parent.angle,
      };
      nodeData.push({
        id: item.id,
        value: 2,
        label: item.label,
        x: x,
        y: y,
        color: {
          background: item.color,
        },
        font: {
          color: item?.textColor ? item?.textColor : GraphColor.white,
        },
      });
      edgeData.push({
        from: parent.id,
        to: item.id,
      });
      if (!groupNodes[item.group]) {
        groupNodes[item.group] = {
          left: {
            x: x,
            y: y,
          },
          right: {
            x: x,
            y: y,
          },
        };
      } else {
        if (x < groupNodes[item.group].left.x) {
          groupNodes[item.group].left = {
            x: x,
            y: y,
          };
        }
        if (x > groupNodes[item.group].right.x) {
          groupNodes[item.group].right = {
            x: x,
            y: y,
          };
        }
      }
    } else {
      // left side
      if (left > 0) {
        if (requireHiddenNodes) {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 92 * Math.sin((Math.PI * 2 * (parent.angle - left * 25)) / 360),
            y: item.level * 92 * -Math.cos((Math.PI * 2 * (parent.angle - left * 25)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
          hiddenNodeIds.left.push("hiddenNode_" + parent.angle + item.label);
          extra = 0.5;
        }
        let r = (item.level + extra) * 96;
        let lineAngle = 24;

        x = r * Math.sin((Math.PI * 2 * (parent.angle - left * lineAngle)) / 360);
        y = r * -Math.cos((Math.PI * 2 * (parent.angle - left * lineAngle)) / 360);
        newParent = {
          id: item.id,
          x: x,
          y: y,
          angle: parent.angle - left * lineAngle,
        };
        nodeData.push({
          id: item.id,
          value: 2,
          label: item.label,
          x: x,
          y: y,
          color: {
            background: item.color,
          },
          font: {
            color: item?.textColor ? item?.textColor : GraphColor.white,
          },
        });
        edgeData.push({
          from: "hiddenNode_" + parent.angle + item.label,
          to: item.id,
        });
        if (!groupNodes[item.group]) {
          groupNodes[item.group] = {
            left: {
              x: x,
              y: y,
            },
            right: {
              x: x,
              y: y,
            },
          };
        } else {
          if (x < groupNodes[item.group].left.x) {
            groupNodes[item.group].left = {
              x: x,
              y: y,
            };
          }
          if (x > groupNodes[item.group].right.x) {
            groupNodes[item.group].right = {
              x: x,
              y: y,
            };
          }
        }
        left--;
      } else if (right > 0) {
        if (requireHiddenNodes) {
          nodeData.push({
            id: "hiddenNode_" + parent.angle + item.label,
            value: 2,
            label: "",
            x: item.level * 92 * Math.sin((Math.PI * 2 * (parent.angle + right * 25)) / 360),
            y: item.level * 92 * -Math.cos((Math.PI * 2 * (parent.angle + right * 25)) / 360),
            shape: "custom",
            ctxRenderer: function ({ ctx, x, y, state: { selected, hover }, style }) {
              return {
                drawNode() {
                  ctx.fillStyle = "grey";
                  ctx.fillRect(x, y, 0, 0);
                },
              };
            },
          });
          hiddenNodeIds.right.push("hiddenNode_" + parent.angle + item.label);
          extra = 0.5;
        }
        let r = (item.level + extra) * 96;
        let lineAngle = 24;

        x = r * Math.sin((Math.PI * 2 * (parent.angle + right * lineAngle)) / 360);
        y = r * -Math.cos((Math.PI * 2 * (parent.angle + right * lineAngle)) / 360);
        newParent = {
          id: item.id,
          x: x,
          y: y,
          angle: parent.angle + right * lineAngle,
        };
        nodeData.push({
          id: item.id,
          value: 2,
          label: item.label,
          x: x,
          y: y,
          color: {
            background: item.color,
          },
          font: {
            color: item?.textColor ? item?.textColor : GraphColor.white,
          },
        });
        edgeData.push({
          from: "hiddenNode_" + parent.angle + item.label,
          to: item.id,
        });
        if (!groupNodes[item.group]) {
          groupNodes[item.group] = {
            left: {
              x: x,
              y: y,
            },
            right: {
              x: x,
              y: y,
            },
          };
        } else {
          if (x < groupNodes[item.group].left.x) {
            groupNodes[item.group].left = {
              x: x,
              y: y,
            };
          }
          if (x > groupNodes[item.group].right.x) {
            groupNodes[item.group].right = {
              x: x,
              y: y,
            };
          }
        }
        right--;
      }
    }
    if (item.child && item.child.length > 0) {
      if (item.child.length % 2 === 0) {
        let nodesPerSide = item.child.length / 2;
        evenChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
      } else {
        let nodesPerSide = (item.child.length - 1) / 2;
        oddChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
      }
    }
  });
  if (hiddenNodeIds.left.length > 0) {
    hiddenNodeIds.left.map((item, index) => {
      if (index > 0) {
        edgeData.push({
          from: hiddenNodeIds.left[index - 1],
          to: hiddenNodeIds.left[index],
          smooth: { type: "dynamic" },
        });
      }
    });
  }
  if (hiddenNodeIds.right.length > 0) {
    hiddenNodeIds.right.map((item, index) => {
      if (index > 0) {
        edgeData.push({
          from: hiddenNodeIds.right[index - 1],
          to: hiddenNodeIds.right[index],
          smooth: { type: "dynamic" },
        });
      }
    });
  }
  if (hiddenNodeIds.center !== "" && hiddenNodeIds.left.length > 0) {
    edgeData.push({
      from: hiddenNodeIds.center,
      to: hiddenNodeIds.left[hiddenNodeIds.left.length - 1],
      smooth: {
        type: "curvedCCW",
      },
    });
  }
  if (hiddenNodeIds.center !== "" && hiddenNodeIds.right.length > 0) {
    edgeData.push({
      from: hiddenNodeIds.center,
      to: hiddenNodeIds.right[hiddenNodeIds.right.length - 1],
      smooth: { type: "curvedCW" },
    });
  }
};

const checkChild = (children, nodeData, parent, edgeData) => {
  let startDegree = 0;
  let circleDivide = 360 / children.length;
  let newParent;
  children.map((item, i) => {
    let r;
    if (i === 0 && item.group === GraphGroup.BLUE_GROUP) {
      r = item.level * 225;
    } else {
      r = item.level * 130;
    }

    if (item.level === 1) {
      let x = r * Math.sin((Math.PI * 2 * startDegree) / 360);
      let y = r * -Math.cos((Math.PI * 2 * startDegree) / 360);
      startDegree += circleDivide;
      newParent = {
        id: item.id,
        x: x,
        y: y,
        angle: startDegree - circleDivide,
      };
      nodeData.push({
        id: item.id,
        value: 4,
        label: item?.image ? "" : item?.label,
        x: x,
        y: y,
        shape: item?.image ? "circularImage" : "circle",
        image: item?.image,
        color: {
          background: item?.color,
        },
        font: {
          color: item?.textColor ? item?.textColor : GraphColor.white,
        },
        borderWidth: item?.image ? 4.5 : 0,
      });
      edgeData.push({
        from: parent.id,
        to: item.id,
      });
      if (!groupNodes[item.group]) {
        groupNodes[item.group] = {
          left: {
            x: x,
            y: y,
          },
          right: {
            x: x,
            y: y,
          },
        };
      } else {
        if (x < groupNodes[item.group].left.x) {
          groupNodes[item.group].left = {
            x: x,
            y: y,
          };
        }
        if (x > groupNodes[item.group].right.x) {
          groupNodes[item.group].right = {
            x: x,
            y: y,
          };
        }
      }
      // handle only top center child
      if (i === 0 && item.group === GraphGroup.BLUE_GROUP) {
        if (item.child && item.child.length > 0) {
          if (item.child.length % 2 === 0) {
            let nodesPerSide = item.child.length / 2;
            topCenterEvenChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
          } else {
            let nodesPerSide = (item.child.length - 1) / 2;
            topCenterOddChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
          }
        }
        // handle others child
      } else {
        if (item.child && item.child.length > 0) {
          if (item.child.length % 2 === 0) {
            let nodesPerSide = item.child.length / 2;
            evenChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
          } else {
            let nodesPerSide = (item.child.length - 1) / 2;
            oddChildren(nodesPerSide, item.child, newParent, nodeData, edgeData);
          }
        }
      }
    }
  });
};

export const getGraphData = jsonData => {
  let nodeData = [];
  let edgeData = [];
  //main node
  nodeData.push({
    id: jsonData.id,
    value: 5,
    x: 0,
    y: 0,
    shape: "circularImage",
    image: jsonData.image,
    borderWidth: 4.5,
    borderWidthSelected: 4.5,
    color: {
      border: GraphColor.white,
      background: GraphColor.white,
    },
  });
  //level nodes
  if (jsonData.child && jsonData.child.length > 0) {
    checkChild(jsonData.child, nodeData, { id: jsonData.id, x: 0, y: 0, angle: 0 }, edgeData);
  }

  return {
    node: new DataSet(nodeData),
    edge: new DataSet(edgeData),
  };
};
