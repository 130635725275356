import styled from "@emotion/styled";

export const StyledFolderName = styled.p`
  color: ${props => (props.dark ? "var(--black)" : "var(--white)")};
  margin-top: ${props => (props.insideBox ? "10px" : "0")};
  position: ${props => props.bottomCenter && "absolute"};
  bottom: ${props => props.bottomCenter && "5px"};
  left: ${props => props.bottomCenter && "50%"};
  transform: ${props => props.bottomCenter && "translateX(-50%)"};
  width: 100%;
  line-height: 1.6;
`;
