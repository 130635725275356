/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import "./Card.scss";
import "./review.scss";
import GetCardFeature from "./GetCardFeature";
import CoverAccordionItem from "./CoverAccordionItem";
import FeaturesAccordionItem from "./FeaturesAccordionItem";
import FeesExcessesAndYourInfo from "./FeesExcessesAndYourInfo";
import Modal from "./Modal";
import RatingStar from "./RatingStar";
import CloseBtn from "./CloseBtn";
import CallInfoModal from "./CallInfoModal";
import ExternalLink from "components/common/ExternalLink";

const ModalDetailsBlock = ({ title, subTitle, description }) => (
  <div className="pb-4">
    <p className="m-0 fw-bold">{title}</p>
    <p className="m-0 text-green fw-bold">{subTitle}</p>
    <p className="m-0">{description}</p>
  </div>
);

const ModalYourInfoBlock = ({ title, description }) => (
  <div className="pb-3">
    <p className="m-0 fw-bold">{title}</p>
    <p className="m-0">{description}</p>
  </div>
);

const QuoteCard = ({ data }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [feesModal, setFeesModal] = React.useState(false);
  const [claimsModal, setClaimsModal] = React.useState(false);
  const [excessModal, setExcessModal] = React.useState(false);
  const [demandsModal, setDemandsModal] = React.useState(false);
  const [callInfoModal, setCallInfoModal] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div>
      <Card body className="my-3 mx-auto w-100">
        <Row>
          <Col>
            <img src={data.partnerLogo} alt="logo" />
          </Col>
          {data.allFeatures.slice(0, 4).map((feature, index) => (
            <Col key={index}>
              <GetCardFeature feature={feature} />
            </Col>
          ))}
          <Col>
            {data.instalments.instalmentsAvailable ? (
              <Stack gap={0} className="text-center">
                <p className="m-0 sm_text">{data.instalments.noOfInstalments} monthly payments</p>
                <p className="m-0 fs-4">£355.44</p>
                <div className="d-flex justify-content-between">
                  <p className="m-0 sm_text">First payment</p>
                  <p className="m-0 sm_text fw-bold">£{data.instalments.instalmentsAmount}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="m-0 sm_text">Total payment</p>
                  <p className="m-0 sm_text fw-bold">£{data.instalments.instalmentsTotal}</p>
                </div>
                <p className="sm_text excess_badge mb-2">Total Excess: £{data.totalExcess}</p>
              </Stack>
            ) : (
              <Stack gap={0} className="text-center">
                <p className="m-0 sm_text">Annual price</p>
                <p className="m-0 fs-4">£{data.premium}</p>
                <p className="sm_text excess_badge mb-2">Total Excess: £{data.totalExcess}</p>
              </Stack>
            )}
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Button variant="success" onClick={toggleDrawer}>
              More Info
            </Button>
          </Col>
        </Row>
        {data?._provider ? (
          <div className="text-end">
            <ExternalLink href={data?._provider_url} label={data?._provider} />
          </div>
        ) : null}
      </Card>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        size={471}
        style={{ overflowY: "auto", height: "100vh" }}
      >
        <div className="position-relative h-100 w-100 drawer_container_bg">
          <div className="card_modal_scroll_area">
            <div className="py-2 border-bottom d-flex justify-content-center w-100 drawer_header">
              <img src={data.partnerLogo} alt="logo" />
              <CloseBtn onClose={toggleDrawer} classes="position-absolute top-0 end-0 h-auto" />
            </div>
            <div className="pt-1 px-3 content_container">
              <Card body className="my-3">
                {data.instalments.instalmentsAvailable ? (
                  <>
                    <div className="d-flex justify-content-between align-items-center my-1">
                      <p className="m-0">Annual price without interest charge</p>
                      <p className="m-0">£{data.premium}</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center my-1">
                      <p className="m-0 fs-5">{data.instalments.noOfInstalments} monthly payments</p>
                      <p className="m-0 fs-3 fw-bold">£{data.instalments.instalmentsAmount}</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center my-1">
                      <p className="m-0">First payment</p>
                      <p className="m-0">£{data.instalments.instalmentsDeposit}</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center my-1">
                      <p className="m-0">Total price with interest charge</p>
                      <p className="m-0">£{data.instalments.instalmentsTotal}</p>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between align-items-center my-1">
                    <p className="m-0 fs-5">Total annual payment</p>
                    <p className="m-0 fs-3 fw-bold">£{data.premium}</p>
                  </div>
                )}
                <p className="sm_text text-center px-5 mt-2">
                  Insurers can change their prices at any time so this price might change if you return to buy later
                </p>
                {data.instalments.instalmentsAvailable ? (
                  <>
                    <div className="border_top_light border_bottom_light py-3">
                      <div className="d-flex justify-content-between align-items-center my-1">
                        <p className="m-0 fs-6">Fire and theft excess</p>
                        <p className="m-0 fw-bold fs-6">£{data.fireAndTheftExcess}</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center my-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21px"
                        height="21px"
                        viewBox="0 0 21 21"
                        version="1.1"
                        className="me-2"
                      >
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g
                            id="GoCo-XSP-in-journey-NO---mobile"
                            transform="translate(-55.000000, -683.000000)"
                            fill="#25181D"
                            fillRule="nonzero"
                          >
                            <path
                              d="M65.5,683 C59.7010101,683 55,687.70101 55,693.5 C55,699.29899 59.7010101,704 65.5,704 C71.2989899,704 76,699.29899 76,693.5 C75.9944872,687.703295 71.2967049,683.005513 65.5,683 Z M65.5,702 C60.8055796,702 57,698.19442 57,693.5 C57,688.80558 60.8055796,685 65.5,685 C70.1944204,685 74,688.80558 74,693.5 C73.9944896,698.192136 70.192136,701.99449 65.5,702 Z M67,693 L67,698 C67,698.828427 66.3284271,699.5 65.5,699.5 C64.6715729,699.5 64,698.828427 64,698 L64,693 C64,692.171573 64.6715729,691.5 65.5,691.5 C66.3284271,691.5 67,692.171573 67,693 Z M67,689 C67,689.828427 66.3284271,690.5 65.5,690.5 C64.6715729,690.5 64,689.828427 64,689 C64,688.171573 64.6715729,687.5 65.5,687.5 C66.3284271,687.5 67,688.171573 67,689 Z"
                              id="Shape"
                            />
                          </g>
                        </g>
                      </svg>
                      <p className="sm_text m-0">
                        Insurers may add an additional excess for young or inexperienced drivers.
                      </p>
                    </div>
                  </>
                ) : null}
              </Card>
              <Card body>
                <p className="m-0 mb-1 card_title">Overview</p>
                <div className="d-flex justify-content-between">
                  <p className="m-0 my-1">Vehicle</p>
                  <p className="m-0 my-1 fw-bold">{data.regNo}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="m-0 my-1">Proposer name</p>
                  <p className="m-0 my-1 fw-bold">{data.proposerNameWithoutTitle}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="m-0 my-1">Additional driver(s)</p>
                  <p className="m-0 my-1">{data.additionalDriversWithoutTitle.join()}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="m-0 my-1">Start date</p>
                  <p className="m-0 my-1 fw-bold">{data.coverStartDate}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="m-0 my-1">Cover type</p>
                  <p className="m-0 my-1 fw-bold">{data.coverType}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="m-0 my-1">Protected no claims</p>
                  <p className="m-0 my-1 fw-bold">{data.noClaimsBonusProtection}</p>
                </div>
              </Card>
              <Card body className="my-3">
                <p className="m-0 mb-1 card_title">Cover with this policy</p>
                <Accordion allowZeroExpanded className="mt-2">
                  {data.allFeatures.map((item, index) => (
                    <FeaturesAccordionItem key={index} item={item} />
                  ))}
                  {data.allCoverItems.map((item, index) => (
                    <CoverAccordionItem key={index} item={item} />
                  ))}
                </Accordion>
              </Card>
              <Card body className="my-3">
                <p className="m-0 mb-1 card_title">Fees, excesses and your info</p>
                <FeesExcessesAndYourInfo
                  title="Fees"
                  onClick={() => {
                    setShowModal(true);
                    setFeesModal(true);
                  }}
                />
                <FeesExcessesAndYourInfo
                  title="Claims"
                  onClick={() => {
                    setShowModal(true);
                    setClaimsModal(true);
                  }}
                />
                <FeesExcessesAndYourInfo
                  title="Excess"
                  onClick={() => {
                    setShowModal(true);
                    setExcessModal(true);
                  }}
                />
                <FeesExcessesAndYourInfo
                  title="Demands and needs"
                  onClick={() => {
                    setShowModal(true);
                    setDemandsModal(true);
                  }}
                />
              </Card>
              <Card body className="my-3">
                <div className="reviews__header mb-1">
                  <div className="d-flex justify-content-between">
                    <p className="m-0 mb-1 card_title">Reviews & ratings</p>
                    {data.reviewsRating ? (
                      <div>
                        <div className="rating__stars">
                          {data.reviewsRating.averageRating.map((rating, index) => (
                            <RatingStar key={index} rating={rating} />
                          ))}
                        </div>
                        <p className="m-0 sm_text text-end">on reviews.co.uk</p>
                      </div>
                    ) : null}
                  </div>

                  {data.reviewsRating ? (
                    <p className="fs-6 mt-2">
                      Reviews from our customers who have bought from <strong>Go Girl</strong>.
                    </p>
                  ) : null}
                </div>
                {data.reviewsRating ? (
                  <div>
                    <div className="gauge__container">
                      <p className="fs-6 m-0">Easy to buy</p>
                      <div>
                        <div className="gauge__background">
                          <div className={`gauge__fill ${data.reviewsRating.easyCssClass}`}></div>
                        </div>
                      </div>
                    </div>
                    <div className="gauge__container">
                      <p className="fs-6 m-0">Communication</p>
                      <div>
                        <div className="gauge__background">
                          <div className={`gauge__fill ${data.reviewsRating.communcationCssClass}`}></div>
                        </div>
                      </div>
                    </div>
                    <div className="gauge__container">
                      <p className="fs-6 m-0">Providing documentation</p>
                      <div>
                        <div className="gauge__background">
                          <div className={`gauge__fill ${data.reviewsRating.documentsCssClass}`}></div>
                        </div>
                      </div>
                    </div>
                    <div className="gauge__container">
                      <p className="fs-6 m-0">Claims experience</p>
                      <div>
                        <div className="gauge__background">
                          <div className={`gauge__fill ${data.reviewsRating.claimsCssClass}`}></div>
                        </div>
                      </div>
                    </div>
                    <div className="gauge__container">
                      <p className="fs-6 m-0">Customer service</p>
                      <div>
                        <div className="gauge__background">
                          <div className={`gauge__fill ${data.reviewsRating.customerCssClass}`}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="fs-6">Unfortunately customer reviews are not currently available for this provider.</p>
                )}
              </Card>
            </div>
            <div className="w-100 h-auto p-4 d-flex justify-content-between position-fixed bottom-0 drawer_container_bg border_top_light">
              {data.allowsPurchaseOverPhone ? (
                <Button
                  variant="outline-dark"
                  className="btn-large rounded-pill w-100 m-1"
                  type="button"
                  onClick={() => setCallInfoModal(true)}
                >
                  Click to call
                </Button>
              ) : null}

              {data.allowsPurchaseOnline ? (
                <a
                  href={`https://car.gocompare.com/GeneralHolding/Index/${data.resultId}?pageRank=${data.resultsPageRank}&clickThroughId=${data.clickThroughId}&features=`}
                  className="btn btn-dark btn-large rounded-pill w-100 m-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Proceed to site
                </a>
              ) : null}
            </div>
          </div>
          <Modal
            title="Fees"
            showModal={feesModal}
            handleModalClose={() => {
              setShowModal(false);
              setFeesModal(false);
            }}
          >
            <ModalDetailsBlock
              title="Adjustment fee"
              subTitle={data.adjustmentFee}
              description="If you make a change to your policy, for example changing your address, your car or adding a driver,
                typically, the insurance provider will charge an administration fee for making the change."
            />
            <ModalDetailsBlock
              title="Cancellation fee"
              subTitle={data.cancellationFee}
              description={`If you cancel your policy after the "cooling off period" the insurance provider may charge a cancellation fee to cover its administration costs.`}
            />
            <ModalDetailsBlock
              title="Duplicate documents fee"
              subTitle={data.duplicateDocumentsFee}
              description="If you have lost any of your car insurance documentation, for example the certificate of motor insurance, the insurance provider may charge an administration fee to issue duplicate documents."
            />
            <ModalDetailsBlock
              title="14 day cool off period"
              subTitle={data.coolingOffPeriod}
              description={`If you cancel your policy within the "cooling off period" some insurance providers may charge a fee to cover their administration costs in addition to a charge for the cover provided. Where a fee is charged for the cover provided, this is typically based on "pro - rata", which means that the charge is calculated based on the number of days your insurance has been in force.`}
            />
          </Modal>
          <Modal
            title="Claims"
            showModal={claimsModal}
            handleModalClose={() => {
              setShowModal(false);
              setClaimsModal(false);
            }}
          >
            <ModalDetailsBlock
              title="Emergency helpline"
              subTitle={data.claimsServiceDetails.twentyFourHourHelpline}
              description={`Typically an emergency helpline is provided as a "first notification of loss" which allows you to report the claim and seek any help and assistance you may need.`}
            />
            <ModalDetailsBlock
              title="Guaranteed repairs period"
              subTitle={data.claimsServiceDetails.repairsGuaranteePeriod}
              description={`Following a claim where a repair has been completed by an approved repairer, most insurance providers will include a guarantee for the repairs. Typically this is for 3 years or more.`}
            />
            <ModalDetailsBlock
              title="Courtesy car provided?"
              subTitle={data.claimsServiceDetails.courtesyCar}
              description={`Some policies will include a courtesy car in the event of your own vehicle being off the road following a claim. Where this cover is available, please check the policy wording for any restrictions on its availability. Please note: a courtesy car is often not provided if a vehicle is written off as a total loss. For courtesy car cover in these circumstances please see the "Total loss courtesy car" details below.`}
            />
            <ModalDetailsBlock
              title="New car replacement cover"
              subTitle={data.claimsServiceDetails.newCarReplacement}
              description={`Following a claim where the repairs to your car are above a certain percentage of the manufacturer's retail price, most insurance providers will replace your car with a brand new one of the same make and model.This cover is subject to certain conditions (for example, the car must be under 12 months old) and you should check the policy wording for more details.`}
            />
          </Modal>
          <Modal
            title="Excess"
            showModal={excessModal}
            handleModalClose={() => {
              setShowModal(false);
              setExcessModal(false);
            }}
          >
            <p className="mb-4">
              In the event of a claim for fire or theft, you will be required to pay the excess set by the insurer as
              your contribution towards the cost of the claim.
            </p>
            <div className="d-flex justify-content-between">
              <p className="m-0 my-1">Voluntary</p>
              <p className="m-0 my-1 fw-bold">£{data.voluntaryExcess}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="m-0 my-1">Compulsory</p>
              <p className="m-0 my-1 fw-bold">£{data.compulsoryExcess}</p>
            </div>
            <hr className="my-1" />
            <div className="d-flex justify-content-between">
              <p className="m-0 my-1">Total excess</p>
              <p className="m-0 my-1 fw-bold text-green">£{data.totalExcess}</p>
            </div>
            <p className="mt-2">
              If you or any named driver are considered to be a young or inexperienced driver, a further excess may also
              apply.
            </p>
            <p className="m-0 fw-bold">Windscreen replacement excess</p>
            <p className="m-0">
              The amount of excess you will pay if you make a claim to have the vehicle's windscreen replaced.
            </p>
            <p className="my-3" dangerouslySetInnerHTML={{ __html: data.windscreenReplacementExcess }}></p>
            <ModalDetailsBlock
              title="Uninsured driver / NCD protection"
              subTitle={data.uninsuredDrivingExcess}
              description="If you are involved in an accident with an uninsured driver which is not your fault, and you are able to meet the insurance provider's conditions, your no claims bonus will be protected and you will not have to pay the policy excess."
            />
          </Modal>
          <Modal
            title="Demands and needs"
            showModal={demandsModal}
            handleModalClose={() => {
              setShowModal(false);
              setDemandsModal(false);
            }}
          >
            <p>
              This policy will meet your requirements for car insurance as set out below. Below are the questions we
              asked you.
            </p>
            <Row className="my-5">
              <Col xs={12}>
                <a
                  href={data.requestACopyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-100 btn btn-outline-dark"
                >
                  Request copy
                </a>
              </Col>
            </Row>
            <ModalYourInfoBlock title="Your name" description={data.proposerName} />
            <ModalYourInfoBlock title="Your date of birth" description={data.dateOfBirth} />
            <ModalYourInfoBlock title="Your address" description={data.address} />
            <ModalYourInfoBlock title="Your car" description={data.carDetails} />
            <ModalYourInfoBlock title="What do you use the car for?" description={data.carUsage} />
            <ModalYourInfoBlock
              title="Do you drive any other vehicles besides this one?"
              description={data.otherVehicles}
            />
            <ModalYourInfoBlock title="Additional drivers" description={data.additionalDrivers.join()} />
            <ModalYourInfoBlock title="Type of cover" description={data.coverType} />
            <ModalYourInfoBlock title="Cover start date" description={data.coverStartDate} />
            <ModalYourInfoBlock title="Number of years No Claims Bonus" description={data.noClaimsBonusLength} />
            <ModalYourInfoBlock
              title="Do you want to protect your No Claims Bonus"
              description={data.noClaimsBonusProtection}
            />
            <ModalYourInfoBlock
              title="How many miles does this car cover each year"
              description={data.annualMileageString}
            />
          </Modal>
          <CallInfoModal show={callInfoModal} onHide={() => setCallInfoModal(false)} data={data} />
        </div>
      </Drawer>
    </div>
  );
};

export default QuoteCard;
