import React from "react";
import "../../styles/featuredAppsBar.css";

import { useAuth } from "context/AuthContext";
import { useIsShowFeaturedAppsBar } from "context/IsShowFeaturedAppsBarContext";

const data = [
  {
    key: "1",
    icon: "",
    text: "Logout",
  },
  {
    key: "2",
    icon: "",
    text: "",
  },
  {
    key: "3",
    icon: "",
    text: "",
  },
  {
    key: "4",
    icon: "",
    text: "",
  },
];

const FeaturedAppsBar = () => {
  const { logout } = useAuth();

  const { isShowAppsBar } = useIsShowFeaturedAppsBar();

  const onClickHandler = key => {
    switch (key) {
      case "1":
        return logout();
      case "2":
        return;
      case "3":
        return;
      case "4":
        return;
      default:
        return;
    }
  };

  if (!isShowAppsBar) return null;
  return (
    <div className="bottom-nav-wrapper d-none">
      <div className="box_container">
        {data.map(({ key, icon, text }) => (
          <div className="box_wrapper" key={key}>
            <div className="box" onClick={() => onClickHandler(key)}>
              {text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedAppsBar;
