import { useState } from "react";

import { Meta } from "components/layout";
import Loader from "components/Loader";
import { useClient } from "context/AuthContext";
import { config } from "../../config";
import SearchForm from "./SearchForm";
import { GraphContentContainer } from "components/graphUI";
import pLimit from "p-limit";
import RobotComponent from "components/robot/robot";
import dayjs from "dayjs";

const formatDate = text => {
  if (!text) return undefined;
  try {
    return dayjs(text, "D-MMMM-YYYY").format("YYYY-MM-DD");
  } catch (e) {
    return text;
  }
};

export default function Check() {
  const [query, setQuery] = useState("05480988");
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  const [searchError, setSearchError] = useState(false);

  const [isShowGraph, setIsShowGraph] = useState(false);

  const pageTitle = "Validate Company";

  const {
    validateCompany: { apiUrl: API_URL },
    validateAddress: { apiUrl: validateAddressAPIUrl },
    validateName: { apiUrl: validateNameAPIUrl },
  } = config;

  const handleGetCompanyDetails = async companyNumber => {
    try {
      let [company, filingHistory, people, charges] = await Promise.all([
        handleGetDetails(`${companyNumber}`),
        handleGetDetails(`${companyNumber}/filing-history`),
        handleGetDetails(`${companyNumber}/people`),
        handleGetDetails(`${companyNumber}/charges`),
      ]);
      if (charges.length > 0) {
        const limit = pLimit(3);
        const urls = charges.map(charge => `/${companyNumber}/charges/${charge}`);
        let promises = urls.map(url => {
          return limit(() => handleGetDetails(url));
        });
        const resp = await Promise.all(promises);
        if (resp.length > 0) {
          charges = resp;
        }
      }
      return [company, filingHistory, people, charges];
    } catch (error) {
      return [];
    }
  };
  const transformName = name => {
    try {
      const nameWithTitlePattern = /^(mr |mrs |ms )/i;
      if (nameWithTitlePattern.test(name)) {
        const [title, ...fullName] = name.split(" ");
        const middleName = fullName.filter((n, i) => i > 0 && i < fullName.length - 1).join(" ");
        return [title, fullName[0], middleName, fullName[fullName.length - 1]];
      }
      let foreName, middleName, surName, uniqueName;
      [surName, uniqueName] = name.split(",").map(n => n.trim());
      [foreName, ...middleName] = uniqueName.split(" ");
      return ["", foreName, middleName.join(" "), surName];
    } catch (e) {
      return ["", "", "", ""];
    }
  };
  const mapCompanyData = async ([company, filingHistory, people, charges]) => {
    const allChargeStatuses = [...new Set(charges.map(c => c.status))];
    const mappedCharges = allChargeStatuses.reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: charges
          .filter(c => c.status === cur)
          .map(c => {
            const chargeObject = {
              ...c,
              transactionFiled: {
                ...c.transactionFiled,
                link: c.transactionFiled?.link
                  ? `${API_URL}/${company.companyNumber}/${c.transactionFiled?.link}`
                  : undefined,
              },
              created: formatDate(c.created),
              delivered: formatDate(c.delivered),
              chargeCode: c.chargeCode?.replaceAll("Charge code", "").replaceAll("\n", ""),
            };
            delete chargeObject.status;
            return chargeObject;
          }),
      };
    }, {});
    const companyAddress = company.registeredOfficeAddress || company.overseasCompanyAddress;
    let validatedCompanyAddress = undefined;
    try {
      validatedCompanyAddress = await client(validateAddressAPIUrl, {
        data: {
          address: companyAddress,
        },
      });
    } catch (e) {
      validatedCompanyAddress = undefined;
    }
    const companyDetails = {
      name: company.name,
      address: companyAddress,
      validatedCompanyAddress,
      number: company.companyNumber,
      status: company.companyStatus,
      type: company.companyType,
      officialPage: company.officialLink,
      yellLink: company.yellLink,
      incorporatedOn: formatDate(company.incorporatedOn),
      previousCompanyNames: company.previousCompanyNames?.map(nameObject => {
        const name = nameObject.name;
        const [from, to] = nameObject.period?.split(" - ") || [];
        return {
          name,
          period: from || to ? `${from ? formatDate(from) : "?"} to ${to ? formatDate(to) : "?"}` : undefined,
        };
      }),
      sicCodes: company.sicCodes,
    };
    const moreDetail = {
      confirmationStatement: company.confirmationStatement
        ? Object.entries(company.confirmationStatement).reduce((acc, [key, date]) => {
            return {
              ...acc,
              [key]: formatDate(date),
            };
          }, {})
        : undefined,
      accounts: company.accounts
        ? Object.entries(company.accounts).reduce((acc, [key, date]) => {
            return {
              ...acc,
              [key]: formatDate(date),
            };
          }, {})
        : null,
    };
    const filingHistoryDetails = filingHistory
      ? Object.entries(filingHistory).reduce((acc, [type, files]) => {
          return {
            ...acc,
            [type]: files.map(f => ({
              ...f,
              key: undefined,
              date: formatDate(f.date),
              link: f.link ? `${API_URL}/${company.companyNumber}/${f.link}` : undefined,
            })),
          };
        }, {})
      : undefined;
    const officers = people.officers
      ? await Object.entries(people.officers).reduce(async (acc, [type, officers]) => {
          const typeData = await Promise.all(
            officers.map(async o => {
              let companyDetails;
              let validatedNameObject;
              try {
                if (o.registrationNumber) {
                  companyDetails = await getFullMappedCompanyDetails(o.registrationNumber);
                }
              } catch (e) {
                companyDetails = undefined;
              }
              try {
                if (!o.registrationNumber) {
                  const [title, foreName, middleNames, surename] = transformName(o.name);
                  if (foreName && surename) {
                    validatedNameObject = await client(validateNameAPIUrl, {
                      data: {
                        title,
                        firstname: foreName,
                        middlename: middleNames,
                        surname: surename,
                      },
                    });
                  }
                }
              } catch (e) {
                validatedNameObject = undefined;
              }
              return {
                ...o,
                appointedOn: formatDate(o.appointedOn),
                correspondenceAddress: o.correspondenceAddress === companyAddress ? undefined : o.correspondenceAddress,
                companyDetails,
                validatedNameObject,
              };
            }),
          );
          return Promise.resolve({
            ...(await acc),
            [type]: typeData,
          });
        }, Promise.resolve({}))
      : undefined;
    const personsWithSignificantControl = people.personsWithSignificantControl
      ? await Object.entries(people.personsWithSignificantControl).reduce(async (acc, [type, list]) => {
          const mappedList = await Promise.all(
            list.map(async o => {
              let companyDetails;
              let validatedNameObject;
              try {
                if (o.registrationNumber) {
                  companyDetails = await getFullMappedCompanyDetails(o.registrationNumber);
                }
              } catch (e) {
                companyDetails = undefined;
              }
              try {
                if (!o.registrationNumber) {
                  const [title, foreName, middleNames, surename] = transformName(o.name);
                  if (foreName && surename) {
                    validatedNameObject = await client(validateNameAPIUrl, {
                      data: {
                        title,
                        firstname: foreName,
                        middlename: middleNames,
                        surname: surename,
                      },
                    });
                  }
                }
              } catch (e) {
                validatedNameObject = undefined;
              }
              return {
                ...o,
                type: undefined,
                notifiedOn: formatDate(o.notifiedOn),
                ceasedOn: formatDate(o.ceasedOn),
                companyDetails,
                validatedNameObject,
                correspondenceAddress: o.correspondenceAddress === companyAddress ? undefined : o.correspondenceAddress,
              };
            }),
          );
          return {
            ...(await acc),
            [type]: mappedList,
          };
        }, Promise.resolve({}))
      : undefined;

    return {
      company: companyDetails,
      more: moreDetail,
      filingHistory: filingHistoryDetails,
      officers,
      personsWithSignificantControl,
      charges: mappedCharges,
    };
  };

  const getFullMappedCompanyDetails = async companyNumber => {
    try {
      let [company, filingHistory, people, charges] = await handleGetCompanyDetails(companyNumber);
      if (company) {
        return mapCompanyData([company, filingHistory, people, charges]);
      } else {
        return null;
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!query) return;
    setLoading(true);
    setSearchError(false);
    setCompanyData(null);
    let searchResp;

    try {
      searchResp = await handleSearch(`?q=${query}`);
    } catch (error) {
      setSearchError(true);
    }

    if (searchResp.companyNumber) {
      try {
        const data = await getFullMappedCompanyDetails(searchResp.companyNumber);
        if (data) {
          setCompanyData(data);
        }
      } catch (error) {
        throw new Error(error);
      }
    } else {
      setSearchError(true);
    }
    setLoading(false);
  };

  const handleGetDetails = async (path = "") => {
    try {
      const apiResponse = await client(`${API_URL}/${path}`);
      if (apiResponse) {
        return apiResponse;
      } else {
        setSearchError(true);
        setQuery("");
      }
    } catch (error) {
      setQuery("");
    }
  };

  const handleSearch = async () => {
    try {
      const apiResponse = await client(`${API_URL}?q=${query}`);
      if (apiResponse) {
        return apiResponse;
      } else {
        setSearchError(true);
        setQuery("");
      }
    } catch (error) {
      setLoading(false);
      setSearchError(true);
      setQuery("");
    }
  };

  return (
    <div className="check-wrapper">
      <Meta title={pageTitle} />

      {!companyData && (
        <div className="outer-search-wrapper">
          <SearchForm
            formLabel="Company Name"
            handleSubmit={handleSubmit}
            onChange={e => setQuery(e.target.value)}
            searchError={searchError}
            search={query}
            isValidTerm={loading || !query}
          />
        </div>
      )}

      <div className="text-center">{loading && <Loader />}</div>

      {isShowGraph ? (
        <RobotComponent graphData={companyData} />
      ) : (
        companyData && (
          <GraphContentContainer
            setIsShowGraph={setIsShowGraph}
            setGraphData={setCompanyData}
            defaultValue={companyData}
            isCheckChild={false}
          />
        )
      )}
    </div>
  );
}
