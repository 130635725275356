import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { v4 as uuidv4 } from "uuid";
import { GraphWrapper } from "./quote.styles";
import { GraphColor } from "constants/general";
import { getImage } from "utils/IllustrationQuote";

const MAIN_NODE = "main_node";

// remove _ from label name
const formatLabel = text => {
  if (!text || typeof text !== "string") return "";
  return text.split("_").join(" ");
};

const ClusterTitle = {
  provenance: "Provenance",
  subjects: "Subjects",
  risk: "Risk",
  percayso: "Percayso",
};

const excludeLabelList = [
  "Percayso Inform",
  "deploy",
  "inform",
  "enrich",
  "Community Gateway",
  "Private Gateway",
  "Percayso Quote Lake",
  "Percayso Gateway",
  "Partner Gateway",
];

const excludeLabel = text => {
  if (excludeLabelList.includes(text)) return true;
  return false;
};

const positionData = {
  GoSkippy: [0, -80],
  ["Open Gl"]: [0, -160],
  Compare_the_market: [0, -240],
  Risk: [0, 80],
  AX03LCW: [60, 170],
  Vehicle: [60, 170],
  12076001: [-60, 160],
  Cover: [60, 160],
  Subjects: [-80, 0],
  ["John Smith"]: [-160, 80],
  ["ANNE OTHER"]: [-160, -80],
  ["SMITH01642123456"]: [-140, 160],
  ["j.smith@btinternet.com"]: [-220, 160],
  ["31_Oakwood_Court_TS7_8TA"]: [-260, 110],
  ["01642123456"]: [-250, 45],
  ["OTHER01642312845"]: [-100, -150],
  ["a.other.hotmail.com"]: [-220, -10],
  ["01642312845"]: [-220, -140],
  Percayso_Inform: [80, 0],
  inform: [160, 0],
  deploy: [160, -60],
  enrich: [160, 60],
  Community_Gateway: [240, 0],
  Private_Gateway: [240, 40],
  ["Partner_Gateway"]: [300, 40],
  Percayso_Quote_Lake: [240, 80],
  Percayso_Gateway: [240, 120],
};
// data used for clustering nodes
const cidData = {
  GoSkippy: 1,
  ["Open Gl"]: 1,
  Compare_the_market: 1,
  Risk: 3,
  AX03LCW: 3,
  Vehicle: 3,
  12076001: 3,
  Cover: 3,
  Subjects: 7,
  ["John Smith"]: 7,
  ["31_Oakwood_Court_TS7_8TA"]: 5,
  ["j.smith@btinternet.com"]: 5,
  ["01642123456"]: 5,
  ["1937-05-19"]: 5,
  ["SMITH01642123456"]: 5,
  ["ANNE OTHER"]: 7,
  ["OTHER01642312845"]: 4,
  ["1967-08-04"]: 4,
  ["01642312845"]: 4,
  ["a.other.hotmail.com"]: 4,
  Percayso_Inform: 2,
  inform: 2,
  deploy: 2,
  enrich: 2,
  Community_Gateway: 6,
  Private_Gateway: 6,
  Percayso_Quote_Lake: 6,
  Percayso_Gateway: 6,
  ["Partner_Gateway"]: 6,
};

const getPosition = label => {
  const data = positionData[label];
  return data || [undefined, undefined];
};

const getCid = label => {
  const data = cidData[label];
  return data || 0;
};

const Quote = ({ graphData: graphDataObj, isAddControllerNodes }) => {
  const graphData = JSON.parse(JSON.stringify(graphDataObj));
  const domNode = useRef(null);
  const network = useRef(null);
  const options = {
    autoResize: true,
    height: "100%",
    width: "100%",
    nodes: {
      borderWidth: 0,
      borderWidthSelected: 0,
      font: { color: "white", align: "left", size: 7, background: "transparent" },
      color: "transparent",
      shadow: {
        enabled: false,
        size: 0,
      },
      shape: "image",
      fixed: {
        x: true,
        y: true,
      },
      size: 10,
      scaling: {
        min: 10,
        max: 10,
      },
    },
    edges: {
      color: "black",
      length: 80,
      selectionWidth: 0,
      width: 1,
    },
    physics: {
      enabled: false,
    },
  };

  const nodes = new DataSet([{ id: MAIN_NODE, label: "Controller", image: getImage("quote"), shape: "image" }]);
  const edges = new DataSet([]);

  const data = {
    nodes,
    edges,
  };

  const addControllerNodes = data => {
    nodes.updateOnly({
      id: MAIN_NODE,
      label: data.client,
      x: 0,
      y: 0,
    });
  };

  const getMainNode = data => {
    const mainNode = Object.entries(data).filter(node => typeof node[1] === "object");
    return mainNode;
  };

  const showObjectChild = (data, parent) => {
    if (!data) return;
    if (data.gender) return;

    const dataArr = Object.entries(data).filter(node => typeof node[1] === "object");
    const childNodeData = addNode(dataArr, parent);
    childNodeData.map(item => {
      if (Array.isArray(item.data)) {
        showArrayChild(item.data, item.parent);
      } else {
        showObjectChild(item.data, item.parent);
        showOthersChild(item.data, item.parent);
      }
    });
  };

  const showArrayChild = (data, parent) => {
    data
      .filter(item => (item.gender ? false : true))
      .map(item => {
        const dataArr = Object.entries(item);

        if (!dataArr.length) return;

        let child = addSingleNode(dataArr[0], parent);

        if (!child) return;

        if (Array.isArray(item)) {
          showArrayChild(item, child.parent);
        } else {
          showObjectChild(item, child.parent);
          showOthersChild(item, child.parent);
        }
      });
  };

  const showOthersChild = (data, parent) => {
    if (!data) return;

    let dataArr = Object.entries(data).filter((node, index) => typeof node[1] !== "object");

    const text = `${dataArr.slice(1).map(node => {
      return `${node[0]} : ${node[1]}\n`;
    })}`;

    if (!text) return;

    nodes.updateOnly({
      id: parent,
      title: text.replace(/,/g, ""),
    });
  };

  const addSingleNode = (data, parent) => {
    const uuid = uuidv4();
    const _label = (data?.length && formatLabel(data[1])) || "";
    nodes.add({
      id: uuid,
      label: _label,
      shape: "image",
      image: getImage((data?.length && data[0]) || ""),
      color: "violetred",
      x: getPosition(data[1])[0],
      y: getPosition(data[1])[1],
      cid: getCid(data[1]),
      font: { color: excludeLabel(_label) ? "transparent" : "white" },
    });
    edges.add({ from: parent, to: uuid });
    return { parent: uuid, data: data[1] };
  };

  const addNode = (data, parent, color = "blue") => {
    return data.map((node, index) => {
      const uuid = uuidv4();
      const _label = formatLabel(node[0]);
      nodes.add({
        id: uuid,
        label: _label,
        shape: "image",
        // not used label formatting
        image: getImage(node[0], "png", false),
        color,
        x: getPosition(node[0])[0],
        y: getPosition(node[0])[1],
        cid: getCid(node[0]),
        font: { color: excludeLabel(_label) ? "transparent" : "white" },
      });

      edges.add({ from: parent, to: uuid });
      return { parent: uuid, data: node[1] };
    });
  };

  function clusterByCid(id, label, nodeLabel, cid, image, imageType, isNotUseCircularShape) {
    const clusterOptionsByData = {
      joinCondition: function (childOptions) {
        return childOptions.cid == id;
      },
      clusterNodeProperties: {
        id: `cidCluster${id}`,
        shape: "image",
        image: getImage(image ? image : "blueCircle", "png"),
        label,
        cid: cid ? cid : undefined,
        x: getPosition(nodeLabel)[0],
        y: getPosition(nodeLabel)[1],
        allowSingleNodeCluster: true,
        font: { color: "white", align: "left", size: 7, background: "transparent" },
        color: "transparent",
      },
    };
    network.current.cluster(clusterOptionsByData);
  }
  useEffect(() => {
    network.current = new Network(domNode.current, data, options);

    network.current.on("selectNode", function (params) {
      network.current.fit({
        maxZoomLevel: 2.5,
        minZoomLevel: 0.5,
      });

      if (params.nodes.length == 1) {
        if (network.current.isCluster(params.nodes[0]) == true) {
          network.current.openCluster(params.nodes[0]);
        }
      }
    });

    network.current.on("selectNode", function (params) {
      network.current.fit({
        maxZoomLevel: 2.5,
        minZoomLevel: 0.5,
      });

      if (params.nodes.length == 1) {
        const nodeInfo = nodes.get(params.nodes[0]);
        switch (nodeInfo?.label) {
          case "GoSkippy":
            clusterByCid(1, ClusterTitle.provenance, "GoSkippy");
            break;
          case "Percayso Inform":
            clusterByCid(6, ClusterTitle.percayso, "Percayso_Gateway", 2, true);
            clusterByCid(2, "", "Percayso_Inform", "", "Percayso_Inform", "png");
            break;
          case "Risk":
            clusterByCid(3, ClusterTitle.risk, "Risk");
            break;
          case "Subjects":
            clusterByCid(4, "", "ANNE OTHER", 7, "ANNE_OTHER", "png", true);
            clusterByCid(5, "", "John Smith", 7, "John_Smith", "png", true);
            clusterByCid(7, ClusterTitle.subjects, ClusterTitle.subjects);
            break;
          case "ANNE OTHER":
            clusterByCid(4, "", "ANNE OTHER", 7, "ANNE_OTHER", "png", true);
            break;
          case "John Smith":
            clusterByCid(5, "", "John Smith", 7, "John_Smith", "png", true);
            break;
          case "Percayso Gateway":
            clusterByCid(6, ClusterTitle.percayso, "Percayso_Gateway", 2, "redCircle", "jpg");
            break;
          default:
            break;
        }
      }
    });
  }, [domNode, network, data, options]);

  useEffect(() => {
    addControllerNodes(graphData);
    const mainNode = getMainNode(graphData);

    showOthersChild(graphData, MAIN_NODE);

    const childNodeData = addNode(mainNode, MAIN_NODE, "black");
    childNodeData.map(item => {
      if (Array.isArray(item.data)) {
        showArrayChild(item.data, item.parent);
      } else {
        showObjectChild(item.data, item.parent);
        showOthersChild(item.data, item.parent);
      }
    });
  }, [network.current]);

  useEffect(() => {
    clusterByCid(1, ClusterTitle.provenance, "GoSkippy");
    clusterByCid(5, "", "John Smith", 7, "John_Smith", "png", true);
    clusterByCid(4, "", "ANNE OTHER", 7, "ANNE_OTHER", "png", true);
    clusterByCid(6, ClusterTitle.percayso, "Percayso_Gateway", 2, "redCircle", "jpg");
    clusterByCid(2, "", "Percayso_Inform", "", "Percayso_Inform", "png");
    clusterByCid(3, ClusterTitle.risk, "Risk");
    clusterByCid(7, ClusterTitle.subjects, ClusterTitle.subjects);

    network.current.fit({
      maxZoomLevel: 2.5,
      minZoomLevel: 0.5,
    });
  }, [network.current]);

  return <GraphWrapper ref={domNode} />;
};

Quote.propTypes = {
  graphData: PropTypes.object.isRequired,
  isAddControllerNodes: PropTypes.bool,
};

Quote.defaultProps = {
  isAddControllerNodes: true,
};

export default Quote;
