import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { MEDIA_QUERY } from "constants/general";

const DateInputForm = ({ placeholder = "", value, handleChange, handleKeyEvent, handleBlur, focusHandle }) => {
  const isSmallScreen = useMediaQuery({
    query: MEDIA_QUERY.SMALL_MOBILE,
  });
  return (
    <div className={classNames("form-group", { ["mt-3"]: !isSmallScreen }, { ["mt-2"]: isSmallScreen })}>
      <input
        type="text"
        placeholder={placeholder}
        className="input-form form-control"
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyEvent}
        onFocus={focusHandle}
      />
    </div>
  );
};

DateInputForm.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleKeyEvent: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  focusHandle: PropTypes.func.isRequired,
};

DateInputForm.defaultProps = {
  placeholder: "",
};

export default DateInputForm;
