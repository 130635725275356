import React, { useCallback, useEffect, useState } from "react";
import { CarInsuranceJsonEditor } from "components/carInsurance";
import { client } from "utils/apiClient";
import { config } from "config";
import { socket } from "config/socket";
import beautify from "json-beautify";
import { Container } from "react-bootstrap";
import QuoteCard from "components/goCompare/Card";

const defaultJSONData = {
  client: "Somerset Bridge",
  GoSkippy: [
    {
      openGl: "Open Gl",
      Compare_the_market: {},
    },
  ],
  Risk: {
    Vehicle: {
      Vehicle: "12076001",
      body: "03",
      cc: 1560,
      doors: 5,
      estimatedValue: 2190,
      fuel: "001",
      gearbox: "002",
      importInfo: {
        imported: true,
        importedFrom: "Japanese import",
      },
      make: "",
      model: "",
      objectId: "1",
      owner: "Spouse",
      keeper: "Spouse",
      pendingOwner: "Spouse",
      purchaseDate: "2014-07-01",
      qPlate: false,
      registeredDate: "2011-01-01",
      rightHandDrive: true,
      seats: 5,
      isTrackerAvailable: true,
      trackerType: "Thatcham device",
      vrn: "SP11UFE",
      vrnValid: "",
      year: 2011,
    },
    Cover: {
      Cover: "Comprehensive",
      accessoriesValue: 0,
      annualMileage: 3000,
      businessMileage: 2000,
      coverPeriod: "monthly",
      coverPeriodUnits: "2",
      wouldYouLike250EuroFreeExcessCover: false,
      coverStartDate: "2022-12-18",
      coverTypeId: "02",
      dayTimeParking: "In a forces base car park",
      nightTimeParking: "In a forces base car park",
      nightTimeParkingPlace: {
        atHome: true,
        whatPostcodeIsTheCarKeptAtOvernight: "BT411AA",
      },
      drivingRestrictionId: "1",
      hasWindscreenCover: false,
      overnightParkingId: "5",
      pleasureMileage: 3000,
      howRegularlyDoYouDriveDuringPeakTimes: {
        doYouDriveTheCarInPeakTimes: true,
        peakDrivingTime: "1 day a week",
      },
      requiredDrivers: "1",
      voluntaryExcess: 0,
      voluntaryExcessAmount: "None",
      whatIsYourRenewalPrice: 500,
      whatDoYouUseTheCarFor: "business use by you",
      noClaimsBonus: {
        howManyYearsNoClaimsBonusNcbDoYouHave: "2 years",
        haveYouHeldCarInsuranceInYourNameWithinTheLast2Years: true,
        haveYouBeenRegularlyDrivingACarNotInsuredByYou: true,
        howManyYearsClaimFreeDrivingDoYouCurrentlyHave: "5 years or more",
        whatTypeOfDrivingExperienceDoYouHave: "Company Car Business Only",
        isYourNoClaimsBonusCurrentlyProtected: true,
        wouldYouLikeToProtectYourNoClaimsBonus: true,
      },
      doYouOwnOrUseAnotherVehicle: {
        doYouOwnVehicle: true,
        otherVehicleType: "Own another car",
        howManyYearsNoClaimsBonusDoYouHaveForTheOtherVehicle: "2 years",
      },
      hasHadInsuranceDeclineOrTermsImposed: false,
    },
  },
  Subjects: [
    {
      ANNE_OTHER: "ANNE OTHER",
      "a.other.hotmail.com": {},
      "01642312845": {},
      OTHER01642312845: {},
      title: "Mr",
      firstName: "ANNE",
      surname: "OTHER",
      dob: "2000-02-21",
      maritalStatus: "Single",
      ukResident: {
        isFromBirth: false,
        date: "2006-02-21",
      },
      address: {
        houseNumber: "street 01",
        postcode: "BT411AA",
      },
      email: "ao.hello89881@hotmail.com",
      password: "HelloTest!22288@@",
      passwordReminderClueText: "Hello",
      whatIsYourMotherMaidenName: "Hello",
      phoneNumber: "8045450191",
      occupation: {
        employmentStatus: "Employed",
        jobTitle: "Teacher",
        industry: "School",
        partTimeJobTitle: "Singer",
        partTimeJobIndustry: "Cinema",
        whatTypeOfStudentAreYou: "Mature student - living at home",
        whichCourseAreYouStudying: "Computer Science",
      },
      whatTypeOfLicenseDoYouHold: {
        licenseType: "International",
        howManyYearsHasItBeenSinceYouPassedYourTest: 1,
        howManyMonthHasItBeenSinceYouPassedYourTest: "less than 1",
      },
      howLongHaveYouHeldThisLicence: "4 years",
      wouldYouLikeToEnterYourDrivingLicenceNumberThisMayReduceYourPremium: true,
      pleaseEnterYour16CharacterGbDrivingLicenceNumber: {
        pleaseEnterTheFirstFiveLettersOfYourSurnameIfFewerThanFiveLettersPleaseAdd9: "KING9",
        pleaseEnterYourDateOfBirthAsItAppearsOnYourLicence: "021999",
        pleaseEnterTheInitialsOfYourFirstTwoForenamesIfYouOnlyHaveOneForenameThenAddA9: "IM",
        pleaseEnterTheThreeComputerGeneratedCharactersFromYourLicence: "454",
      },
      dvlaReport: {
        doYouHaveAnyDvlaReportedMedicalConditionsOrDisabilities: true,
        disabilitiesName: "DVLA aware - no restrictions",
      },
      yourHousehold: {
        howManyCarsAreAtYourHome: "8",
        howManyChildrenDoYouHaveUnder16: 5,
        areYouAHomeowner: true,
      },
      claimsConvictions: {
        anyIncidentInTheLast5Years: {
          hasIncident: true,
          whatTypeOfIncidentWasIt: {
            name: "Fire damage",
            date: "2022-03-26",
            claimOnMostRecentPolicy: true,
            wasItSettled: true,
            wasAnybodyInjured: true,
            whoWasAtFault: {
              you: true,
            },
            theft: {
              whereWasTheVehicle: "Away from home",
              whatWasStolen: "Vehicle and its contents",
              wasTheVehicleRecovered: false,
            },
            claimCost: 60000,
          },
        },
        anyConvictionsInTheLast5Years: {
          hasConvictions: true,
          whatTypeOfConvictionWasIt: {
            name: "DD90 - furious driving",
            date: "2022-04-24",
            dvlaCodeOnLicence: "UT52 Aiding and abetting UT50 aggravated taking of a vehicle",
            penaltyPoints: 8,
            fineIncurred: 5599,
            lengthOfBanInMonths: 0,
            wasTheDriverBreathalysed: {
              wasBreathalysed: true,
              whatWasTheBreathalyserReading: 99,
            },
            wasTheOffenceAccidentRelated: true,
          },
        },
        doYouHaveAnyUnspentNonMotoringConvictions: true,
      },
      securityQuestion: "What school did you attend when you were twelve",
      securityQuestionAnswer: "Twelve",
      selectTheBenefitsYouWouldLikeToReceive: {
        priceAlerts: true,
        newsAndUpdates: true,
        OffersAndIncentives: true,
      },
    },
    {
      John_Smith: "John Smith",
      "31_Oakwood_Court_TS7_8TA": {},
      "j.smith@btinternet.com": {},
      "01642123456": {},
      SMITH01642123456: {},
    },
  ],
  Percayso_Inform: [
    {
      deploy: "deploy",
    },
    {
      inform: "inform",
    },
    {
      enrich: "enrich",
    },
  ],
};

const CarInsurance = () => {
  const [message, setMessage] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [textAreaValue, setTextAreaValue] = useState(stringify(defaultJSONData));

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      socket.emit("register_car_insurance", {
        formData: { ...JSON.parse(textAreaValue) },
      });
    } catch (error) {
      setValidationMessage("Car insurance register request submit is failed.");
    } finally {
      setIsLoading(false);
    }
  }, [textAreaValue]);

  useEffect(() => {
    if (textAreaValue) {
      socket.on("register_car_insurance_request_received", args => {
        setIsLoading(args.loading);
        setMessage(args.loadingMessage);
      });

      socket.on("register_car_insurance_data_saving_in_db", args => {
        setMessage(args.loadingMessage);
      });

      socket.on("register_car_insurance_scrape_complete", args => {
        setIsLoading(args.loading);
        setMessage(args.loadingMessage);
        setData(args.data.data);
      });

      socket.on("register_car_insurance_scrape_error", args => {
        setIsLoading(args.loading);
        setValidationMessage(args.loadingMessage);
      });

      return () => {
        socket.off("register_car_insurance_request_received");
        socket.off("register_car_insurance_data_saving_in_db");
        socket.off("register_car_insurance_scrape_complete");
        socket.off("register_car_insurance_scrape_error");
      };
    }
  }, []);

  return (
    <div>
      {Array.isArray(data) && data.length ? (
        <Container>
          {data.map((item, index) => (
            <QuoteCard key={index} data={item} />
          ))}
        </Container>
      ) : (
        <div>
          <CarInsuranceJsonEditor
            fetchData={fetchData}
            isLoading={isLoading}
            textAreaValue={textAreaValue}
            setTextAreaValue={setTextAreaValue}
          />
        </div>
      )}
    </div>
  );
};

export default CarInsurance;

function stringify(data) {
  return JSON.stringify(data, undefined, 3);
}
