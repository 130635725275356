import React from "react";
import PropTypes from "prop-types";

const RatingStar = ({ rating }) => {
  switch (rating.toString().toLowerCase()) {
    case "h":
      return (
        <svg width="18" height="16" viewBox="0 0 23 21" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path
              id="a1"
              d="M11.1154664 16.7787887l6.8693582 4.0944507-1.8173787-7.722 6.0634869-5.19304222-7.9931319-.67783098L11.1154664 0 7.99313188 7.2803662 0 7.95819718l6.0634869 5.19304222-1.81737874 7.722z"
            ></path>
          </defs>
          <g fill="none" fillRule="evenodd">
            <mask id="b1" fill="#fff">
              <use xlinkHref="#a1"></use>
            </mask>
            <use fill="#e0e0e0" xlinkHref="#a1"></use>
            <path
              fill="#e0e0e0"
              d="M-2.22309328-1.09859155h13.3385597v23.0704225h-13.3385597z"
              mask="url(#b1)"
              className="animation__star animation__star--reviews"
            ></path>
          </g>
        </svg>
      );

    case "e":
      return (
        <svg width="18" height="16" viewBox="0 0 23 21" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <path
              id="a2"
              d="M11.1154664 16.7787887l6.8693582 4.0944507-1.8173787-7.722 6.0634869-5.19304222-7.9931319-.67783098L11.1154664 0 7.99313188 7.2803662 0 7.95819718l6.0634869 5.19304222-1.81737874 7.722z"
            ></path>
          </defs>
          <use fill="#e0e0e0" fillRule="evenodd" xlinkHref="#a2"></use>
        </svg>
      );

    default:
      return (
        <svg width="18" height="16" viewBox="0 0 23 21" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.1154664 16.7787887l6.8693582 4.0944507-1.8173787-7.722 6.0634869-5.19304222-7.9931319-.67783098L11.1154664 0 7.99313188 7.2803662 0 7.95819718l6.0634869 5.19304222-1.81737874 7.722"
            fill="#e0e0e0"
            fillRule="evenodd"
            className="animation__star animation__star--reviews"
          ></path>
        </svg>
      );
  }
};

RatingStar.propTypes = {
  rating: PropTypes.string.isRequired,
};

export default RatingStar;
