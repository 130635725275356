/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";

import percaysoLogo from "assest/logo-small.png";
import { useAuth } from "context/AuthContext";
import NavRoute from "routes/NavRoute";
import ConfirmSelectURL from "utils/confirmSelectURL";
import NavLink from "./NavLink";
import nameUp from "assest/nameUP.png";
import nameOver from "assest/nameOVER.png";
import drivingLicenseUp from "assest/drivinglicenceUP.png";
import drivingLicenseOver from "assest/drivinglicenceOVER.png";

import * as mq from "styles/media-queries";
import SearchPopover from "components/percayso/SearchPopover";
import { usePercaysoDataTable } from "hooks/usePercaysoDataTable";
import { useIsShowNavBar } from "hooks/useIsShowNavBar";

function Nav() {
  const { logout } = useAuth();
  const { isShowSearchIcon } = usePercaysoDataTable();
  const { isShowNavBar } = useIsShowNavBar();

  return isShowNavBar ? (
    <nav
      css={{
        display: "flex",
        alignItems: "center",
        borderRadius: 3,
        marginBottom: "2em",
        [mq.small]: {
          position: "static",
          top: "auto",
        },
      }}
    >
      <ul
        css={{
          listStyle: "none",
          padding: 0,
          display: "flex",
          flexGrow: 1,
        }}
      >
        <li>
          <NavLink to="/">
            <img className="nav-icons" src={percaysoLogo} alt="logo" />
          </NavLink>
        </li>
        {NavRoute.map((eachRoute, id) => (
          <li key={id}>
            <NavLink to={eachRoute.path}>
              {ConfirmSelectURL(eachRoute.path) ? (
                <div className="selected-link">
                  <img className="nav-menu-icon" src={eachRoute.iconOver} alt="logo" />
                  <i className="selected-icon fa fa-caret-up" title="caret" />
                </div>
              ) : (
                <img className="nav-menu-icon" src={eachRoute.IconUp} alt="logo" />
              )}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="d-flex align-items-center me-4 mb-3">
        {isShowSearchIcon ? <SearchPopover /> : null}
        <button className="btn-validate" onClick={() => logout()}>
          Logout
        </button>
      </div>
    </nav>
  ) : null;
}

export default Nav;
