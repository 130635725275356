import React, { useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { GraphWrapper } from "./MatchNameGraph.styles";
import maleIcon from "./../../assest/nameGraph/user.png";
import femaleIcon from "./../../assest/nameGraph/female.png";

const MatchNameGraph = ({ graphData: graphDataObj }) => {
  const graphData = JSON.parse(JSON.stringify(graphDataObj));
  const domNode = useRef(null);
  const network = useRef(null);
  const options = {
    autoResize: true,
    height: "100%",
    width: "100%",
    nodes: {
      borderWidth: 0,
      borderWidthSelected: 0,
      font: { color: "white", align: "left", size: 20, background: "transparent" },
      color: "transparent",
      shadow: {
        enabled: false,
        size: 0,
      },
      shape: "image",

      size: 35,
      scaling: {
        min: 10,
        max: 10,
      },
    },
    edges: {
      color: "black",
      length: 400,
      selectionWidth: 0,
      width: 3,
    },
  };

  const nodes = new DataSet([]);
  const edges = new DataSet([]);

  const data = {
    nodes,
    edges,
  };

  const showOthersChild = data => {
    if (!data) return;

    let dataArr = Object.entries(data).filter((node, index) => typeof node[1] !== "object");

    const text = `${dataArr.map(node => {
      if (node[0] !== "" && node[1] !== "") {
        return `${node[0]} : ${node[1]}\n`;
      }
    })}`;

    if (!text) return;

    nodes.updateOnly({
      id: 0,
      title: text.replace(/,/g, ""),
    });

    nodes.updateOnly({
      id: 1,
      title: text.replace(/,/g, ""),
    });
  };

  const getName = name => {
    const splittedName = name.split("|");
    return `${splittedName[0]} ${splittedName[1]} ${splittedName[2]} ${splittedName[3]}`;
  };

  const getGender = name => name.split("|")[6];

  useEffect(() => {
    nodes.add({
      id: 0,
      label: getName(graphData.name_1),
      image: getGender(graphData.name_1) === "MALE" ? maleIcon : femaleIcon,
      shape: "image",
    });
    nodes.add({
      id: 1,
      label: getName(graphData.name_2),
      image: getGender(graphData.name_2) === "MALE" ? maleIcon : femaleIcon,
      shape: "image",
    });

    edges.add({
      from: 0,
      to: 1,
      length: 400,
    });

    showOthersChild({ ...graphData });

    network.current = new Network(domNode.current, data, options);
  }, [data]);

  return <GraphWrapper ref={domNode} />;
};

MatchNameGraph.propTypes = {
  graphData: PropTypes.object.isRequired,
};

export default MatchNameGraph;
