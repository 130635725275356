import React, { useEffect, useState } from "react";
import { SearchContainer, SearchGraph } from "components/search";
import { useIsShowFeaturedAppsBar } from "context/IsShowFeaturedAppsBarContext";
import { Meta } from "components/layout";

const pageTitle = "Search";

const Search = () => {
  const [isShowGraph, setIsShowGraph] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const { dispatch: dispatchIsShowFeaturedAppsBar, Action } = useIsShowFeaturedAppsBar();

  useEffect(() => {
    dispatchIsShowFeaturedAppsBar({ type: Action.HideAppsBar });

    return () => {
      dispatchIsShowFeaturedAppsBar({ type: Action.ShowAppsBar });
    };
  }, []);

  return (
    <>
      <Meta title={pageTitle} />
      {isShowGraph ? (
        <SearchGraph graphData={graphData.length ? graphData[0]._source : {}} />
      ) : (
        <SearchContainer
          // inputValue={inputValue}
          // setInputValue={setInputValue}
          setIsShowGraph={setIsShowGraph}
          setGraphData={setGraphData}
        />
      )}
    </>
  );
};

export default Search;
