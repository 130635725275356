import React, { useState } from "react";

import { useDataTable } from "context/DataTableContext";
import Pagination from "components/Pagination";
import FormattedTable from "components/Table";

function Officer() {
  const { officers, pageSize } = useDataTable();
  const [currentPage, setCurrentPage] = useState(1);

  const officersColumns = [
    { dataField: "id", text: "ID" },
    { dataField: "full_name", text: "Name" },
    { dataField: "address", text: "Address" },
    { dataField: "country_residence", text: "Country" },
  ];

  return (
    <div>
      {officers && (
        <div className="mt-3">
          <FormattedTable
            data={officers.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize)}
            columns={officersColumns}
            type="officers"
          />
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            pageSize={pageSize}
            totalCount={officers.length}
            onPageChange={page => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  );
}

export default Officer;
