import React, { useContext, useState } from "react";

const NavBarContext = React.createContext();

// eslint-disable-next-line react/prop-types
export const NavBarProvider = ({ children }) => {
  const [isShowNavBar, setIsShowNavBar] = useState(true);

  return <NavBarContext.Provider value={{ isShowNavBar, setIsShowNavBar }}>{children}</NavBarContext.Provider>;
};

NavBarProvider.displayName = "NavBarProvider";

export function useIsShowNavBar() {
  const { isShowNavBar, setIsShowNavBar } = useContext(NavBarContext);

  return {
    isShowNavBar,
    setIsShowNavBar,
  };
}
