// !Sample Data
// export const CONE_JSON_DATA = {
//   enrich: true,
//   inform: true,
// };

// export const CONE_JSON_DATA = {
//   enrich: {
//     enabled: true,
//     x: 0,
//     y: 0,
//   },
//   inform: {
//     enabled: false,
//     x: 1,
//     y: 5,
//   },
// };

export const CONE_JSON_DATA_TITLES = {
  enrich: "enrich",
  inform: "inform",
  deploy: "deploy",
  community: "community",
  private: "private",
  percayso: "percayso",
  partner: "partner",
  saveLines: "saveLines",
  publishLines: "publishLines",
  percaysoInfoManager: "percaysoInfoManager",
  clientArea: "clientArea",
  extractLines: "extractLines",
  quote: "quote",
  clientLineBig: "clientLineBig",
  client: "client",
  clientLineSmall: "clientLineSmall",
};

export const CONE_JSON_DATA_ONE = {
  [CONE_JSON_DATA_TITLES.enrich]: true,
  [CONE_JSON_DATA_TITLES.inform]: {
    enabled: true,
    x: 0,
    y: -29,
  },
  [CONE_JSON_DATA_TITLES.deploy]: true,
  [CONE_JSON_DATA_TITLES.community]: true,
  [CONE_JSON_DATA_TITLES.private]: {
    enabled: true,
    x: 84,
    y: 8.5,
  },
  [CONE_JSON_DATA_TITLES.percayso]: {
    enabled: true,
    x: 76,
    y: 34,
  },
  [CONE_JSON_DATA_TITLES.partner]: {
    enabled: true,
    x: 46,
    y: 49,
  },
  [CONE_JSON_DATA_TITLES.saveLines]: false,
  [CONE_JSON_DATA_TITLES.publishLines]: {
    enabled: false,
    x: -80,
    y: -67,
  },
  [CONE_JSON_DATA_TITLES.percaysoInfoManager]: false,
  [CONE_JSON_DATA_TITLES.clientArea]: {
    enabled: false,
    x: 101,
    y: -103,
  },
  [CONE_JSON_DATA_TITLES.extractLines]: false,
  [CONE_JSON_DATA_TITLES.quote]: {
    enabled: false,
    x: 0,
    y: -125,
  },
  clientLineBig: false,
  client: false,
  clientLineSmall: false,
};
export const CONE_JSON_DATA_TWO = {
  [CONE_JSON_DATA_TITLES.enrich]: true,
  [CONE_JSON_DATA_TITLES.inform]: true,
  [CONE_JSON_DATA_TITLES.deploy]: false,
  [CONE_JSON_DATA_TITLES.community]: true,
  [CONE_JSON_DATA_TITLES.private]: true,
  [CONE_JSON_DATA_TITLES.percayso]: true,
  [CONE_JSON_DATA_TITLES.partner]: true,
  [CONE_JSON_DATA_TITLES.saveLines]: true,
  [CONE_JSON_DATA_TITLES.publishLines]: true,
  [CONE_JSON_DATA_TITLES.percaysoInfoManager]: true,
  [CONE_JSON_DATA_TITLES.clientArea]: true,
  [CONE_JSON_DATA_TITLES.extractLines]: true,
  [CONE_JSON_DATA_TITLES.quote]: true,
  [CONE_JSON_DATA_TITLES.clientLineBig]: true,
  [CONE_JSON_DATA_TITLES.client]: true,
  [CONE_JSON_DATA_TITLES.clientLineSmall]: false,
};
export const CONE_JSON_DATA_THREE = {
  [CONE_JSON_DATA_TITLES.enrich]: true,
  [CONE_JSON_DATA_TITLES.inform]: false,
  [CONE_JSON_DATA_TITLES.deploy]: false,
  [CONE_JSON_DATA_TITLES.community]: true,
  [CONE_JSON_DATA_TITLES.private]: true,
  [CONE_JSON_DATA_TITLES.percayso]: true,
  [CONE_JSON_DATA_TITLES.partner]: true,
  [CONE_JSON_DATA_TITLES.saveLines]: false,
  [CONE_JSON_DATA_TITLES.publishLines]: false,
  [CONE_JSON_DATA_TITLES.percaysoInfoManager]: false,
  [CONE_JSON_DATA_TITLES.clientArea]: false,
  [CONE_JSON_DATA_TITLES.extractLines]: false,
  [CONE_JSON_DATA_TITLES.quote]: false,
  [CONE_JSON_DATA_TITLES.clientLineBig]: false,
  [CONE_JSON_DATA_TITLES.client]: true,
  [CONE_JSON_DATA_TITLES.clientLineSmall]: true,
};
export const CONE_JSON_DATA_FOUR = {
  [CONE_JSON_DATA_TITLES.enrich]: true,
  [CONE_JSON_DATA_TITLES.inform]: false,
  [CONE_JSON_DATA_TITLES.deploy]: false,
  [CONE_JSON_DATA_TITLES.community]: true,
  [CONE_JSON_DATA_TITLES.private]: true,
  [CONE_JSON_DATA_TITLES.percayso]: true,
  [CONE_JSON_DATA_TITLES.partner]: true,
  [CONE_JSON_DATA_TITLES.percaysoInfoManager]: false,
  [CONE_JSON_DATA_TITLES.clientArea]: false,
};
export const CONE_JSON_DATA_FIVE = {
  [CONE_JSON_DATA_TITLES.enrich]: true,
  [CONE_JSON_DATA_TITLES.inform]: true,
  [CONE_JSON_DATA_TITLES.deploy]: false,
  [CONE_JSON_DATA_TITLES.community]: true,
  [CONE_JSON_DATA_TITLES.private]: true,
  [CONE_JSON_DATA_TITLES.percayso]: true,
  [CONE_JSON_DATA_TITLES.partner]: true,
  [CONE_JSON_DATA_TITLES.saveLines]: true,
  [CONE_JSON_DATA_TITLES.publishLines]: true,
  [CONE_JSON_DATA_TITLES.percaysoInfoManager]: true,
  [CONE_JSON_DATA_TITLES.clientArea]: true,
  [CONE_JSON_DATA_TITLES.extractLines]: true,
  [CONE_JSON_DATA_TITLES.quote]: true,
  [CONE_JSON_DATA_TITLES.clientLineBig]: true,
  [CONE_JSON_DATA_TITLES.client]: true,
  [CONE_JSON_DATA_TITLES.clientLineSmall]: false,
};
export const CONE_JSON_DATA_SIX = {
  [CONE_JSON_DATA_TITLES.enrich]: true,
  [CONE_JSON_DATA_TITLES.inform]: true,
  [CONE_JSON_DATA_TITLES.deploy]: false,
  [CONE_JSON_DATA_TITLES.community]: true,
  [CONE_JSON_DATA_TITLES.private]: true,
  [CONE_JSON_DATA_TITLES.percayso]: true,
  [CONE_JSON_DATA_TITLES.partner]: true,
  [CONE_JSON_DATA_TITLES.saveLines]: true,
  [CONE_JSON_DATA_TITLES.publishLines]: true,
  [CONE_JSON_DATA_TITLES.percaysoInfoManager]: true,
  [CONE_JSON_DATA_TITLES.clientArea]: true,
  [CONE_JSON_DATA_TITLES.extractLines]: true,
  [CONE_JSON_DATA_TITLES.quote]: false,
  [CONE_JSON_DATA_TITLES.clientLineBig]: true,
  [CONE_JSON_DATA_TITLES.client]: true,
  [CONE_JSON_DATA_TITLES.clientLineSmall]: false,
};
export const CONE_JSON_DATA_SEVEN = {
  [CONE_JSON_DATA_TITLES.enrich]: true,
  [CONE_JSON_DATA_TITLES.inform]: true,
  [CONE_JSON_DATA_TITLES.deploy]: true,
  [CONE_JSON_DATA_TITLES.community]: true,
  [CONE_JSON_DATA_TITLES.private]: true,
  [CONE_JSON_DATA_TITLES.percayso]: true,
  [CONE_JSON_DATA_TITLES.partner]: true,
  [CONE_JSON_DATA_TITLES.saveLines]: true,
  [CONE_JSON_DATA_TITLES.publishLines]: true,
  [CONE_JSON_DATA_TITLES.percaysoInfoManager]: true,
  [CONE_JSON_DATA_TITLES.clientArea]: true,
  [CONE_JSON_DATA_TITLES.extractLines]: true,
  [CONE_JSON_DATA_TITLES.quote]: true,
  [CONE_JSON_DATA_TITLES.clientLineBig]: false,
  [CONE_JSON_DATA_TITLES.client]: false,
  [CONE_JSON_DATA_TITLES.clientLineSmall]: false,
};
export const CONE_JSON_DATA_EIGHT = {
  [CONE_JSON_DATA_TITLES.enrich]: true,
  [CONE_JSON_DATA_TITLES.inform]: true,
  [CONE_JSON_DATA_TITLES.deploy]: true,
  [CONE_JSON_DATA_TITLES.community]: true,
  [CONE_JSON_DATA_TITLES.private]: true,
  [CONE_JSON_DATA_TITLES.percayso]: true,
  [CONE_JSON_DATA_TITLES.partner]: true,
  [CONE_JSON_DATA_TITLES.saveLines]: true,
  [CONE_JSON_DATA_TITLES.publishLines]: true,
  [CONE_JSON_DATA_TITLES.percaysoInfoManager]: true,
  [CONE_JSON_DATA_TITLES.clientArea]: true,
  [CONE_JSON_DATA_TITLES.extractLines]: true,
  [CONE_JSON_DATA_TITLES.quote]: true,
  [CONE_JSON_DATA_TITLES.clientLineBig]: false,
  [CONE_JSON_DATA_TITLES.client]: false,
  [CONE_JSON_DATA_TITLES.clientLineSmall]: false,
};
