import React, { useState } from "react";
import { Meta } from "components/layout";
import { InputForm } from "components/forms";
import { FieldsWrapper, Loader, LoadingButton } from "components/lib";
import "../../styles/validateAddress.css";
import { config } from "../../config";
import { client } from "utils/apiClient";
import { TextArea } from "../../components/graphUI/GraphContentContainer.styles";

// const options = [
//   {
//     value: QUOTE_TYPES.CAR_QUOTES,
//     label: "Get Car Quotes",
//     needQuery: true,
//     api: API_URL_BY_QUOTE_TYPES.CAR_QUOTES,
//   },
// ];

const ValidateCompanyByYell = () => {
  const [apiResponse, setApiResponse] = useState(null);
  const [name, setName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const pageTitle = "Get Company Detail From Yell";

  const handleSubmit = async e => {
    e.preventDefault();
    setValidationMessage("");

    if (!name) {
      setValidationMessage("Company Name required!");
      return;
    }

    if (!postcode) {
      setValidationMessage("Company Postcode required!");
      return;
    }

    setLoading(true);
    const {
      validateCompany: { apiUrl: API_URL },
    } = config;
    try {
      const apiResponse = await client(`${API_URL}/search-by-yell`, {
        query: {
          name,
          postcode,
        },
      });

      setApiResponse(apiResponse);
    } catch (e) {
      setValidationMessage("Getting company detail from Yell failed");
    }

    setLoading(false);
  };

  return (
    <div className="address-form-wrapper">
      <Meta title={pageTitle} />
      <div>
        <form onSubmit={handleSubmit} className="mt-3">
          <FieldsWrapper>
            <InputForm
              type={"text"}
              value={name}
              handleChange={event => setName(event.target.value)}
              placeholder={"Company Name"}
              autoFocus
            />
            <InputForm
              type={"text"}
              value={postcode}
              handleChange={event => setPostcode(event.target.value)}
              placeholder={"Company Postcode"}
              autoFocus
            />
          </FieldsWrapper>

          <div className="btn-container">
            <LoadingButton type="submit" className="btn-validate mt-1">
              {loading ? <Loader /> : "Submit"}
            </LoadingButton>
          </div>
        </form>
        <div className="name-validate-error">{validationMessage}</div>
        {apiResponse && (
          <TextArea
            className="w-100 h-100 rounded-3 p-2 form-control"
            style={{ minHeight: "300px" }}
            placeholder="Data"
            value={JSON.stringify(apiResponse, undefined, 3)}
            // onChange={event => setTextAreaValue(event.target.value)}
          />
        )}
      </div>
    </div>
  );
};

export default ValidateCompanyByYell;
