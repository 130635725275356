import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

function ResultsDropDown({ companies }) {
  return (
    <div className="resultsDropDown">
      {companies.map(company => (
        <Link key={company.id} to={`detail/${company.id}`} className="result">
          {company.name.toLowerCase()}
        </Link>
      ))}
    </div>
  );
}

ResultsDropDown.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ResultsDropDown;
