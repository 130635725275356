import { GraphContentContainer } from "components/graphUI";
import React, { useState } from "react";
import NameGraph from "./NameGraph";
import { PropTypes } from "prop-types";

const ValidateNameGraph = ({ apiResponse, handleAnother }) => {
  const [isShowJSONField, setIsShowJSONField] = useState(true);
  const [graphData, setGraphData] = useState(null);
  return !isShowJSONField ? (
    <NameGraph graphData={graphData} setIsShowJSONField={setIsShowJSONField} handleAnother={handleAnother} />
  ) : (
    <GraphContentContainer
      setIsShowJSONField={setIsShowJSONField}
      setGraphData={setGraphData}
      defaultValue={apiResponse}
      isCheckChild={false}
    />
  );
};

ValidateNameGraph.propTypes = {
  apiResponse: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  handleAnother: PropTypes.func.isRequired,
};

export default ValidateNameGraph;
