import React from "react";
import { StyledFolderName } from "./FolderName.styles";
import PropTypes from "prop-types";

const FolderName = props => {
  const { name } = props;
  return (
    <StyledFolderName className="inner text-truncate" {...props}>
      {name}
    </StyledFolderName>
  );
};

FolderName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FolderName;
