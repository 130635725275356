import React from "react";
import PercaysoLogo from "assest/percayso-logo-roundel.svg";

const CoBotAvatar = () => {
  return (
    <div className="react-chatbot-kit-chat-bot-avatar">
      <div className="react-chatbot-kit-chat-bot-avatar-container" style={{ background: "none" }}>
        <img alt="BotAvatar" src={PercaysoLogo} />
      </div>
    </div>
  );
};

export default CoBotAvatar;
