import React from "react";

import * as colors from "styles/colors";

import PropTypes from "prop-types";

function FullPageErrorFallback({ error }) {
  return (
    <div
      role="alert"
      css={{
        color: colors.danger,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  );
}

FullPageErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
};

export default FullPageErrorFallback;
