import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";

const ConfirmSelectURL = route => {
  const [check, setCheck] = useState(false);
  const match = useMatch(route);
  useEffect(() => {
    setCheck(false);
    if (match) {
      setCheck(true);
    }
  }, [match]);

  return check;
};

export default ConfirmSelectURL;
