import "./bootstrap";
import ReactDOM from "react-dom";
import { App } from "./App";
import { AppProviders } from "./context";
import "./font/Greycliff-CF-Regular.otf";
import "./styles/global/_color.css";
import ChatBox from "components/chatBot/ChatBox";
import "react-chatbot-kit/build/main.css";
import "./styles/chatbot.css";

ReactDOM.render(
  <AppProviders>
    <App />
    <ChatBox />
  </AppProviders>,
  document.getElementById("root"),
);
