import dummyImage from "assest/quoteicons/blueCircle.png";

const labelNameFormatter = text => {
  if (!text) return "";
  return text
    .toString()
    .split(" ")
    .join("_")
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
};

// remove _ from label name
export const formatLabel = text => {
  if (!text || typeof text !== "string") return "";
  return text.split("_").join(" ");
};

// get node image using node label name
export const getImage = (label, imageFormat = "png", isLabelFormat) => {
  try {
    if (label === "") return dummyImage;
    if (isLabelFormat) return require(`assest/quoteicons/${labelNameFormatter(label)}.${imageFormat}`);
    return require(`assest/quoteicons/${label}.${imageFormat}`);
  } catch (error) {
    return dummyImage;
  }
};

// flat object & array
function traverseAndFlatten(currentNode, target, flattenedKey) {
  for (let key in currentNode) {
    // eslint-disable-next-line no-prototype-builtins
    if (currentNode.hasOwnProperty(key)) {
      let newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = flattenedKey + "." + key;
      }

      const value = currentNode[key];
      if (typeof value === "object") {
        traverseAndFlatten(value, target, newKey);
      } else {
        target[newKey] = value;
      }
    }
  }
}

// data formatter function
export function formatQuoteData(obj) {
  const flattenedObject = {};
  traverseAndFlatten(obj, flattenedObject);
  return flattenedObject;
}
