import React, { useState, useEffect } from "react";
import { config } from "../config";
import Meta from "components/layout/Meta";
import { client } from "../utils/apiClient";
import InputForm from "components/forms/InputForm";
import TextAreaForm from "components/forms/TextAreaForm";
import SelectFrom from "components/forms/SelectForm";
import DateInputForm from "components/forms/DateInputForm";
import { toast } from "react-toastify";
import { getParsedDateInput } from "utils/customDateParser";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton, Loader, FieldsWrapper } from "components/lib";
import "../styles/validatedln.css";
import ValidateNameGraph from "components/nameGraph";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
toast.configure();

const ValidateName = () => {
  const pageTitle = "Driver Licence Check";
  const [title, setTitle] = useState("");
  const [foreName, setForeName] = useState("");
  const [middleNames, setMiddleNames] = useState("");
  const [surename, setSurename] = useState("");
  const [suffix, setSuffix] = useState("");
  const [nameValidationMessage, setNameValidationMessage] = useState("");
  const [nameRequired, setNameRequired] = useState("");
  //   const [dlnValidation, setDlnValidation] = useState("");
  const [errorFetching, setErrorFetching] = useState("");

  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [loading, setLoading] = useState(false);
  const [jsonResponse, setJsonResponse] = useState(null);

  const getQuery = pipedData => {
    if (pipedData.length === 5) {
      return {
        title: pipedData[0],
        firstname: pipedData[1],
        middlename: pipedData[2],
        surname: pipedData[3],
        suffix: pipedData[4],
      };
    }

    return { title: title, firstname: foreName, middlename: middleNames, surname: surename, suffix: suffix };
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setNameValidationMessage("");
    setNameRequired("");
    setErrorFetching("");
    const splittedTitle = title.split("|");

    if (title.includes("|")) {
      if (splittedTitle.length !== 5) {
        setNameRequired("Invalid full piped name!");
        return;
      }

      if (!splittedTitle[1]) {
        setNameRequired("forename field required!");
        return;
      }

      if (!splittedTitle[3]) {
        setNameRequired("surname field required!");
        return;
      }
    } else {
      if (!foreName) {
        setNameRequired("forename field required!");
        return;
      }
      if (!surename) {
        setNameRequired("surname field required!");
        return;
      }
    }

    if (!dayjs(dob, "DD-MM-YYYY", true).isValid()) {
      setNameRequired("Invalid date");
      return;
    }

    setLoading(true);

    const {
      validateName: { apiUrl: API_URL },
    } = config;

    try {
      const apiResponse = await client(API_URL, {
        data: {
          ...getQuery(splittedTitle),
          dob: dayjs(`${dob}`.split("-").reverse().join("-")).format("YYYY-MM-DD"),
        },
      });

      if (apiResponse.matchLevel === "VALID") {
        setJsonResponse(apiResponse);
      } else setNameValidationMessage("Name verification Failed");
    } catch (error) {
      setNameValidationMessage("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleFormReload = () => {
    setJsonResponse(null);
    setTitle("");
    setForeName("");
    setMiddleNames("");
    setSurename("");
    setSuffix("");
  };

  const handleDateChange = event => {
    const valueLength = dob.length;
    const value = event.target.value;
    const currentInput = value
      .split("")
      .filter(el => el !== "-")
      .join("");
    if (!isNaN(currentInput)) {
      let allAllowed = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let ValidDigits = [];
      if (valueLength === 0) {
        ValidDigits = [0, 1, 2, 3];
        if (ValidDigits.includes(parseInt(currentInput))) {
          setDob(currentInput);
        }
      } else if (valueLength === 1) {
        const firstNumChar = dob[0];
        switch (firstNumChar) {
          case "1":
          case "2":
            ValidDigits = allAllowed;
            break;
          case "0":
            ValidDigits = [1, 2, 3, 4, 5, 6, 7, 8, 9];
            break;
          case "3":
            ValidDigits = [0, 1];
            break;
          default:
            ValidDigits = [];
        }
        if (ValidDigits.includes(parseInt(currentInput[1]))) {
          setDob(`${currentInput}-`);
        }
      } else if (valueLength === 3) {
        ValidDigits = [0, 1];
        if (ValidDigits.includes(parseInt(currentInput[2]))) {
          setDob(value);
        }
      } else if (valueLength === 4) {
        const forthNumChar = dob[3];
        switch (forthNumChar) {
          case "0":
            ValidDigits = [1, 2, 3, 4, 5, 6, 7, 8, 9];
            break;
          case "1":
            ValidDigits = [0, 1, 2];
            break;
          default:
            ValidDigits = [];
        }
        if (ValidDigits.includes(parseInt(currentInput[3]))) {
          setDob(`${value}-`);
        }
      } else if (valueLength === 6) {
        ValidDigits = [1, 2];
        const sixthDigit = parseInt(currentInput[4]);
        if (ValidDigits.includes(sixthDigit)) {
          const autoDigit = sixthDigit === 1 ? 9 : 0;
          setDob(`${value}${autoDigit}`);
        }
      } else if (valueLength === 8) {
        const seventhNumChar = dob[7];
        switch (seventhNumChar) {
          case "9":
            ValidDigits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            break;
          case "0":
            ValidDigits = [0];
            break;
          default:
            ValidDigits = [];
        }
        if (ValidDigits.includes(parseInt(currentInput[6]))) {
          setDob(value);
        }
      } else if (valueLength === 9) {
        const eightNumChar = dob[7];
        switch (eightNumChar) {
          case "9":
            ValidDigits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            break;
          case "0":
            ValidDigits = [0, 1, 2, 3, 4, 5, 6, 7, 8];
            break;
          default:
            ValidDigits = [];
        }
        if (ValidDigits.includes(parseInt(currentInput[7]))) {
          setDob(value);
        }
      }
    }
  };

  const handleKeyEvent = event => {
    if (event.key === "Backspace" && dob.length) setDob(dob.slice(0, -1));
  };

  const handleAnother = () => {
    setJsonResponse(null);
    setTitle("");
    setForeName("");
    setMiddleNames("");
    setSurename("");
    setSuffix("");
    setDob("");
    setNameValidationMessage("");
    setNameRequired("");
    setErrorFetching("");
  };

  return (
    <div className="driver-license-page-wrapper">
      <Meta title={pageTitle} />
      {jsonResponse === null ? (
        <form className="mt-3" onSubmit={handleSubmit}>
          <FieldsWrapper>
            <InputForm
              type={"text"}
              placeholder={"Title or full piped name"}
              value={title}
              handleChange={event => setTitle(event.target.value)}
              autoFocus
              isLimitValeLength={false}
            />

            <InputForm
              type={"text"}
              placeholder={"Forename"}
              value={foreName}
              handleChange={event => setForeName(event.target.value)}
            />
            <InputForm
              type={"text"}
              placeholder={"Middle Name(s)"}
              value={middleNames}
              handleChange={event => setMiddleNames(event.target.value)}
            />
            <InputForm
              type={"text"}
              placeholder={"Surname"}
              value={surename}
              handleChange={event => setSurename(event.target.value)}
            />
            <InputForm
              type={"text"}
              placeholder={"Suffix"}
              value={suffix}
              handleChange={event => setSuffix(event.target.value)}
            />
            <DateInputForm
              type={"text"}
              placeholder={"Date of Birth"}
              value={dob}
              handleChange={handleDateChange}
              handleKeyEvent={handleKeyEvent}
            />
          </FieldsWrapper>
          <div className="btn-container">
            <LoadingButton type="submit" className="btn-validate mt-2">
              {loading ? <Loader /> : "Submit"}
            </LoadingButton>
          </div>
          <div className="name-validate-error">{nameValidationMessage}</div>
          <div className="name-validate-error">{nameRequired}</div>
          <div className="name-validate-error">{errorFetching}</div>
        </form>
      ) : (
        <div>
          {/* <div className="driver-license-wrapper">
            {foreName === "" && surename === "" && middleNames === "" && surename === "" ? null : (
              <div className="protect-input">
                {foreName} {middleNames} {surename}
              </div>
            )}
            <TextAreaForm jsonValue={jsonResponse} cols={20} rows={10} formLabel="Response" readOnly={true} />
          </div>
          <div className="btn-container">
            <LoadingButton type="submit" onClick={handleFormReload} className="btn-validate mt-4">
              {loading ? <Loader /> : "Another"}
            </LoadingButton>
          </div> */}
          <ValidateNameGraph apiResponse={jsonResponse} handleAnother={handleAnother} />
        </div>
      )}
    </div>
  );
};

export default ValidateName;
