import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  height: 94.4px;
  overflow: hidden;
  /* @media (max-width: 480px) {
    height: 81.59px;
  }
  @media (min-width: 993px) {
    height: 70.57px;
  } */
`;

export const FolderContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const FolderIconContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: calc(100% - 19.3px);
  justify-items: center;
  align-content: baseline;
  width: 100%;
  padding: 1px 2px;
  background-color: var(--lily);
  border: 1px solid rgba(248, 248, 248, 0.5);
  border-radius: 0.7rem;
  max-height: 60px;
  max-width: 60px;
  min-width: 57px;
  min-height: 57px;
  margin-top: 11px;

  @media (min-width: 993px) {
    max-height: 60px;
    max-width: 60px;
    border-radius: 0.7rem;
    height: 100%;
  }
`;

export const SmallIcon = styled.img`
  max-width: 12.5px;
  margin: 2px 0;
`;
